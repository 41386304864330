import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { RubricService } from 'src/app/core/services/rubric.service';
import { Rubric, RubricApplication } from 'src/app/core/models/rubrica.model';
import { PendingService } from "../../core/services/pending.service";
import { Pending } from "../../core/models/utils.model";
import {CommentEvaluationService} from "../../core/services/comment-evaluation.service";
import {CommentEvaluation} from "../../core/models/comment-evaluation.model";
import {WorkloadRequest} from "../../core/models/workload.model";
import { Faculty } from 'src/app/core/models/faculty.model';
import { ProjectService } from 'src/app/core/services/project.service';
import { RubricAplicationService } from 'src/app/core/services/rubric-aplication.service';
import { of, switchMap } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { OtherAcademicsView } from '../proyecto/proyecto-detalle/proyecto-detalle.component';


export interface Semester{
    name: string;
    year: number;
    semester: number;
}

@Component({
    selector: 'app-evaluacion-pares',
    templateUrl: './evaluacion-pares.component.html',
    styleUrls: ['./evaluacion-pares.component.scss']
})
export class PeerReviewComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;

    rubric?: Rubric;
    rubricApplication?: RubricApplication;

    idRubricAplication!: number;
    project?: Project;


    semesters: Semester[] = [];

    otherAcademics: OtherAcademicsView[] = [];

    commentEvaluation!: CommentEvaluation;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private commentEvaluationService: CommentEvaluationService,
        private rubricService: RubricService,
        private pendingService: PendingService,
        private rubricApplicationService: RubricAplicationService,
        private projectService: ProjectService,
        injector: Injector
    ) {
        super(injector);
    }

    override async ngOnInit() {
        super.ngOnInit();

        //this.commentEvaluation = await this.commentEvaluationService.getCommentEvaluation(1);
        this.commentEvaluation = {
            investigador: '',
            departamento: ''
        }

        this.openLoading();
        
        this.route.params.pipe(
            switchMap(params => {
                this.idRubricAplication = params['id'];
      
                if (this.idRubricAplication) {
                  return this.rubricApplicationService.getRubricAplication(this.idRubricAplication);
                }
      
                // If there's no idUser, just return an observable of null
                return of(null);
            })
          ).subscribe({
              next: async(response) => {
                  if (response) {
                    this.rubricApplication = response;
                    this.project = await this.projectService.getProject(this.rubricApplication.project!.id);
                    this.rubric = await this.rubricService.getRubric(12);

                      //bundle all other academics, grouped by rut
                      const otherAcademics: OtherAcademicsView[] = [];
                      for(let oa of this.project!.other_academics!){
                        const foundOA = otherAcademics.find(oa => oa.rut === oa.rut);
                        if(!foundOA){
                          otherAcademics.push({
                            name: '',
                            rut: oa.rut,
                            activities: [
                              {
                                objective: oa.objective,
                                start_date: oa.start_date,
                                end_date: oa.end_date,
                              },
                            ],
                          });
                        }
                        if(foundOA){
                          foundOA.activities.push({
                            objective: oa.objective,
                            start_date: oa.start_date,
                            end_date: oa.end_date,
                          });
                        }
                      }
                      this.otherAcademics = otherAcademics;

                    this.calculateSemesters();
                    this.closeLoading();
                  } else {
                    throw new Error('Error fetching Rubric Application');
                  }
      
                  
              },
              error: (error) => {
                  console.error('Error fetching Project:', error);
                  // Handle the error accordingly
                  this.closeLoading();
              }
          });
    }

    calculateSemesters() {
        //Todo: Get starting semester:
        let currentSemester = 2;
        let currentYear = 2024;
        let current_months = 0;
    
        const semesters: Semester[] = [];
    
        while(current_months < this.project!.estimated_duration_months!){
          // Get current semester
          semesters.push({
            name: `${currentYear} - ${currentSemester.toString().padStart(2, '0')}`,
            year: currentYear,
            semester: currentSemester
          });
          //advance to next semester
          currentSemester++;
          if(currentSemester > 2){
            currentSemester = 1;
            currentYear++;
          }
          current_months += 6;
        }
    
        this.semesters = semesters;
      }

    async save() {
        this.openLoading();
        let request: any = {
          answers: [],
          investigatiorComment: this.commentEvaluation.investigador,
          internalComment: this.commentEvaluation.departamento
        };

        let totalScore = 0;

        this.rubric?.categories.forEach(category => {
            category.questions.forEach(question => {
               let obj: Pending = {
                   idQuestion: question.id,
                   value: question.answer
               };
               totalScore += question.answer;
                request.answers.push(obj);
            });
        });

        request.totalScore = totalScore;
        request.qualitativeEvaluation = this.getEvalText(request.totalScore);

        await this.rubricApplicationService.saveAnswers(this.idRubricAplication, request);

        this.closeLoading();
        await this.globalSuccess('Evaluación enviada exitosamente');
    }

    cancel(): void {
        this.router.navigateByUrl('mis-pendientes');
    }

    getEvalText(evalTotal:number): string {
      const json = JSON.parse(this.rubric?.evalJSON || '{}');
      if(json.hasOwnProperty('evals')){
          for(let e of json.evals){
              if(evalTotal >= e.min && evalTotal <= e.max){
                  return e.name;
              }
          }
      }
      return '';
  }


  getProjectTypeLabel(projectType: string): string {
    switch (projectType) {
      case 'is_initial':
        return 'Iniciación (hasta 12 meses)';
      case 'is_regular':
        return 'Regular (hasta 24 meses)';
      case 'is_technology_transfer':
        return 'Transf. Tec. (hasta 24 meses)';
      case 'is_related_music':
        return 'Música';
      case 'is_related_literature':
        return 'Literatura';
      case 'other_related':
        return 'Otro (Indicar)';
      default:
        return '';
    }
  }

  getFacultyName(faculty: Faculty | undefined): string {
    // logic to get faculty name by id
    return '';
  }

  getContractTypeName(contractTypeId: number| undefined): string {
    // logic to get contract type name by id
    return '';
  }

  getGradeName(grade: string| undefined): string {
    // logic to get grade name by id
    return '';
  }

  getObjectiveName(objectiveId: string| undefined): string {
    // logic to get objective name by id
    return '';
  }

  getPersonTotalHours(person:any): number {
    // logic to calculate total hours for a person
    return 0;
  }

  getTotalHours(): number {
    // logic to calculate total hours
    return 0;
  }

}
