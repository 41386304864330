<!-- start page title -->
<app-pagetitle title="Formulario Solicitud Compra DTI" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formSolicitud" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
                    <div class="row justify-content-end">
                        <div class="col col-lg-4">
                            <div class="mb-3">
                                <label for="name">Fecha</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="date">
                            </div>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="name" readonly>
                                <label for="name">Nombre Solicitante</label>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="RutProvider" readonly>
                                <label for="name">Rut Proveedor</label>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="RazonProvider" readonly>
                                <label for="name">Razon Social Proveedor</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="GiroProvider" readonly>
                                <label for="name">Giro Proveedor</label>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="DireccionProvider" readonly>
                                <label for="name">Dirección Proveedor</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="TelefonoProvider" readonly>
                                <label for="name">Teléfono Proveedor</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="EmailProvider" readonly>
                                <label for="name">Correo Electrónico Proveedor</label>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 10%">Cantidad</th>
                                        <th style="width: 60%">Detalle</th>
                                        <th style="width: 15%">Precio Unitario</th>
                                        <th style="width: 15%">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                    <tr>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                    <tr>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                    <tr>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                    <tr>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colspan="3" style="text-align: right;">NETO</th>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                    <tr>
                                        <th colspan="3" style="text-align: right;">IVA</th>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                    <tr>
                                        <th colspan="3" style="text-align: right;">TOTAL</th>
                                        <td><input type="number" class="form-control"></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                    </div>

                    <ngx-dropzone (change)="onSelect($event)" class="mt-4 mb-4">
                        <ngx-dropzone-label><i class="display-4 text-muted bx bxs-cloud-upload"></i>
                          <h4>Drop files here or click to upload.</h4>
                        </ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                      </ngx-dropzone>

                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
