<!-- start page title -->
<app-pagetitle title="Formulario Contrato" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formContract">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="academic" value="{{project?.academic?.fullName}}" readonly>
                                <label for="academic">Investigador Responsable</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="rut_academic" value="{{project?.rut_academic}}" readonly>
                                <label for="rut_academic">RUT</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="academic_gender" value="{{project?.gender_academic}}" readonly>
                                <label for="academic_gender">Género</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email_academic" value="{{project?.academic?.email}}" readonly>
                                <label for="email_academic">Correo Electrónico</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="phone_academic" value="{{project?.phone_academic}}" readonly>
                                <label for="phone_academic">Teléfono UnACh</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="cell_academic" value="{{project?.cell_academic}}" readonly>
                                <label for="cell_academic">Celular</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <label for="startDate">Fecha Inicio</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" id="startDate" formControlName="startDate" [readonly]="contract?.isSigned">
                        </div>
                        <div class="col-lg-6">
                            <label for="endDate">Fecha Fin</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" id="endDate" formControlName="endDate" [readonly]="contract?.isSigned">
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="directCosts" formControlName="directCosts" readonly>
                                <label for="directCosts">Monto Costos Directos</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="indirectCosts" formControlName="indirectCosts" readonly>
                                <label for="indirectCosts">Monto Costos Indirectos</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th rowspan="3">Investigador / Estudiante</th>
                                    <th rowspan="3">Facultad / Unidad</th>
                                    <th rowspan="3">Tipo de Contrato</th>
                                    <th rowspan="3">Grado</th>
                                    <th [attr.colspan]="semesters.length + 2">N° horas cronológicas* asignadas para el Proyecto**</th>
                                </tr>
                                <tr>
                                    <th [attr.colspan]="semesters.length">N° horas</th>
                                    <th rowspan="2">N° semanas</th>
                                    <th rowspan="2">N° horas totales</th>
                                </tr>
                                <tr>
                                    <th *ngFor="let sem of semesters">
                                        {{ sem.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pe of personEstimations; let i = index">
                                    <td>{{pe.rut}}<br>{{pe.name}}</td>
                                    <td>{{pe.faculty?.acronym}}</td>
                                    <td>{{pe.contract_type}}</td>
                                    <td>{{pe.degree}}</td>
                                    <td *ngFor="let hourIndex of getWeeklyHoursControls(i)">{{hourIndex}}</td>
                                    <td>{{pe.weeks}}</td>
                                    <td>{{getPersonTotalHours(i)}}h</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td [attr.colspan]="semesters.length + 5" style="text-align: right;">TOTAL</td>
                                    <td>{{getTotalHours()}}h</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="!isSigned">
                <div class="card-body" style="text-align: center;">
                    <div class="form-check form-check-warning mb-3">
                        <input class="form-check-input" type="checkbox" id="formCheckcolor4" formControlName="isSigned" style="width: 30px; height: 30px; margin-right: 15px">
                        <label class="form-check-label" for="formCheckcolor4" style="line-height: 33px;">
                            ¿Firmar Contrato?
                        </label>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="formContract.value.isSigned && !contract?.contractFile">
                <div class="card-body" style="text-align: center;">
                  <div class="mb-3">
                    <label for="contractFile">Subir Contrato Firmado</label>
                    <input type="file" class="form-control" id="contractFile" (change)="onFileChange($event)">
                    <div *ngIf="formContract.get('contractFile')?.touched && formContract.get('contractFile')?.invalid" class="text-danger">
                      Debe subir el contrato firmado
                    </div>
                  </div>
                </div>
            </div>

            <div class="card text-white bg-success" *ngIf="contract?.isSigned && contract?.contractFile">
                <div class="card-body" style="text-align: center;">
                    <div class="col col-lg-12">
                        <div class="form-check mb-3">
                            <h5>Contrato Firmado el {{contract?.dateSigned | date: 'dd/MM/yyyy'}}, <a class="btn btn-primary" target="_blank" href="/api/file/get-drive/{{contract?.contractFile}}">Descargar Archivo Contrato</a></h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">

                    <div class="d-flex justify-content-between">
                        <!-- Left-aligned button -->
                        <button class="btn btn-secondary" type="button" (click)="previewPDF()" *ngIf="!isSigned">
                            <i class="bx bxs-search"></i>
                            Ver Contrato para Firmar
                        </button>
                        <!-- Right-aligned buttons -->
                        <div>
                            <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">
                                <i class="bx bxs-x-square"></i> Cancelar
                            </button>
                          <button *ngIf="!isSigned" class="btn btn-primary" type="submit" (click)="save()" [disabled]="!formContract.valid">
                              <i class="bx bxs-check-square"></i> Continuar
                          </button>
                        </div>
                      </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>