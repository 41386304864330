import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { Career } from 'src/app/core/models/career.model';
import { CECRequest, CECResponse } from 'src/app/core/models/cec.model';
import { Faculty } from 'src/app/core/models/faculty.model';
import { CarrerasService } from 'src/app/core/services/carreras.service';
import { CECService } from 'src/app/core/services/cec.service';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { BasePage } from 'src/app/shared/base-page';

@Component({
  selector: 'app-cec-formulario',
  templateUrl: './cec-formulario.component.html',
  styleUrls: ['./cec-formulario.component.scss']
})
export class CECFormComponent extends BasePage implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  idCEC: number = 0;
  cec: CECResponse | null = null;

  // Formularios de cada sección
  formStep2: FormGroup;
  formStep3: FormGroup;
  formStep4: FormGroup;
  formStep5: FormGroup;

  files: { [key: string]: File } = {};

  faculties: Array<Faculty> = [];
  careers: Array<Career> = [];

  constructor(
    private fb: FormBuilder, 
    private cecService: CECService,
    private facultiesService: FacultadesService,
    private careersService: CarrerasService,
    private route: ActivatedRoute,
    injector: Injector
  ) {
    super(injector);

    // Inicialización de los formularios
    this.formStep2 = this.fb.group({
      title: ['', Validators.required],
      origin: ['', Validators.required],
      contact: ['', Validators.required],
      investigators: [''],
      academic: ['', Validators.required],
      faculty: ['', Validators.required],
      career: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
    });

    this.formStep3 = this.fb.group({
      question: ['', Validators.required],
      general_objective: ['', Validators.required],
      specific_objectives: ['', Validators.required],
      hipotesis: [''],
      design: ['', Validators.required],
      sample: ['', Validators.required],
      environment: ['', Validators.required],
      activities: ['', Validators.required],
    });

    this.formStep4 = this.fb.group({
      is_external_expert_validated: [false],
      is_statistics_validated: [false],
      is_country_validated: [false],
      is_unach_expert_validated: [false],
      is_not_validated: [false],
      bibliography: [''],
      is_descriptive_stats: [false],
      is_analitical_Stats: [false],
      is_other_analitics: [false],
      other_analitics: [''],
    });

    this.formStep5 = this.fb.group({
      path_consent_file: ['', Validators.required],
      path_commitment_file: ['', Validators.required],
      path_project_file: ['', Validators.required],
    });
  }


  async ngOnInit() {
    super.ngOnInit();
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Solicitudes CEC' },
      { label: 'Crear Solicitud', active: true }
    ];

    this.openLoading();

    this.faculties = await this.facultiesService.getTodasFacultadesActivas();
    this.careers = await this.careersService.getTodasCarrerasActivas();

    this.route.params.pipe(
      switchMap(params => {
          this.idCEC = params['id'];

          if (this.idCEC) {
              return this.cecService.getCEC(this.idCEC);
          }

          // If there's no idCEC, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: async(response) => {
            if (response ) {
                this.cec = response;

                this.cec.start_date = new Date(this.cec.start_date).toISOString().split('T')[0];
                this.cec.end_date = new Date(this.cec.end_date).toISOString().split('T')[0];

                //Patch form values with the retrieved CEC data
                this.formStep2.patchValue({
                  title: this.cec.title,
                  origin: this.cec.origin,
                  contact: this.cec.contact,
                  investigators: this.cec.investigators,
                  academic: this.cec.academic,
                  faculty: this.cec.faculty?.id,
                  career: this.cec.career?.id,
                  start_date: this.cec.start_date,
                  end_date: this.cec.end_date,
                });
                this.formStep3.patchValue({
                  question: this.cec.question,
                  general_objective: this.cec.general_objective,
                  specific_objectives: this.cec.specific_objectives,
                  hipotesis: this.cec.hipotesis,
                  design: this.cec.design,
                  sample: this.cec.sample,
                  environment: this.cec.environment,
                  activities: this.cec.activities,
                });
                this.formStep4.patchValue({
                  is_external_expert_validated: this.cec.is_external_expert_validated,
                  is_statistics_validated: this.cec.is_statistics_validated,
                  is_country_validated: this.cec.is_country_validated,
                  is_unach_expert_validated: this.cec.is_unach_expert_validated,
                  is_not_validated: this.cec.is_not_validated,
                  bibliography: this.cec.bibliography,
                  is_descriptive_stats: this.cec.is_descriptive_stats,
                  is_analitical_stats: this.cec.is_analitical_stats,
                  is_other_analitics: this.cec.is_other_analitics,
                  other_analitics: this.cec.other_analitics,
                });
                /*this.formStep5.patchValue({
                  path_consent_file: this.cec.path_consent_file,
                  path_commitment_file: this.cec.path_commitment_file,
                  path_project_file: this.cec.path_project_file,
                });*/
            }

            this.breadCrumbItems = [
              { label: 'Solicitudes CEC' },
              { label: 'Editar Solicitud CEC', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Support Letter:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  careersForFaculty(facultyId: number) {
    if(facultyId === 0) return this.careers;
    return this.careers.filter(career => career.faculty?.id == facultyId);
  }

  /**
   * Manejar archivos seleccionados
   * @param event Evento del input de archivo
   * @param key Nombre del campo de archivo
   */
  onFileChange(event: any, key: string): void {
    if (event.target.files && event.target.files.length > 0) {
      this.files[key] = event.target.files[0]; // Guardar el archivo seleccionado
    }
  }

  /**
   * Navegar al paso específico del stepper.
   * @param stepIndex Índice del paso al que se quiere ir.
   */
  navigateToStep(stepIndex: number): void {
    if (this.stepper) {
      this.stepper.selectedIndex = stepIndex;
    }
  }

  /**
   * Enviar el formulario completo.
   */
  async submitForm() {
    if (
      this.formStep2.valid &&
      this.formStep3.valid &&
      this.formStep4.valid &&
      this.formStep5.valid
    ) {
      this.openLoading();

      const investigation: CECRequest = {
        ...this.formStep2.value,
        ...this.formStep3.value,
        ...this.formStep4.value,
        ...this.formStep5.value,
      };

      const response = await this.cecService.postCEC(investigation, this.files);
      this.closeLoading();
      await this.globalSuccess(`El Formulario de Solicitud a CEC se ha guardado correctamente`);
      
      //Ir a la lista de solicitudes CEC
      this.router.navigate(['/solicitudes-comite']);

    } else {
      console.log('Por favor, completa todos los campos requeridos.');
    }
  }

  /**
   * Verifica si un paso específico es válido.
   * Esto puede ser usado para mostrar un estado visual o validar antes de navegar.
   * @param step Índice del paso.
   * @returns Booleano indicando si el paso es válido.
   */
  isStepValid(step: number): boolean {
    switch (step) {
      case 0:
        return this.formStep2.valid;
      case 1:
        return this.formStep3.valid;
      case 2:
        return this.formStep4.valid;
      case 3:
        return this.formStep5.valid;
      default:
        return false;
    }
  }

  getFacultyName(facultyId: number): string {
    const faculty = this.faculties.find(f => f.id === facultyId);
    return faculty? faculty.name || '' : '';
  }

  getCareerName(careerId: number): string {
    const career = this.careers.find(c => c.id === careerId);
    return career? career.name || '' : '';
  }

  getFileName(filePath: string, fileType: string): string {
    if(filePath) {
      return filePath.split('\\').pop() || '';
    }
    if(this.cec){
      let file = '';
      switch(fileType) {
        case 'path_consent_file':
          file = this.cec.path_consent_file;
          break;
        case 'path_commitment_file':
          file = this.cec.path_commitment_file;
          break;
        case 'path_project_file':
          file = this.cec.path_project_file;
          break;
        default:
          break;
      }
      return file.split('/').pop() || '';
    }
    return '';
  }

  getFileNameB64(filePath: string, fileType: string): string {
    const file = this.getFileName(filePath, fileType);
    return file? btoa(file) : '';
  }
}
