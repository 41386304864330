import { Component, Input, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ProyectoWizardComponent } from '../proyecto-wizard/proyecto-wizard.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ProjectWizardService } from 'src/app/core/services/project-wizard.service';
import { Objective, ObjectiveResponse } from 'src/app/core/models/objective.model';

@Component({
  selector: 'app-proyecto-step2',
  templateUrl: './proyecto-step2.component.html',
  styleUrls: ['./proyecto-step2.component.scss']
})
export class ProyectoStep2Component {

  formStep: FormGroup;
  @Input() parentComponent!: ProyectoWizardComponent;
  public Editor = ClassicEditor;

  constructor(
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private projectWizardService: ProjectWizardService,
  ) { 
    this.formStep = this.fb.group({
      pi_summary: [undefined],
      pi_introduction: [undefined],
      pi_objectives: this.fb.array([]),
      pi_objectives_deleted: [[]],
      pi_methodology: [undefined],
      pi_contribution_science: [undefined],
      pi_aplicability_results: [undefined],
    });
  }

  async ngOnInit() {
    // Load existing data if available
    this.projectWizardService.getProjectData().subscribe(data => {
      this.formStep.patchValue(data);

      this.piObjectives.clear();
      if(data.pi_objectives){
        for(let objective of data.pi_objectives) {
          this.piObjectives.push(this.createObjectiveFormGroup(objective));
        }
      }
    });

  }

  get piObjectives(): FormArray {
    return this.formStep.get('pi_objectives') as FormArray;
  }
  
  addObjective(): void {
    this.piObjectives.push(this.createObjectiveFormGroup({name:'',text: '', id: -1}));
  }

  createObjectiveFormGroup(objective: Objective): FormGroup {
    return this.fb.group({
      text: [objective.text],
      name: [objective.name],
      // Include ID as a property for reference, not as a FormControl
      id: objective.id
    });
  }
  
  removeObjective(index: number): void {
    //add the id to the array of deleted ids, if its greater than -1
    if(this.piObjectives.at(index).value.id > -1) this.formStep.get('pi_objectives_deleted')?.value.push(this.piObjectives.at(index).value.id);
    this.piObjectives.removeAt(index);
  }

  openNoBackdrop(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { backdrop: false, position: 'top', scroll: true });
  }

  nextStep(): void {
    this.parentComponent.nextStep(this.formStep.value);
  }

  save(): void{
    this.parentComponent.updateData(this.formStep.value);
  }

  previousStep(): void {
    this.parentComponent.previousStep(this.formStep.value);
  }

}
