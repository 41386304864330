import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InvestigativeLine } from '../models/utils.model';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  getInvestigativeLines(type: string): Promise<InvestigativeLine[]> {
    return lastValueFrom(this.http.get<InvestigativeLine[]>(`${environment.apiURL}/InvestigativeLine/?type=${type}&isActive=1`));
  }

  
}
