import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { Contract } from 'src/app/core/models/contract.model';
import { ContractService } from 'src/app/core/services/contract.service';

@Component({
  selector: 'app-contrato-lista',
  templateUrl: './contrato-lista.component.html',
  styleUrls: ['./contrato-lista.component.scss']
})
export class ContratoListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['name', 'faculty', 'isSigned', 'dateSigned', 'actions'];
  dataSource: MatTableDataSource<Contract> = new MatTableDataSource<Contract>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  contracts: Contract[] = [];

  constructor(private contractService: ContractService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getContracts();
  }

  async getContracts() {
    this.openLoading();
    this.contracts = await this.contractService.getContracts();
    this.contracts.map((cp) => { 
      cp.actions = [
        {
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/contratos/editar/${cp.id}`
        },
        {
          name: 'Ver PDF',
          icon: 'bx bx-search-alt',
          url: `/api/contract/pdf?id=${cp.id}`
        }
      ]
    });
    this.dataSource.data = this.contracts;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  doAction(accion: Action, id: number){
    if(accion.name === 'Ver PDF') window.open(accion.url as string, '_blank');
    this.router.navigateByUrl(accion.url as string);
  }

}
