import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommitteeRequest } from '../models/committee-request.model';

@Injectable({
  providedIn: 'root'
})
export class CommitteeRequestWizardService {
  private committeeRequestData = new BehaviorSubject<CommitteeRequest>({} as CommitteeRequest);
  private lastModified = new BehaviorSubject<Date>({} as Date);

  getCommitteeRequestData() {
    return this.committeeRequestData.asObservable();
  }

  updateCommitteeRequestData(data: Partial<CommitteeRequest>) {
    this.committeeRequestData.next({ ...this.committeeRequestData.value, ...data });
  }

  getLastModified() {
    return this.lastModified.asObservable();
  }
  
  updateLastModified(date: Date) {
    this.lastModified.next(date);
  }
}
