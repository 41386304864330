import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ComitteeRequestWizardComponent } from '../solicitud-comite-wizard/solicitud-comite-wizard.component';
import { CommitteeRequestWizardService } from 'src/app/core/services/committee-request-wizard.service';

@Component({
  selector: 'app-solicitud-comite-step1',
  templateUrl: './solicitud-comite-step1.component.html',
  styleUrls: ['./solicitud-comite-step1.component.scss']
})
export class ComitteeRequestStep1Component {

  formStep1: FormGroup;
  @Input() parentComponent!: ComitteeRequestWizardComponent;

  constructor(
    private fb: FormBuilder,
    private committeeRequestWizardService: CommitteeRequestWizardService,
    private utilsService: UtilsService
  ) { 
    this.formStep1 = this.fb.group({
      title: [undefined],
      source: [undefined],
      academic: [undefined],
      students: [undefined],
      guide_academic: [undefined],
      faculty: [undefined],
      career: [undefined],
      start_date: [undefined],
      end_date: [undefined],
    });
  }

  async ngOnInit() {
    

    // Load existing data if available
    this.committeeRequestWizardService.getCommitteeRequestData().subscribe(data => {
      this.formStep1.patchValue(data);
      
    });

  }

  nextStep(): void {
    this.parentComponent.nextStep(this.formStep1.value);
  }

  previousStep(): void {
    this.parentComponent.previousStep(this.formStep1.value);
  }

}
