<div class="table-responsive">
    <table class="table mb-0">
      <thead class="table-light">
        <tr>
          <th colspan="3"><b>{{ personDetails.name }}</b></th>
        </tr>
        <tr>
          <th>Activity</th>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody formArrayName="activities">
        <tr *ngFor="let activity of activities.controls; let i = index" [formGroupName]="i">
          <td><input type="text" class="form-control" formControlName="activity"></td>
          <td><input type="text" class="form-control" formControlName="start"></td>
          <td><input type="text" class="form-control" formControlName="end"></td>
        </tr>
        <tr>
          <td colspan="3" style="text-align: right;">
            <button class="btn btn-primary add-btn" (click)="addActivity()"><i class="bx bx-plus"></i> Add Activity</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  