import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { Faculty } from 'src/app/core/models/faculty.model';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { of, switchMap, tap } from 'rxjs';
import { Career, CareerRequest } from 'src/app/core/models/career.model';
import { CarrerasService } from 'src/app/core/services/carreras.service';


@Component({
    selector: 'app-form-carrera',
    templateUrl: './form-carrera.component.html',
    styleUrls: ['./form-carrera.component.scss']
})
export class FormCarreraComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    formCarrera!: FormGroup;

    carrera?: Career = undefined;
    idCarrera: number = 0;

    facultades: Faculty[] = [];

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private facultadService: FacultadesService,
        private carreraService: CarrerasService,
        injector: Injector
    ) {
        super(injector);
        this.formCarrera = this.fb.group({
            nombre: [undefined, [Validators.required]],
            activo: [true],
            facultad: [undefined, [Validators.required]],
        });
    }

    override async ngOnInit() {

        super.ngOnInit();
        this.openLoading();
        this.facultades = (await this.facultadService.getTodasFacultadesActivas());
        
        this.route.params.pipe(
            switchMap(params => {
                this.idCarrera = params['id'];
    
                if (this.idCarrera) {
                    return this.carreraService.getCarrera(this.idCarrera);
                }
    
                // If there's no idCarrera, just return an observable of null
                return of(null);
            })
        ).subscribe({
            next: (response) => {
                if (response && response) {
                    this.carrera = response;
    
                    this.formCarrera.patchValue({
                        nombre: this.carrera.name,
                        activo: this.carrera.is_active,
                        facultad: this.carrera.faculty.id,
                    });
                }
    
                this.closeLoading();
            },
            error: (error) => {
                console.error('Error fetching facultad:', error);
                // Handle the error accordingly
                this.closeLoading();
            }
        });
        
    }

    async save() {
        let datos = this.formCarrera.value;
        let request: CareerRequest = {
            name: datos.nombre,
            is_active: datos.activo,
            faculty: datos.facultad
        };
        this.openLoading();
        if (this.carrera) {
            let data = await this.carreraService.putCarrera(request, this.carrera.id);
            this.closeLoading();
            await this.globalSuccess(`La carrera ${data.name} ha sido actualizada correctamente`);
            this.router.navigateByUrl('carreras' );
        } else {
            let data = await this.carreraService.postCarrera(request);
            this.closeLoading();
            await this.globalSuccess(`La carrera ${data.name} ha sido creada correctamente`);
            this.router.navigateByUrl('carreras');
        }
    }

    cancel(): void {
        this.router.navigateByUrl('carreras');
    }

}
