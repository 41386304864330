import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project.service';
import { BasePage } from 'src/app/shared/base-page';

interface Dict {
  [key: string]: number;
}

@Component({
  selector: 'app-proyecto-gasto',
  templateUrl: './proyecto-gasto.component.html',
  styleUrls: ['./proyecto-gasto.component.scss']
})

export class ProyectoGastoComponent extends BasePage implements OnInit{

  idProject!: number;
  project?: Project;
  breadCrumbItems!: Array<{}>;

  isCollapsed: boolean = true;

  secciones = [
    {
      name: '1. GASTOS DE OPERACIÓN',
      categories: [
        '1.1 Maquinas y equipos', 
        '1.2 Software y licencias', 
        '1.3 Insumos de Laboratorio']
    },
    {
      name: '2. MATERIALES DE OFICINA',
      categories: [
        '2.1 Materiales de Oficina )paple, lápices, corchetera, fotocopias, etc', 
        '2.2 Otros'
      ]
    },
    {
      name: '3. HONORARIOS ASISTENTES',
      categories: [
        '3.1 Alumnos Ayudantes (max. $200.000 por alumno y año', 
        '3.2 Tesistas de pregrado (máximo $200.000 por tesista y semestre)',
        '3.3 Tesistas de posgrado (máximo $400.000 por tesista y semestre)'
      ]
    },
    {
      name: '4. VIAJES PARA REALIZAR EL PROYECTO',
      categories: [
        '4.1 Movilización (máximo $100.000 en Chile y 200.000 en el extranjero)', 
        '4.1.1 Movilización para recoger datos o consultas bibliográficas',
        '4.1.2 Movilización para asesoría científica',
      ]
    },
    {
      name: '5. PRESENTACIÓN DE LA INVESTIGACIÓN EN EVENTOS CIENTÍFICOS O ACADÉMICOS',
      categories: [
        '5.1 Gastos de impresión de pósteres, inscripción a congresos, movilización, viáticos, alojamiento investigación (máximo $100.000/evento, y con un máximo de un evento científico anual)', 
      ]
    },
    {
      name: '6. PUBLICACIÓN DE LA INVESTIGACIÓN',
      categories: [
        '6.1. Traducir el artículo para publicar en revista especializada', 
        '6.2 Gastos de edición',
        '6.3 Gastos de publicación',
        '6.4 Otros'
      ]
    },
    {
      name: '7. OTROS',
      categories: [
        '7.1 Otros', 
      ]
    }
  ];

  budgets: Dict = {
    '1.1 Maquinas y equipos': 0,
    '1.2 Software y licencias': 0,
    '1.3 Insumos de Laboratorio': 0,
    '2.1 Materiales de Oficina )paple, lápices, corchetera, fotocopias, etc': 0,
    '2.2 Otros': 0,
    '3.1 Alumnos Ayudantes (max. $200.000 por alumno y año': 0,
    '3.2 Tesistas de pregrado (máximo $200.000 por tesista y semestre)': 0,
    '3.3 Tesistas de posgrado (máximo $400.000 por tesista y semestre)': 0,
    '4.1 Movilización (máximo $100.000 en Chile y 200.000 en el extranjero)': 0,
    '4.1.1 Movilización para recoger datos o consultas bibliográficas': 0,
    '4.1.2 Movilización para asesoría científica': 0,
    '5.1 Gastos de impresión de pósteres, inscripción a congresos, movilización, viáticos, alojamiento investigación (máximo $100.000/evento, y con un máximo de un evento científico anual)': 0,
    '6.1. Traducir el artículo para publicar en revista especializada': 0,
    '6.2 Gastos de edición': 0,
    '6.3 Gastos de publicación': 0,
    '6.4 Otros': 0,
    '7.1 Otros': 0
  }

  expenses: Dict = {
    '1.1 Maquinas y equipos': 0,
    '1.2 Software y licencias': 0,
    '1.3 Insumos de Laboratorio': 0,
    '2.1 Materiales de Oficina )paple, lápices, corchetera, fotocopias, etc': 0,
    '2.2 Otros': 0,
    '3.1 Alumnos Ayudantes (max. $200.000 por alumno y año': 0,
    '3.2 Tesistas de pregrado (máximo $200.000 por tesista y semestre)': 0,
    '3.3 Tesistas de posgrado (máximo $400.000 por tesista y semestre)': 0,
    '4.1 Movilización (máximo $100.000 en Chile y 200.000 en el extranjero)': 0,
    '4.1.1 Movilización para recoger datos o consultas bibliográficas': 0,
    '4.1.2 Movilización para asesoría científica': 0,
    '5.1 Gastos de impresión de pósteres, inscripción a congresos, movilización, viáticos, alojamiento investigación (máximo $100.000/evento, y con un máximo de un evento científico anual)': 0,
    '6.1. Traducir el artículo para publicar en revista especializada': 0,
    '6.2 Gastos de edición': 0,
    '6.3 Gastos de publicación': 0,
    '6.4 Otros': 0,
    '7.1 Otros': 0
  }

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    injector: Injector) 
  { super(injector); }

  async ngOnInit() {

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Proyecto' },
      { label: 'Ver Proyecto', active: true }
    ];

    this.openLoading();

    this.route.params.pipe(
      switchMap(params => {
          this.idProject = params['id'];

          if (this.idProject) {
              return this.projectService.getProject(this.idProject);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: (response) => {
            if (response ) {
                this.project = response;
            }

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Project:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
 
  }

  getBudget(category: string): number {
    return this.budgets[category];
  }

  getExpenses(category: string): number {
    return this.expenses[category];
  }

  getDiff(category: string): number {
    return this.budgets[category] - this.expenses[category];
  }

  getSectionTotalDiff(section: any): number {
    let total = 0;
    for( let c of section.categories){
      total += this.getDiff(c);
    }
    
    return total;
  }

  getSectionTotalBudget(section: any): number {
    let total = 0;
    for( let c of section.categories){
      total += this.getBudget(c);
    }
    
    return total;
  }

  getSectionTotalExpenses(section: any): number {
    let total = 0;
    for( let c of section.categories){
      total += this.getExpenses(c);
    }
    
    return total;
  }

  getTotalBudget(): number {
    let total = 0;
    for( let s of this.secciones ){
      total += this.getSectionTotalBudget(s);
    }

    return total;
  }

  getTotalExpenses(): number {
    let total = 0;
    for( let s of this.secciones ){
      total += this.getSectionTotalExpenses(s);
    }

    return total;
  }

  getTotalDiff(): number {
    let total = 0;
    for( let s of this.secciones ){
      total += this.getSectionTotalDiff(s);
    }

    return total;
  }

  goToNewOE() {
    this.router.navigateByUrl('/solicitudes-oe/crear');
  }

  goToNewGasto() {
    this.router.navigateByUrl('/gastos/crear');
  }

  goToNewOC() {
    this.router.navigateByUrl('/solicitudes-oc/crear');
  }

  goToNewDTI() {
    this.router.navigateByUrl('/solicitudes-dti/crear');
  }

  goToNewBH() {
    this.router.navigateByUrl('/solicitudes-bh/crear');
  }

  goToActaAsignacion() {
    this.router.navigateByUrl('/acta-inventario/crear');
  }

  goToInformeActividades() {
    this.router.navigateByUrl('/solicitudes-bh/adjuntos/1');
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
  }

}
