<!-- project-wizard.component.html -->
<!-- start page title -->
<app-pagetitle title="Formulario Solicitudes al Comité de Ética" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <ng-stepper #cdkStepper [linear]="true" class="wizard">

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">1.</span>
                        <span>Información</span>
                        </ng-template>
                        <app-solicitud-comite-step1 [parentComponent]="this"></app-solicitud-comite-step1>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">2.</span>
                        <span>Identificación</span>
                        </ng-template>
                        <app-solicitud-comite-step1 [parentComponent]="this"></app-solicitud-comite-step1>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">3.</span>
                        <span>Estructura</span>
                        </ng-template>
                        <app-solicitud-comite-step1 [parentComponent]="this"></app-solicitud-comite-step1>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">4.</span>
                        <span>Validaciones</span>
                        </ng-template>
                        <app-solicitud-comite-step1 [parentComponent]="this"></app-solicitud-comite-step1>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">5.</span>
                        <span>Documentación</span>
                        </ng-template>
                        <app-solicitud-comite-step1 [parentComponent]="this"></app-solicitud-comite-step1>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">6.</span>
                        <span>Resumen</span>
                        </ng-template>
                        <app-solicitud-comite-step1 [parentComponent]="this"></app-solicitud-comite-step1>
                    </cdk-step>
                </ng-stepper>
            </div>
        </div>
    </div>
</div>