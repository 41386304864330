import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import MetisMenu from 'metismenujs';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { MENU } from './menu';
import { MenuItem } from './menu.model';

import { SIDEBAR_COLOR } from '../layouts.model';
import { AuthStateService } from 'src/app/account/auth/auth.state.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})


/**
 * Sidebar Component
 */
export class SidebarComponent implements OnInit {

  @ViewChild('sideMenu') sideMenu!: ElementRef;
  menu: any;
  menuItems: MenuItem[] = [];

  userDetails: any = {};

  isSidebar: any;

  permisos: any = {
    1: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35],
    2: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35],
    3: [1,2,4,5,6,7,8,15,19,30],
    4: [1,2,4,5,6,7,15,19],
    5: [1,2,4,5,6,7,19],
    6: [1,4,5,7,15]
  };

  constructor(
    private router: Router, 
    public translate: TranslateService, 
    private authStateService: AuthStateService,
    private userService: UserService // Inyecta UserService
  ) {
    translate.setDefaultLang('en');
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });

    this.authStateService.authState$.subscribe(authState => {
      this.userDetails.name = authState?.name
      this.userDetails.role = authState?.role
    });
  }

  ngOnInit(): void {
    this.menuItems = MENU;
    
    this.isSidebar = SIDEBAR_COLOR;
    if(this.isSidebar === 'dark') {
      document.body.setAttribute('data-sidebar', 'dark');
    }

    this.updatePendingTasksBadge(); // Llama a la función para actualizar el badge
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /***
   * Activate droup down set 
   */
  ngAfterViewInit() {
    this.menu = new MetisMenu('#side-menu');
    this._activateMenuDropdown();
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: any) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links: any = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) {
              childAnchor.classList.add('mm-active');
            }
            if (childDropdown) {
              childDropdown.classList.add('mm-active');
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  }

  tienePermiso(item: MenuItem): boolean {
    const misPermisos = this.permisos[this.userDetails.role];
    return misPermisos.includes(item.id);
  }

  // Función para actualizar el badge
  updatePendingTasksBadge() {
    this.userService.getMisPendientes().then(pendientes => {
      const misPendientesMenu = this.menuItems.find(item => item.id === 15);
      if (misPendientesMenu) {
        misPendientesMenu.badge.text = pendientes.length.toString();
        misPendientesMenu.badge.variant = pendientes.length === 0 ? 'secondary' : 'danger'; // Cambia a gris si no hay pendientes
      }
    }).catch(error => {
      console.error("Error al obtener pendientes:", error);
    });
  }
  
}
