import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { CarrerasService } from 'src/app/core/services/carreras.service';
import { UserService } from 'src/app/core/services/user.service';
import { PendingTasks } from 'src/app/core/models/pending-tasks.model';


@Component({
    selector: 'app-mis-pendientes',
    templateUrl: './mis-pendientes.component.html',
    styleUrls: ['./mis-pendientes.component.scss']
})
export class MyPendingActionsComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;

    tasks: PendingTasks[];

    constructor(
        private userService: UserService,
        injector: Injector
    ) {
        super(injector);
        this.tasks = [];
    }

    override async ngOnInit() {
        super.ngOnInit();
        this.tasks = await this.userService.getMisPendientes();

        console.log(this.tasks);
    }

    getPeerReviews(): PendingTasks[] {
        return this.tasks.filter(t => t.context === 'EVALUACION PAR')
    }

    getDirEvals(): PendingTasks[] {
        return this.tasks.filter(t => t.context === 'EVALUACION DIRINVEST')
    }

    getFacultyTasks(): PendingTasks[] {
        return this.tasks.filter(t => (t.context === 'CARTA APOYO' || t.context === 'COSTOS INDIRECTOS'))
    }

    goToPeerReview(id: number): void {
        this.router.navigateByUrl('evaluacion-pares/'+id);
    }

    goToDirectiveReview(id: number ): void {
        this.router.navigateByUrl('/evaluacion-dirinves/'+id);
    }

    goToFacultyTask(item: PendingTasks): void {
        if(item.context === 'CARTA APOYO'){
            this.router.navigateByUrl('/cartas-apoyo/editar/'+item.project_id);
        } else {
            this.router.navigateByUrl('/costos-indirectos/editar/'+item.project_id);
        }
    }


}
