import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENU',
        isTitle: true
    },
    {
        id: 2,
        label: 'Tablero',
        icon: 'bx bxs-dashboard',
        link: '/',
    },
    {
        id: 3,
        label: 'Convocatorias',
        icon: 'bx bx-calendar-check',
        link: '/convocatorias',
    },
    {
        id: 4,
        label: 'Bases de Concursos',
        icon: 'bx bx-book',
        link: '/bases',
    },
    {
        id: 5,
        label: 'Proyectos',
        icon: 'bx bx-clipboard',
        subItems: [
            {
                id: 6,
                label: 'Nuevo Proyecto',
                link: '/proyectos/crear',
                parentId: 5
            },
            {
                id: 7,
                label: 'Lista de Proyectos',
                link: '/proyectos/lista',
                parentId: 5
            },
            {
                id: 8,
                label: 'Cartas de Apoyo',
                link: '/cartas-apoyo/lista',
                parentId: 5
            },
            {
                id: 9,
                label: 'Currículos',
                link: '/curriculos/lista',
                parentId: 5
            },
            {
                id: 10,
                label: 'Evaluaciones',
                link: '/evaluaciones/lista',
                parentId: 5
            },
            {
                id: 11,
                label: 'Actas de Consejo',
                link: '/actas-consejo/lista',
                parentId: 5
            },
            {
                id: 30,
                label: 'Costos Indirectos',
                link: '/costos-indirectos/lista',
                parentId: 5
            },
            {
                id: 12,
                label: 'Contratos',
                link: '/contratos/lista',
                parentId: 5
            },
            {
                id: 13,
                label: 'Rendiciones Gastos',
                link: '/gastos/',
                parentId: 5
            },
            {
                id: 27,
                label: 'Solicitudes de Egreso',
                link: '/solicitudes-oe/',
                parentId: 5
                
            },
            {
                id: 28,
                label: 'Solicitudes de Compra',
                link: '/solicitudes-oc/',
                parentId: 5
                
            },
            {
                id: 29,
                label: 'Solicitudes de Compra DTI',
                link: '/solicitudes-dti/',
                parentId: 5
                
            },
            {
                id: 31,
                label: 'Actas de Inventario',
                link: '/acta-inventario/',
                parentId: 5
                
            },
            {
                id: 32,
                label: 'Solicitudes de Boletas de Honorario',
                link: '/solicitudes-bh/',
                parentId: 5
                
            },
            {
                id: 14,
                label: 'Informes de Avance',
                link: '/informe-avance/',
                parentId: 5
            },
        ]
    },
    {
        id: 33,
        label: 'Solicitudes al CEC',
        icon: 'bx bx-book-open',
        subItems: [
            {
                id: 34,
                label: 'Nueva Solicitud',
                link: '/solicitudes-comite/crear',
                parentId: 5
            },
            {
                id: 35,
                label: 'Lista de Solicitudes',
                link: '/solicitudes-comite/',
                parentId: 5
            },
        ]
    },
    {
        id: 15,
        label: 'Mis Pendientes',
        icon: 'bx bx-bell',
        badge: {
            variant: 'danger',
            text: '2',
        },
        link: '/mis-pendientes',
    },
    {
        id: 17,
        label: 'Contabilidad',
        icon: 'bx bx-dollar-circle',
        link: '/contabilidad',
    },
    {
        id: 19,
        isLayout: true
    },
    {
        id: 20,
        label: 'ADMINISTRACIÓN',
        isTitle: true
    },
    {
        id: 18,
        label: 'Reportes',
        icon: 'bx bxs-report',
        link: '/reportes',
    },
    {
        id: 16,
        label: 'Gestionar Rúbricas',
        icon: 'bx bx-badge-check',
        link: '/rubricas',
    },
    {
        id: 21,
        label: 'Mantenedores',
        icon: 'bx bx-cog',
        subItems: [
            {
                id: 22,
                label: 'Usuarios',
                link: '/usuarios',
                parentId: 21
            },
            {
                id: 23,
                label: 'Roles y Permisos',
                link: '/roles',
                parentId: 21
            },
            {
                id: 24,
                label: 'Facultades',
                link: '/facultades',
                parentId: 21
            },
            {
                id: 25,
                label: 'Carreras',
                link: '/carreras',
                parentId: 21
            },
            {
                id: 26,
                label: 'Plantillas PDF',
                link: '/plantillas/lista',
                parentId: 21
            }
        ]
    }
];

