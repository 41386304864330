import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { CECResponse } from 'src/app/core/models/cec.model';
import { CECService } from 'src/app/core/services/cec.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cec-lista',
  templateUrl: './cec-lista.component.html',
  styleUrls: ['./cec-lista.component.scss']
})
export class CECListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['title', 'faculty', 'career', 'user', 'status', 'actions'];
  dataSource: MatTableDataSource<CECResponse> = new MatTableDataSource<CECResponse>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  solicitudes: CECResponse[] = [];

  constructor(private cecService: CECService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getSolicitudes();
  }

  async getSolicitudes() {
    this.openLoading();
    this.solicitudes = await this.cecService.getAllCECs();
    /*this.solicitudes.map((cp) => { 
      cp.actions = [
        {
          name: 'Ver Solicitud',
          icon: 'bx bx-search-alt',
          url: `/api/supportletter/pdf?id=${cp.id}`
        }
      ]

      if(cp.status == 'CREADA' || cp.status == 'OBSERVADA'){
        if(cp.user.id === this.authService.user.id){
        cp.actions.push({
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/cartas-apoyo/editar/${cp.id}`
        },)
    });*/
    this.dataSource.data = this.solicitudes;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  doAction(accion: Action, id: number){
    if(accion.name == "Enviar") this.changeStatus('ENVIADA', accion.name, id);
    else this.router.navigateByUrl(accion.url as string);
  }

  async changeStatus(s: string, name: String, id: number) {
    //Confirm dialog
    let resp = await Swal.fire({
      title: "Confirmación", 
      text: `¿Está seguro que desea ${name} la Solicitud al CEC?`,
      icon: 'warning',
      confirmButtonText: `Si, ${name}`,
      cancelButtonText: 'No, cancelar',
      showCancelButton: true
    });

    if(resp.isConfirmed){
      let ret = await this.cecService.changeStatus(s, id);
      if(ret == 'OK'){
        await this.globalSuccess(`Se ha cambiado el estado del Proyecto correctamente`);
        this.getSolicitudes();
      }
    }
  }

  create(): void {
    this.router.navigateByUrl('/solicitudes-comite/crear');
  }

}
