import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CallPeriod, CallPeriodRequiest } from '../models/call-period.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CallPeriodService {
  putCallPeriod(callPeriod: CallPeriodRequiest, idCallPeriod: number) {
    return lastValueFrom(this.http.put<CallPeriod>(`${environment.apiURL}/callperiod/${idCallPeriod}`, this.toRequestObject(callPeriod)));
  }
  getCallPeriod(idCallPeriod: number) {
    return lastValueFrom(this.http.get<CallPeriod>(`${environment.apiURL}/callperiod/${idCallPeriod}`));
  }

  getCurrentCallPeriod() {
    return lastValueFrom(this.http.get<CallPeriod>(`${environment.apiURL}/callperiod/current`));
  }

  constructor(private http: HttpClient) { }

  getCallPeriods() { 
    return lastValueFrom(this.http.get<CallPeriod[]>(`${environment.apiURL}/callperiod`));
  }

  postCallPeriod(callPeriod: CallPeriodRequiest){
    return lastValueFrom(this.http.post<CallPeriod>(`${environment.apiURL}/callperiod`, this.toRequestObject(callPeriod)));
  }

  toRequestObject(callPeriod: CallPeriodRequiest){
    return {
      name: callPeriod.name,
      year: callPeriod.year,
      code: callPeriod.code,
      startDate: this.toMysqlFormat(callPeriod.startDate),
      endDate: this.toMysqlFormat(callPeriod.endDate)
    }
  }

  private toMysqlFormat(date: Date): string {
    const isoString = date.toISOString();
    return isoString.slice(0, 10);
  }
}
