import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { Faculty, FacultyRequest } from '../models/faculty.model';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class FacultadesService {

    constructor(private http: HttpClient) {}

    getFacultad(facultadID: number): Promise<Faculty> {
        return lastValueFrom(this.http.get<Faculty>(`${environment.apiURL}/faculty/${facultadID}`));
    }

    getTodasFacultades(): Promise<Faculty[]> {
        return lastValueFrom(this.http.get<Faculty[]>(`${environment.apiURL}/faculty`));
    }

    getTodasFacultadesActivas(): Promise<Faculty[]> {
        return lastValueFrom(this.http.get<Faculty[]>(`${environment.apiURL}/faculty/?is_active=true`));
    }

    putFacultad(facultad: FacultyRequest, idFacultad: number): Promise<Faculty> {
        return lastValueFrom(this.http.put<Faculty>(`${environment.apiURL}/faculty/${idFacultad}`, facultad));
    }

    postFacultad(facultad: FacultyRequest): Promise<Faculty> {
        return lastValueFrom(this.http.post<Faculty>(`${environment.apiURL}/faculty`, facultad));
    }

}
