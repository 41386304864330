import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountUpModule } from 'ngx-countup';

import { ScrollToModule } from '@hkjeffchan/ngx-scroll-to';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbAccordionModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule, NgbPopoverModule, NgbCollapseModule, NgbDatepickerModule, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';

// Routings
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.modules';


// Components
import { DashboardsComponent } from './dashboard/dashboards/dashboards.component';
import { ConvocatoriaListaComponent } from './convocatoria/convocatoria-lista/convocatoria-lista.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from '@angular/material/input';
import { ConvocatoriaFormularioComponent } from './convocatoria/convocatoria-formulario/convocatoria-formulario.component';
import { I18n, NgbDatepickerSpanish } from '../core/NgbDatepickerSpanish';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxGanttModule } from '@worktile/gantt';
import {
  NgxMatDateAdapter,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { FlatpickrModule } from 'angularx-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import flatpickr from 'flatpickr';
import { ReactiveFormsModule } from '@angular/forms';
import { UsuarioListaComponent } from './usuario/usuario-lista/usuario-lista.component';
import { UsuarioFormularioComponent } from './usuario/usuario-formulario/usuario-formulario.component';
import { Select2Module } from 'ng-select2-component';
import { RolesListaComponent } from './roles/roles-lista/roles-lista.component';
import { RolesFormularioComponent } from './roles/roles-formulario/roles-formulario.component';
import { ProyectoWizardComponent } from './proyecto/proyecto-form/proyecto-wizard/proyecto-wizard.component';
import { ProyectoStep1Component } from './proyecto/proyecto-form/proyecto-step1/proyecto-step1.component';
import { ProyectoStep2Component } from './proyecto/proyecto-form/proyecto-step2/proyecto-step2.component';
import { ProyectoStep3Component } from './proyecto/proyecto-form/proyecto-step3/proyecto-step3.component';
import { ProyectoStep4Component } from './proyecto/proyecto-form/proyecto-step4/proyecto-step4.component';
import { ProyectoStep5Component } from './proyecto/proyecto-form/proyecto-step5/proyecto-step5.component';
import { CustomDateAdapter } from '../shared/custom-date-adapter';
import { ProyectoStep6Component } from './proyecto/proyecto-form/proyecto-step6/proyecto-step6.component';
import { FormFacultyComponent } from './facultad/form-facultad/form-facultad.component';
import { ListFacultyComponent } from './facultad/list-facultad/list-facultad.component';
import { ListCareerComponent } from './carrera/list-carrera/list-carrera.component';
import { FormCarreraComponent } from './carrera/form-carrera/form-carrera.component';
import { PersonActivitiesComponent } from './proyecto/proyecto-form/proyecto-step5/person-activities/person-activities.component';
import { ProyectoListaComponent } from './proyecto/proyecto-lista/proyecto-lista.component';
import { CartaApoyoListaComponent } from './carta-apoyo/carta-apoyo-lista/carta-apoyo-lista.component';
import { CartaApoyoFormularioComponent } from './carta-apoyo/carta-apoyo-formulario/carta-apoyo-formulario.component';
import { ListaPlantillasComponent } from './plantillas/lista-plantillas/lista-plantillas.component';
import { ProyectoDetalleComponent } from './proyecto/proyecto-detalle/proyecto-detalle.component';
import { CurriculumListaComponent } from './curriculum/curriculum-lista/curriculum-lista.component';
import { CurriculimFormularioComponent } from './curriculum/curriculum-formulario/curriculum-formulario.component';
import { CostosIndirectosListaComponent } from './costos-indirectos/costos-indirectos-lista/costos-indirectos-lista.component';
import { CostosIndirectosFormularioComponent } from './costos-indirectos/costos-indirectos-formulario/costos-indirectos-formulario.component';
import { ContratoFormularioComponent } from './contrato/contrato-formulario/contrato-formulario.component';
import { ContratoListaComponent } from './contrato/contrato-lista/contrato-lista.component';
import { PeerReviewersComponent } from './proyecto/pares-evaluadores/pares-evaluadores.component';
import { MyPendingActionsComponent } from './mis-pendientes/mis-pendientes.component';
import { PeerReviewComponent } from './evaluacion-pares/evaluacion-pares.component';
import { DirectiveReviewComponent } from './evaluacion-dirinves/evaluacion-dirinves.component';
import { RubricaListaComponent } from './rubrica/rubrica-lista/rubrica-lista.component';
import { RubricaFormularioComponent } from './rubrica/rubrica-formulario/rubrica-formulario.component';
import { RubricaRespuestaComponent } from "./rubrica/rubrica-respuesta/rubrica-respuesta.component";
import { CouncilMinuteComponent } from './acta-dirinves/acta-dirinves.component';
import { ComitteeRequestWizardComponent } from './solicitud-comite/solicitud-comite-form/solicitud-comite-wizard/solicitud-comite-wizard.component';
import { ComitteeRequestStep1Component } from './solicitud-comite/solicitud-comite-form/solicitud-comite-step1/solicitud-comite-step1.component';
import { ProyectoGastoComponent } from './proyecto/proyecto-gasto/proyecto-gasto.component';
import { SolicitudOEFormularioComponent } from './solicitud-oe/solicitud-oe-formulario/solicitud-oe-formulario.component';
import { SolicitudOEListaComponent } from './solicitud-oe/solicitud-oe-lista/solicitud-oe-lista.component';
import { GastoFormularioComponent } from './gasto/gasto-formulario/gasto-formulario.component';
import { GastoListaComponent } from './gasto/gasto-lista/gasto-lista.component';
import { SolicitudOCFormularioComponent } from './solicitud-oc/solicitud-oc-formulario/solicitud-oc-formulario.component';
import { SolicitudOCListaComponent } from './solicitud-oc/solicitud-oc-lista/solicitud-oc-lista.component';
import { SolicitudDTIFormularioComponent } from './solicitud-dti/solicitud-dti-formulario/solicitud-dti-formulario.component';
import { SolicitudDTIListaComponent } from './solicitud-dti/solicitud-dti-lista/solicitud-dti-lista.component';
import { ActaInventarioFormularioComponent } from './inventario-equipo/inventario-equipo-formulario/inventario-equipo-formulario.component';
import { ActaInventarioListaComponent } from './inventario-equipo/inventario-equipo-lista/inventario-equipo-lista.component';
import { SolicitudBHFormularioComponent } from './solicitud-bh/solicitud-bh-formulario/solicitud-bh-formulario.component';
import { SolicitudBHListaComponent } from './solicitud-bh/solicitud-bh-lista/solicitud-bh-lista.component';
import { SolicitudBHAdjuntosComponent } from './solicitud-bh/solicitud-bh-adjuntos/solicitud-bh-adjuntos.component';
import { InformeAvanceFormularioComponent } from './informe-avance/informe-avance-formulario/informe-avance-formulario.component';
import { InformeAvanceListaComponent } from './informe-avance/informe-avance-lista/informe-avance-lista.component';
import { TableroComponent } from './tablero/dashboards.component';
import { MatSelectModule } from '@angular/material/select';
import {ProyectoViewComponent } from './proyecto/proyecto-detalle/proyecto-view/proyecto-view.component';
import { PeerReviewViewComponent } from './evaluacion-pares-view/evaluacion-pares-view.component';
import { RubricaRespuestaViewComponent } from './rubrica/rubrica-respuesta-view/rubrica-respuesta-view.component';
import { CurriculumViewComponent } from './curriculum/curriculum-view/curriculum-view.component';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
      dateInput: 'l, LT'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
  },
};

@NgModule({
  declarations: [
    DashboardsComponent,
    ConvocatoriaListaComponent,
    ConvocatoriaFormularioComponent,
    UsuarioListaComponent,
    UsuarioFormularioComponent,
    RolesListaComponent,
    RolesFormularioComponent,
    ProyectoWizardComponent,
    ProyectoStep1Component,
    ProyectoStep2Component,
    ProyectoStep3Component,
    ProyectoStep4Component,
    ProyectoStep5Component,
    ProyectoStep6Component,
    ProyectoListaComponent,
    ProyectoViewComponent,
    FormFacultyComponent,
    ListFacultyComponent,
    ListCareerComponent,
    FormCarreraComponent,
    PersonActivitiesComponent,
    CartaApoyoListaComponent,
    CartaApoyoFormularioComponent,
    ListaPlantillasComponent,
    ProyectoDetalleComponent,
    CurriculumListaComponent,
    CurriculimFormularioComponent,
    CurriculumViewComponent,
    CostosIndirectosListaComponent,
    CostosIndirectosFormularioComponent,
    ContratoFormularioComponent,
    ContratoListaComponent,
    PeerReviewersComponent,
    MyPendingActionsComponent,
    PeerReviewComponent,
    PeerReviewViewComponent,
    DirectiveReviewComponent,
    RubricaListaComponent,
    RubricaFormularioComponent,
    RubricaRespuestaComponent,
    RubricaRespuestaViewComponent,
    CouncilMinuteComponent,
    ComitteeRequestWizardComponent,
    ComitteeRequestStep1Component,
    ProyectoGastoComponent,
    SolicitudOEFormularioComponent,
    SolicitudOEListaComponent,
    GastoFormularioComponent,
    GastoListaComponent,
    SolicitudOCFormularioComponent,
    SolicitudOCListaComponent,
    SolicitudDTIFormularioComponent,
    SolicitudDTIListaComponent,
    ActaInventarioFormularioComponent,
    ActaInventarioListaComponent,
    SolicitudBHFormularioComponent,
    SolicitudBHListaComponent,
    SolicitudBHAdjuntosComponent,
    InformeAvanceFormularioComponent,
    InformeAvanceListaComponent,
    TableroComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ScrollToModule.forRoot(),
    NgApexchartsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CountUpModule,
    SharedModule,
    MatTableModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbDatepickerModule,
    FlatpickrModule.forRoot(),
    GoogleMapsModule,
    ReactiveFormsModule,
    Select2Module,
    CdkStepperModule,
    NgStepperModule,
    CKEditorModule,
    NgxGanttModule,
    NgxMatDatetimePickerModule,
    NgbModule,
    NgxDropzoneModule
  ],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerSpanish },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }

export function flatpickrFactory() {
  flatpickr.localize(Spanish);
  return flatpickr;
}
