import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Rubric } from '../models/rubrica.model';
import { Minute } from '../models/minute.model';

@Injectable({
  providedIn: 'root'
})
export class MinuteService {

  constructor(private http: HttpClient) { }

  getMinuteByProject(id: number): Promise<Minute[]> {
    return lastValueFrom(this.http.get<Minute[]>(`${environment.apiURL}/minute?project=${id}`));
  }

  getMinute(id: number): Promise<Minute> {
    return lastValueFrom(this.http.get<Minute>(`${environment.apiURL}/minute/${id}`));
  }

  putMinute(id:number, data: any): Promise<Minute> {
    return lastValueFrom(this.http.put<Minute>(`${environment.apiURL}/minute/${id}`, data));
  }
}
