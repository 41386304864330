<!-- start page title -->
<app-pagetitle title="Acta Consejo Dirinves" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row" *ngIf="project">
                    <div class="col-lg-2">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="callperiod" readonly [value]="project.callPeriod!.code">
                            <label for="callperiod">Convocatoria</label>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingSelectFacu" name="faculty" readonly [value]="project.faculty!.acronym">
                            <label for="floatingSelectFacu">Facultad / Unidad</label>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" readonly [value]="project.folio">
                            <label for="floatingSelectFacu">Folio</label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingSelect" name="career" readonly [value]="project.career!.name">
                            <label for="floatingSelect">Programa / Carrera</label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="projectType" name="type" readonly [value]="getProjectTypeLabel(project)">
                            <label for="projectType">Tipo Proyecto</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <form [formGroup]="formActa" class="example-form">
                <div class="card-body">
                    <h4>ACTA CONSEJO DE INVESTIGACIÓN</h4>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class=" mb-3">
                                <label for="titulo">Título Consejo</label>
                                <input type="text" class="form-control" name="title" id="titulo" formControlName="title">
                                <div *ngIf="formActa.get('title')?.touched && formActa.get('title')?.invalid" class="text-danger">
                                    Este campo es requerido
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="startDate">Fecha Consejo</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" name="date_council" formControlName="date_council">
                            <div *ngIf="formActa.get('date_council')?.touched && formActa.get('date_council')?.invalid" class="text-danger">
                                Este campo es requerido
                              </div>
                        </div>
                        <div class="col-lg-6">
                            <div class=" mb-3">
                                <label for="nroVoto">Número Voto</label>
                                <input type="text" class="form-control" name="vote_number" id="nroVoto" formControlName="vote_number">
                                <div *ngIf="formActa.get('vote_number')?.touched && formActa.get('vote_number')?.invalid" class="text-danger">
                                    Este campo es requerido
                                  </div>
                            </div>
                        </div>
                        <div class="mb-4 mb-md-0 col-lg-6">
                            <h5 class="font-size-14 mb-2">Estado</h5>
                            <div class="form-check form-check-inline form-radio-success">
                                <input class="form-check-input" type="radio" name="status" id="inlineRadio1" formControlName="status"
                                    value="APROBADO">
                                <label class="form-check-label" for="inlineRadio1">Aprobado</label>
                            </div>
                            <div class="form-check form-check-inline form-radio-warning">
                                <input class="form-check-input" type="radio" name="status" id="inlineRadio2" formControlName="status"
                                    value="APROBADO CON REPAROS">
                                <label class="form-check-label" for="inlineRadio2">Aprobado con Reparos</label>
                            </div>
                            <div class="form-check form-check-inline form-radio-danger">
                                <input class="form-check-input" type="radio" name="status" id="inlineRadio3" formControlName="status"
                                    value="RECHAZADO">
                                <label class="form-check-label" for="inlineRadio3">Rechazado</label>
                            </div>
                            <div *ngIf="formActa.get('status')?.touched && formActa.get('status')?.invalid" class="text-danger">
                                Este campo es requerido
                              </div>
                        </div>
                        <div class="col-lg-12">
                            <div class=" mb-3">
                                <label for="acta">Acta Consejo</label>
                                <textarea class="form-control" id="acta" name="type" rows="10" formControlName="body_text"></textarea>
                            </div>
                        </div>
                    </div>
                    <h4 *ngIf="directiveEvals.length > 0">Recomendación de la Dirección de Investigación: <span class="text-warning">{{directiveEvals[0].qualitativeEvaluation}}</span><b> puntaje total: {{directiveEvals[0].totalScore}}</b></h4>
                </div>
            </form>

            <div class="card-body" *ngIf="workload">
                <h4 class="mt-5">RECOMENDACIÓN CARGA HORARIA</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th style="width: 40%;">INVESTIGADOR</th>
                                <th style="width: 20%;" class="table-warning">Solicitado</th>
                                <th style="width: 20%;" class="table-success">Recomendación Dirección Investigación</th>
                                <th style="width: 20%;">Horas en otra Investigación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of workload.investigadores">
                                <th>{{item.name}}</th>
                                <th class="table-warning" style="text-align: center;">{{item.requested_hours}}</th>
                                <th class="table-success">
                                    <input type="number" class="form-control" [(ngModel)]="item.recomended_hours">
                                </th>
                                <th style="text-align: center;">{{item.other_hours > 0 ? item.other_hours : '-'}}</th>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Duración (semanas)</th>
                                <th class="table-warning" style="text-align: center;">{{workload.requested_duration}}</th>
                                <th class="table-success" style="text-align: center;">{{workload.recomended_duration}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Total Horas</th>
                                <th class="table-warning" style="text-align: center;">{{getTotalHoraSolicitado() | number}}</th>
                                <th class="table-success" style="text-align: center;">{{getTotalHoraRecomendado() | number}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Presupuesto Operativo</th>
                                <th class="table-warning" style="text-align: center;">{{workload.requested_budget | number}}</th>
                                <th class="table-success" style="text-align: center;">
                                    <input type="number" class="form-control" [(ngModel)]="workload.recomended_budget">
                                </th>
                                <th></th>
                            </tr>
                            <tr>
                                <th style="color: red; font-weight: bold;">Total Horas x ${{workload.hourly_rate | number}}/hora</th>
                                <th class="table-warning" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraSolicitado()*workload.hourly_rate | number}}</th>
                                <th class="table-success" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraRecomendado()*workload.hourly_rate | number}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th style="color: red; font-weight: bold;">Presupuesto Total II</th>
                                <th class="table-warning" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraSolicitado()*workload.hourly_rate + workload.requested_budget | number}}</th>
                                <th class="table-success" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraRecomendado()*workload.hourly_rate + workload.recomended_budget  | number}}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="d-flex flex-row-reverse">
                    <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                    <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end row -->
