import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import { Project } from 'src/app/core/models/project.model';
import { OtherAcademicsView } from '../proyecto-detalle.component';
import { EstimateExpenseRequest } from 'src/app/core/models/estimate_expense.model';
import { InvestigativeLine } from 'src/app/core/models/utils.model';
import { UtilsService } from 'src/app/core/services/utils.service';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap/accordion/accordion';

export interface Semester{
    name: string;
    year: number;
    semester: number;
}

@Component({
    selector: 'app-proyecto-view',
    templateUrl: './proyecto-view.component.html',
    styleUrls: ['./proyecto-view.component.scss']
})
export class ProyectoViewComponent {

  @ViewChild('accordion', { static: true }) accordion!: NgbAccordion;
  closeOthers = true; // Inicialmente cerrado, cambiar a `false` para abrir todo
  activePanelIds: string[] = []; // Lista de paneles activos

    private _project?: Project;
    investigativeLines: InvestigativeLine[] = [];

    @Input() 
    set project(value: Project | undefined) {
        this._project = value;
        if (value) {
            this.initializeData();
        }
    }
    
    get project(): Project | undefined {
        return this._project;
    }
    
    semesters: Semester[] = [];
    otherAcademics: OtherAcademicsView[] = [];

    secciones = [
      {
        value: 'OPERACION',
        name: '1. GASTOS DE OPERACIÓN',
        categories: [
          {id:1, name:'1.1 Maquinas y equipos'}, 
          {id:2, name:'1.2 Software y licencias'}, 
          {id:3, name:'1.3 Insumos de Laboratorio'}
        ]
      },
      {
        name: '2. MATERIALES DE OFICINA',
        categories: [
          {id:4, name:'2.1 Materiales de Oficina )paple, lápices, corchetera, fotocopias, etc'}, 
          {id:5, name:'2.2 Otros'}
        ]
      },
      {
        name: '3. HONORARIOS ASISTENTES',
        categories: [
          {id:6, name:'3.1 Alumnos Ayudantes (max. $200.000 por alumno y año'}, 
          {id:7, name:'3.2 Tesistas de pregrado (máximo $200.000 por tesista y semestre)'},
          {id:8, name:'3.3 Tesistas de posgrado (máximo $400.000 por tesista y semestre)'},
          {id:18, name:'3.4 Colaboradores Profesionales Externos'}
        ]
      },
      {
        name: '4. VIAJES PARA REALIZAR EL PROYECTO',
        categories: [
          {id:9, name:'4.1 Movilización (máximo $100.000 en Chile y 200.000 en el extranjero)'}, 
          {id:10, name:'4.1.1 Movilización para recoger datos o consultas bibliográficas'},
          {id:11, name:'4.1.2 Movilización para asesoría científica'},
        ]
      },
      {
        name: '5. PRESENTACIÓN DE LA INVESTIGACIÓN EN EVENTOS CIENTÍFICOS O ACADÉMICOS',
        categories: [
          {id:12, name:'5.1 Gastos de impresión de pósteres, inscripción a congresos, movilización, viáticos, alojamiento investigación (máximo $100.000/evento, y con un máximo de un evento científico anual)'}, 
        ]
      },
      {
        name: '6. PUBLICACIÓN DE LA INVESTIGACIÓN',
        categories: [
          {id:13, name:'6.1. Traducir el artículo para publicar en revista especializada'}, 
          {id:14, name:'6.2 Gastos de edición'},
          {id:15, name:'6.3 Gastos de publicación'},
          {id:16, name:'6.4 Otros'}
        ]
      },
      {
        name: '7. OTROS',
        categories: [
          {id:17, name:'7.1 Otros'}, 
        ]
      }
    ];

    expenses:EstimateExpenseRequest[] = [];

    constructor(
      private utilsService: UtilsService,
      private cdr: ChangeDetectorRef
    ) { 
    }

    async initializeData() {
      this.investigativeLines = await this.utilsService.getInvestigativeLines(this.project?.type || '');
        //bundle all other academics, grouped by rut
        const otherAcademics: OtherAcademicsView[] = [];
        for(let oa of this.project!.other_academics!){
            const foundOA = otherAcademics.find(oa => oa.rut === oa.rut);
            if(!foundOA){
                otherAcademics.push({
                    name: '',
                    rut: oa.rut,
                    activities: [
                        {
                            objective: oa.objective,
                            start_date: oa.start_date,
                            end_date: oa.end_date,
                        },
                    ],
                });
            }
            if(foundOA){
                foundOA.activities.push({
                objective: oa.objective,
                start_date: oa.start_date,
                end_date: oa.end_date,
                });
            }
        }
        this.otherAcademics = otherAcademics;

        this.calculateSemesters();

        for(let expense of this.project!.estimate_expenses || []) {
          this.expenses.push({
            category: expense.category!.id, 
            amount: expense.amount, 
            name: expense.name, 
            unit_cost: expense.unit_cost, 
            objective: expense.objective,
            section: this.getSectionFromCat(expense.category!.id),
            project: this.project!.id,
            id: expense.id,
            attachmentBase64: expense.attachmentBase64,
            attachmentName: expense.attachmentName,
            attachmentId: expense.attachmentId
          });
        }
    }

    calculateSemesters() {
        //Todo: Get starting semester:
        let currentSemester = 2;
        let currentYear = 2024;
        let current_months = 0;
    
        const semesters: Semester[] = [];
    
        while(current_months < this.project!.estimated_duration_months!){
          // Get current semester
          semesters.push({
            name: `${currentYear} - ${currentSemester.toString().padStart(2, '0')}`,
            year: currentYear,
            semester: currentSemester
          });
          //advance to next semester
          currentSemester++;
          if(currentSemester > 2){
            currentSemester = 1;
            currentYear++;
          }
          current_months += 6;
        }
    
        this.semesters = semesters;
    }

    getProjectTypeLabel(projectType: string): string {
        switch (projectType) {
          case 'is_initial':
            return 'Iniciación (hasta 12 meses)';
          case 'is_regular':
            return 'Regular (hasta 24 meses)';
          case 'is_technology_transfer':
            return 'Transf. Tec. (hasta 24 meses)';
          case 'is_related_music':
            return 'Música';
          case 'is_related_literature':
            return 'Literatura';
          case 'other_related':
            return 'Otro (Indicar)';
          default:
            return '';
        }
    }
    
      getContractTypeName(contractTypeId: number| undefined): string {
        // logic to get contract type name by id
        return '';
      }
    
      getGradeName(grade: string| undefined): string {
        // logic to get grade name by id
        return '';
      }
    
      getObjectiveName(objectiveId: string| undefined): string {
        // logic to get objective name by id
        return '';
      }
    
      getPersonTotalHours(person:any): number {
        // logic to calculate total hours for a person
        return 0;
      }
    
      getTotalHours(): number {
        // logic to calculate total hours
        return 0;
      }

      getSectionFromCat(cat: number) {
        return this.secciones.find(x => x.categories.find(y => y.id === cat))?.name || '';
      }

      getGrandTotal(): number {
        let total = 0;
    
        for (let expense of this.expenses) {
          total += (expense.amount * expense.unit_cost);
        }
        
        return total;
      }

      formatCurrency(value: number): string {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
      }

      getSectionTotal(section: any): number {
        let total = 0;
        for(let category of section.categories) {
          const expenses = this.getExpensesFromCat(category.id);
          for (let expense of expenses) {
            total += (expense.amount * expense.unit_cost);
          }
        }
        return total;
      }

      getExpensesFromCat(cat: number) {
        return this.expenses.filter(x => x.category === cat);
      }
    
      getExpenseTotal(e: EstimateExpenseRequest) {
        return e.unit_cost * e.amount;
      }

      getObjective(objectiveId?: number) {
        if(!this.project?.pi_objectives) return null;
        let o = this.project.pi_objectives.find(x => x.id === objectiveId);
        if(!o) return '-';
        return o.name;
      }

      getLineName(lineId?: string) {
        if(!lineId) return null;
        return this.investigativeLines.find(x => x.id === parseInt(lineId))?.name || '-';
      }

      downloadPDF() {
        this.closeOthers = false;
        this.cdr.detectChanges();
        // Abrir todos los paneles tomando los IDs de cada uno
        this.accordion.expandAll();

        // Selecciona el elemento que deseas convertir a PDF
        const element = document.body.querySelector('#projectDisplay');
        
        // Configuración de html2pdf
        const options = {
          margin: 0.5,
          filename: 'ProyectoInvestigacion.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
      
        // Genera el PDF
        html2pdf().set(options).from(element).save();

        //this.closeOthers = true;
        //this.accordion.collapseAll();
      }

}