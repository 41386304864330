import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { UserService } from 'src/app/core/services/user.service';
import { PurchaseRequest } from 'src/app/core/models/purchase-request.model';

@Component({
  selector: 'app-solicitud-oc-lista',
  templateUrl: './solicitud-oc-lista.component.html',
  styleUrls: ['./solicitud-oc-lista.component.scss']
})

export class SolicitudOCListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['projectName', 'provider', 'amount', 'status', 'actions'];
  dataSource: MatTableDataSource<PurchaseRequest> = new MatTableDataSource<PurchaseRequest>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  requests: PurchaseRequest[] = [];

  constructor(private userService: UserService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getRequests();
  }

  async getRequests() {
    this.openLoading();
    //this.users = await this.userService.getUsers();
    this.requests = [
      {
        id: 1,
        projectName: '202302-FAIN-001 - Proyecto TEST',
        provider: 'SODIMAC',
        date: new Date(),
        amount: 250000,
        description: 'SOLO UN TEST',
        status: 'ENVIADA',
        actions: [{
          name: 'Aprobar Solicitud',
          icon: 'bx bxs-check-circle',
          url: ''
        },
        {
          name: 'Rechazar Solicitud',
          icon: 'bx bxs-x-circle',
          url: ''
        },
        {
          name: 'Ver',
          icon: 'bx bx-search-alt',
          url: ''
        }]
      },
      {
        id: 2,
        projectName: '202302-FAIN-001 - Proyecto TEST',
        provider: 'Ripley',
        date: new Date(),
        amount: 220000,
        description: 'SOLO UN TEST',
        status: 'ACEPTADA',
        actions: [
          {
            name: 'Ver',
            icon: 'bx bx-search-alt',
            url: ''
          },
          {
            name: 'Agregar Rendición Gasto',
            icon: ' bx bx-money',
            url: '/gasto/crear'
          }
        ]
      },
    ]

    this.dataSource.data = this.requests;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  doAction(accion: Action, id: number){
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/usuarios/crear');
  }

}
