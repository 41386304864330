<!-- start page title -->
<app-pagetitle title="Formulario Carrera" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formCarrera" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
                    <div class="row">
                        <div class="col col-lg-10">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Ej. Ingeniería Civil Informática" formControlName="nombre">
                                <label for="name">Nombre Carrera</label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="mb-4">
                                <h5 class="font-size-14 mb-2">Estado</h5>
                                <div class="form-check form-switch form-switch-lg mb-lg-3" dir="ltr">
                                    <input class="form-check-input" type="checkbox" id="formCheckActivo" formControlName="activo">
                                    <label class="form-check-label" for="formCheckActivo">
                                        {{ formCarrera.value.activo ? 'Activo' : 'Inactivo' }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" aria-label="Floating label select example" formControlName="facultad">
                                    <option value="">Eliga una Facultad</option>
                                    <option *ngFor="let faculty of facultades" [value]="faculty.id">{{faculty.name}}</option>
                                </select>
                                <label for="floatingSelect">Facultad</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
