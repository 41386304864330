import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/shared/base-page';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { SupportLetter, SupportLetterRequest } from 'src/app/core/models/support_letter.model';
import { SupportLetterService } from 'src/app/core/services/support-letter.service';
import { PersonEstimation } from 'src/app/core/models/person_estimation.model';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project.service';

export interface Semester{
  name: string;
  year: number;
  semester: number;
}

@Component({
  selector: 'app-carta-apoyo-formulario',
  templateUrl: './carta-apoyo-formulario.component.html',
  styleUrls: ['./carta-apoyo-formulario.component.scss']
})
export class CartaApoyoFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formSupportLetter: FormGroup = this.fb.group({
    isSigned: new FormControl(false),
    people_estimation: this.fb.array([]),
    rut: new FormControl('', [Validators.required]),
  });

  supportLetter?: SupportLetter = undefined;
  idSupportLetter: number = 0;
  personEstimations: PersonEstimation[] = [];
  project?: Project = undefined;
  semesters: Semester[] = [];
  
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private supportLetterService: SupportLetterService,
    private projectService: ProjectService,
    injector: Injector
  ) { 
    super(injector);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.openLoading();
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Cartas de Apoyo' },
      { label: 'Editar Carta de Apoyo', active: true }
    ];

    this.route.params.pipe(
      switchMap(params => {
          this.idSupportLetter = params['id'];

          if (this.idSupportLetter) {
              return this.supportLetterService.getSupportLetter(this.idSupportLetter);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: async(response) => {
            if (response ) {
                this.supportLetter = response;

                /*this.formRole.patchValue({
                  name: this.role.name
                });*/
                this.project = await this.projectService.getProject(this.supportLetter.project.id);
                this.personEstimations = await this.supportLetterService.getPersonEstimation(response.project.id, response.faculty!.id);
                
                this.formSupportLetter.patchValue({
                  isSigned: this.supportLetter.isSigned
                })

                this.personEstimations.forEach((pe, index) => {
                  
                  while (this.peopleEstimations.length <= index) {
                    const newGroup = this.fb.group({
                      name: [pe.name],
                      rut: [pe.rut],
                      faculty: [pe.faculty?.acronym],
                      contract_type: [pe.contract_type],
                      grade: [pe.degree],
                      weekly_hours: this.fb.array(pe.hours!.map(h => this.fb.control(h.hours))),
                      weeks: [pe.weeks]
                    });
                    this.peopleEstimations.push(newGroup);
                  }
                });

                this.calculateSemesters();
                
            }

            this.breadCrumbItems = [
              { label: 'Cartas de Apoyo' },
              { label: 'Editar Carta de Apoyo', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Support Letter:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  get peopleEstimations(): FormArray {
    return this.formSupportLetter.get('people_estimation') as FormArray;
  }

  /*get semesters(): Semester[] {

    const semesters: Semester[] = [];

    for(let pe of this.personEstimations){
      for(let h of pe.hours? pe.hours : []){
        if(!semesters.find(s => s.name === (h.semester + "" + h.year))){
          semesters.push({name: h.semester.toString().padStart(2, '0')+ "" + h.year});
        }
      }
    }

    return semesters;
  }*/

  calculateSemesters() {
    //Todo: Get starting semester:
    let currentSemester = 2;
    let currentYear = 2024;
    let current_months = 0;

    const semesters: Semester[] = [];

    while(current_months < this.project!.estimated_duration_months!){
      // Get current semester
      semesters.push({
        name: `${currentYear} - ${currentSemester.toString().padStart(2, '0')}`,
        year: currentYear,
        semester: currentSemester
      });
      //advance to next semester
      currentSemester++;
      if(currentSemester > 2){
        currentSemester = 1;
        currentYear++;
      }
      current_months += 6;
    }

    this.semesters = semesters;
  }

  getWeeklyHoursControls(index: number): number[] {
    const weeklyHoursArray = this.peopleEstimations.at(index).get('weekly_hours') as FormArray;
    return Array.from({ length: weeklyHoursArray.length }, (_, i) => i);
  }

  getPersonTotalHours(index: number): number {
    const personEstimation = this.peopleEstimations.at(index) as FormGroup;
    const weeklyHoursArray = personEstimation.get('weekly_hours') as FormArray;
    const weeks = personEstimation.get('weeks')!.value || 0;
    const totalHours = weeklyHoursArray.controls
      .map(control => control.value || 0)
      .reduce((acc, hours) => acc + hours, 0) * weeks;
    return totalHours;
  }

  getTotalHours(): number {
    let total = 0;
    for (let i = 0; i < this.peopleEstimations.length; i++) {
      total += this.getPersonTotalHours(i);
    }
    return total;
  }

  previewPDF(){
    window.open('/api/supportletter/pdf?id=' + this.idSupportLetter, '_blank');
  }

  

  async save() {
    let datos = this.formSupportLetter.value;
    
    if(this.supportLetter){

      //today's date in format YYYY-MM-DD
      const date = new Date();
      const today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

      const request: SupportLetterRequest = {
        faculty_id: this.supportLetter.faculty.id,
        project_id: this.supportLetter.project.id,
        user_id: this.supportLetter.user.id,
        isSigned: datos.isSigned,
        dateSigned: today,
        rut: datos.rut
      }

      this.openLoading();
      let resp = await this.supportLetterService.putSupportLetter(request, this.supportLetter.id);
      await this.projectService.checkProjectSend(this.supportLetter.project.id);
      this.supportLetter = resp;
      this.closeLoading();
      await this.globalSuccess(`La Carta de Apoyo se ha guardado correctamente`);
      
    }
  }

  cancel(): void {
    this.router.navigateByUrl('cartas-apoyo/lista');
  }

}
