import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/shared/base-page';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { PersonEstimation } from 'src/app/core/models/person_estimation.model';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project.service';
import { IndirectCost, IndirectCostRequest } from 'src/app/core/models/indirect_cost.model';
import { IndirectCostService } from 'src/app/core/services/indirect-cost.service';
import { Location } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WorkloadRequest, WorkloadResponse } from 'src/app/core/models/workload.model';
import { Minute } from 'src/app/core/models/minute.model';
import { WorkloadService } from 'src/app/core/services/workload.service';
import { MinuteService } from 'src/app/core/services/minute.service';
import * as moment from 'moment';

export interface Semester{
  semester: number;
  year: number;
  name: string;
}

@Component({
  selector: 'app-costos-indirectos-formulario',
  templateUrl: './costos-indirectos-formulario.component.html',
  styleUrls: ['./costos-indirectos-formulario.component.scss']
})
export class CostosIndirectosFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

    formIndirectCost: FormGroup = this.fb.group({
        isSigned: [false, [Validators.requiredTrue]],
        rut: ['', [Validators.required]]
    });

  indirectCost?: IndirectCost = undefined;
  idIndirectCost: number = 0;
  personEstimations: PersonEstimation[] = [];
  project?: Project = undefined;
  minutes!: Minute[];
  workload?: WorkloadRequest = undefined;

  hourly_rate = 7000;

  signed = false;
  isSigned: boolean = false;
  approvalCode: string = '';

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private indirectCostService: IndirectCostService,
    private projectService: ProjectService,
    private workloadService: WorkloadService,
    private minuteService: MinuteService,
    private location: Location,
    injector: Injector
  ) { 
    super(injector);
  }

  async ngOnInit() {

    super.ngOnInit();
    this.openLoading();
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Costos Indirectos' },
      { label: 'Editar Costos Indirectos', active: true }
    ];

    this.route.params.pipe(
      switchMap(params => {
          this.idIndirectCost = params['id'];

          if (this.idIndirectCost) {
              return this.indirectCostService.getIndirectCost(this.idIndirectCost);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: async(response) => {
            if (response ) {
                this.indirectCost = response;

                this.project = await this.projectService.getProject(this.indirectCost.project.id);
                this.personEstimations = await this.indirectCostService.getPersonEstimation(response.project.id, response.faculty!.id);
                this.minutes = await this.minuteService.getMinuteByProject(response.project.id);
                if(this.minutes.length > 0){
                  this.workload = await this.workloadService.getWorkloadFromMinute(this.minutes[0].id);
                }

                this.approvalCode = this.getApprovalCode(this.minutes[0]);

                this.formIndirectCost.patchValue({
                    isSigned: this.indirectCost.isSigned
                });

                this.isSigned = this.indirectCost.isSigned;

                if(this.workload){
                  this.hourly_rate = this.workload.hourly_rate;
                }
            }

            this.breadCrumbItems = [
              { label: 'Costos Indirectos' },
              { label: 'Editar Costos Indirectos', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Indirect Cost:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  getApprovalCode(minute: Minute): string{
    let approvalCode = '';
    if(!approvalCode && minute){
      console.log(moment(minute.date_council).format('DD/MM/YYYY'));
      approvalCode = (minute.vote_number || '') + ' - ' + (minute.date_council? moment(minute.date_council).format('DD/MM/YYYY'): '');
    }
    return approvalCode;
  }

    get semesters(): Semester[] {
        const semesters: Semester[] = [];
        for (let pe of this.personEstimations) {
            for (let h of pe.hours ? pe.hours : []) {
                let auxSemester = semesters.find(s => s.semester === h.semester && s.year === h.year);
                if (!auxSemester) {
                    auxSemester = {
                        semester: h.semester,
                        year: h.year,
                        name: h.semester.toString().padStart(2, '0') + "" + h.year
                    };
                    semesters.push(auxSemester);
                }
            }
        }
        return semesters;
    }

    getWeeklyHoursControls(index: number): number[] {
        let result = [];
        for (let s of this.semesters) {
            let value = 0;
            for (let h of this.personEstimations[index].hours || []) {
                if (s.semester === h.semester && s.year === h.year) {
                    value = h.hours;
                }
            }
            result.push(value);
        }
        return result;
    }

  getPersonTotalHours(index: number): number {
      let auxPerson = this.personEstimations[index];
      if (auxPerson) {
          const weeks = auxPerson.weeks || 0;
          const listHours = auxPerson.hours || [];
          const totalHours = listHours.map(control => control.hours || 0)
              .reduce((acc, hours) => acc + hours, 0) * weeks;
          return totalHours;
      }
      return 0;
  }

  getPersonTotalCost(index: number): number {
    const hours = this.getPersonTotalHours(index);
    return hours * this.hourly_rate;
  }

  getTotalHours(): number {
    let total = 0;
    for (let i = 0; i < this.personEstimations.length; i++) {
      total += this.getPersonTotalHours(i);
    }
    return total;
  }

  getTotalCost(): number {
    let total = 0;
    for (let i = 0; i < this.personEstimations.length; i++) {
      total += this.getPersonTotalCost(i);
    }
    return total;
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
  }

  previewPDF(){
    window.open('/api/indirectcost/pdf?id=' + this.idIndirectCost, '_blank');
  }

  async save() {
    let datos = this.formIndirectCost.value;

    //today's date in format YYYY-MM-DD
    const date = new Date();
    const today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    if(this.indirectCost){
      let request: IndirectCostRequest = {
        dateSigned: today,
        isSigned: datos.isSigned,
        faculty: this.indirectCost.faculty.id,
        project: this.indirectCost.project.id,
        user: this.indirectCost.user.id,
        rut: datos.rut
      };

      if (!this.isSigned && datos.isSigned) {
          this.openLoading();
          let data = await this.indirectCostService.putIndirectCost(this.idIndirectCost, request);
          this.closeLoading();
          await this.globalSuccess(data.value);
      }
    }

    
    this.cancel();
  }

  cancel(): void {
    this.location.back();
  }

}
