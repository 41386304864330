import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WorkloadRequest, WorkloadResponse } from "../models/workload.model";
import { Mensaje } from "../models/utils.model";

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {

  constructor(private http: HttpClient) { }

  getWorkload(id: number): Promise<WorkloadResponse> {
    return lastValueFrom(this.http.get<WorkloadResponse>(`${environment.apiURL}/workload/${id}`));
  }

  getWorkloadFromEval(id: number): Promise<WorkloadResponse> {
    return lastValueFrom(this.http.get<WorkloadResponse>(`${environment.apiURL}/workload?rubric_application=${id}`));
  }

  putWorkload(id: number, data: WorkloadRequest): Promise<Mensaje> {
    return lastValueFrom(this.http.put<Mensaje>(`${environment.apiURL}/workload/${id}`, data));
  }

  postWorkload(data: WorkloadRequest): Promise<Mensaje> {
    return lastValueFrom(this.http.post<Mensaje>(`${environment.apiURL}/workload`, data));
  }

  getWorkloadFromMinute(id: number): WorkloadRequest | PromiseLike<WorkloadRequest> {
    return lastValueFrom(this.http.get<WorkloadRequest>(`${environment.apiURL}/workload?minute=${id}`));
  }

}
