import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-person-activities',
  templateUrl: './person-activities.component.html',
  styleUrls: ['./person-activities.component.scss']
})
export class PersonActivitiesComponent implements OnInit {
  @Input() personDetails: any; // You can define a more specific type

  activitiesForm!: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.activitiesForm = this.fb.group({
      activities: this.fb.array([])
    });

    // Initialize activities here if any
  }

  get activities(): FormArray {
    return this.activitiesForm.get('activities') as FormArray;
  }

  // Add activity method
  addActivity() {
    const activitiesArray = this.activitiesForm.get('activities') as FormArray;
    activitiesArray.push(this.fb.group({
      activity: '',
      start: '',
      end: ''
    }));
  }

  // Other methods to manage activities...
}
