import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/shared/base-page';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { RoleService } from 'src/app/core/services/role.service';
import { Role, RoleRequest } from 'src/app/core/models/role.model';
import { SiteSection } from 'src/app/core/models/site_section.model';

@Component({
  selector: 'app-roles-formulario',
  templateUrl: './roles-formulario.component.html',
  styleUrls: ['./roles-formulario.component.scss']
})
export class RolesFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formRole: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    sections: new FormArray([])
  });

  role?: Role = undefined;
  idRole: number = 0;
  allSections: SiteSection[] = [];

  

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private roleService: RoleService,
    injector: Injector
  ) { 
    super(injector);

    

    this.roleService.getSiteSections().then((allSections)=>{
      this.allSections = allSections;
      const sectionsFormArray = allSections.map(() => new FormControl(false));

      this.formRole = this.fb.group({
        name: [undefined, [Validators.required]],
        sections: new FormArray(sectionsFormArray)
      });
    });
    
  }

  async ngOnInit() {

    super.ngOnInit();
    this.openLoading();
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Roles y Permisos' },
      { label: 'Crear Rol', active: true }
    ];

    this.route.params.pipe(
      switchMap(params => {
          this.idRole = params['id'];

          if (this.idRole) {
              return this.roleService.getRole(this.idRole);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: (response) => {
            if (response ) {
                this.role = response;

                this.formRole.patchValue({
                  name: this.role.name
                });

                const sectionsControls = (this.formRole.controls.sections as FormArray).controls;
                this.allSections.forEach((section, index) => {
                  // Initialize `associated` as false
                  let associated = false;
            
                  // Check if `response.sections` is defined and has the `find` method
                  if (response.siteSections && Array.isArray(response.siteSections)) {
                    // Use `find` to check if the current section is associated with the role
                    associated = !!response.siteSections.find(s => s.id === section.id);
                  }
                  
                  // Patch the value of the corresponding form control
                  sectionsControls[index].setValue(associated);
                });
            }

            this.breadCrumbItems = [
              { label: 'Roles y Permisos' },
              { label: 'Editar Rol', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Role:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  get sectionsFormArray(): FormArray {
    return this.formRole.get('sections') as FormArray;
  }

  async save() {
    const selectedSections = this.formRole.value.sections
      .map((checked: boolean, i: number) => checked ? this.allSections[i] : null)
      .filter((v: SiteSection | null) => v !== null);

    let datos = this.formRole.value;
    let request: RoleRequest = {
      name: datos.name,
      siteSections: selectedSections.map((s: SiteSection) => s.id)
    };

    this.openLoading();
    if (this.role) {
        let data = await this.roleService.putRole(request, this.role.id);
        this.closeLoading();
        await this.globalSuccess(`El Rol ${data.name}, ha sido guardado correctamente`);
        this.router.navigateByUrl('roles' );
    } else {
        let data = await this.roleService.postRole(request);
        this.closeLoading();
        await this.globalSuccess(`El Rol ${data.name}, ha sido creado correctamente`);
        this.router.navigateByUrl('roles');
    }
  }

  cancel(): void {
    this.router.navigateByUrl('roles');
  }

}
