<form [formGroup]="formStep1">
    <h5>1. IDENTIFICACION</h5>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="title">
                <label for="title">Título de la Investigación</label>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <select class="form-select" id="floatingSelectProce" name="faculty" formControlName="source" required>
                    <option value="">Eliga una Procedencia</option>
                </select>
                <label for="floatingSelectProce">Procedencia</label>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="academic" value="" formControlName="academic">
                <label for="academic">Investigador de contacto (alumno/a TFC/TFP, Investigador)</label>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <textarea class="form-control" id="students" name="students" rows="5" formControlName="students"></textarea>
                <label for="students">Investigadores (alumnos/as TFC/TFP, Investigadores)</label>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="guide_academic" formControlName="guide_academic">
                <label for="guide_academic">Profesor/a Guía</label>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-floating mb-3">
                <select class="form-select" id="floatingSelectFacu" formControlName="faculty" name="faculty" required>
                    <option value="">Eliga una Facultad / Unidad</option>
                    
                </select>
                <label for="floatingSelectFacu">Facultad / Unidad</label>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-floating mb-3">
                <select class="form-select" id="floatingSelect" formControlName="career" name="career" required>
                    <option value="">Eliga un Programa / Carrera</option>
                    
                </select>
                <label for="floatingSelect">Programa / Carrera</label>
            </div>
        </div>
        <div class="col-lg-6">
            <label for="startDate">Fecha prevista para el inicio de la investigación</label>
            <input class="form-control flatpickr-input" id="startDate" type="text" mwlFlatpickr dateFormat="d/m/Y">
        </div>
        <div class="col-lg-6">
            <label for="endDate">Fecha prevista para el fin de la investigación</label>
            <input class="form-control flatpickr-input" id="endDate" type="text" mwlFlatpickr dateFormat="d/m/Y">
        </div>

    </div>
            
    <div class="mt-5 d-flex flex-row-reverse">
        <button class="btn btn-primary" type="submit" (click)="nextStep()">Siguiente</button>
        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="previousStep()">Atrás</button>
        <span style="margin-right: 5px;"><small>Último Guardado 08/10/2023 10:31 am</small></span>
    </div>

</form>
