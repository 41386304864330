<!-- start page title -->
<app-pagetitle title="Tablero" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-book-check-outline text-primary"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="text-muted text-uppercase fw-semibold font-size-13">Proyectos Adjudicados</p>
                    <h4 class="mb-1 mt-1"><span [countUp]="5" class="counter-value" ></span></h4>
                </div>
                <p class="text-muted mt-3 mb-0"><span class="badge bg-success-subtle text-success me-1"><i
                            class="mdi mdi-arrow-up-bold me-1"></i>2.65%</span> convocatoria pasada
                </p>
            </div>
        </div>
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-book-cog-outline text-success"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="text-muted text-uppercase fw-semibold font-size-13">Proyectos en Ejecución</p>
                    <h4 class="mb-1 mt-1"><span [countUp]="10" class="counter-value"></span></h4>
                </div>
                <p class="text-muted mt-3 mb-0"><span class="badge bg-danger-subtle text-danger me-1"><i
                            class="mdi mdi-arrow-down-bold me-1"></i>4.58%</span> convocatoria pasada
                </p>
            </div>
        </div>
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-clipboard-check-multiple-outline text-primary"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="text-muted text-uppercase fw-semibold font-size-13">Proyectos en Evaluación</p>
                    <h4 class="mb-1 mt-1"><span class="counter-value" [countUp]="12" ></span></h4>
                </div>
                <p class="text-muted mt-3 mb-0"><span class="badge bg-success-subtle text-success me-1"><i
                            class="mdi mdi-arrow-up-bold me-1"></i>14.33%</span> convocatoria pasada
                </p>
            </div>
        </div>
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-account-group text-success"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="text-muted text-uppercase fw-semibold font-size-13">Investigadores</p>
                    <h4 class="mb-1 mt-1"><span class="counter-value" [countUp]="57"></span>
                    </h4>
                </div>
                <p class="text-muted mt-3 mb-0"><span class="badge bg-warning-subtle text-warning me-1"><i
                            class="mdi mdi-arrow-up-bold me-1"></i>0.55%</span> convocatoria pasada
                </p>
            </div>
        </div>
    </div> <!-- end col-->
</div> <!-- end row-->

<div class="row">
    <div class="col-xl-12">
        <div class="card card-height-100">
            <div class="card-body pb-0">
                <!--div class="float-end">
                    <div class="dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-reset" href="javascript:void(0);" ngbDropdownToggle
                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span class="fw-semibold">Sort By:</span> <span class="text-muted">Yearly<i
                                    class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1"
                            ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Yearly</a>
                            <a class="dropdown-item" href="javascript:void(0);">Monthly</a>
                            <a class="dropdown-item" href="javascript:void(0);">Weekly</a>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                        </div>
                    </div>
                </div-->
                <h4 class="card-title mb-4">Distribución de Proyectos</h4>

                <!--div class="mt-1">
                    <ul class="list-inline main-chart mb-0">
                        <li class="list-inline-item chart-border-left me-0 border-0">
                            <h3 class="text-primary">$<span data-plugin="counterup">2,371</span><span
                                    class="text-muted d-inline-block fw-normal font-size-15 ms-3">Income</span></h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0">
                            <h3><span data-plugin="counterup">258</span><span
                                    class="text-muted d-inline-block fw-normal font-size-15 ms-3">Sales</span>
                            </h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0">
                            <h3><span data-plugin="counterup">3.6</span>%<span
                                    class="text-muted d-inline-block fw-normal font-size-15 ms-3">Conversation
                                    Ratio</span></h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0">
                            <h3><span data-plugin="counterup">52</span>k<span
                                    class="text-muted d-inline-block fw-normal font-size-15 ms-3">Users</span></h3>
                        </li>
                    </ul>
                </div-->

                <div class="mt-3">
                    <apx-chart dir="ltr" class="apex-charts" [chart]="projectsBarChart.chart"
                        [series]="projectsBarChart.series" [legend]="projectsBarChart.legend"
                        [stroke]="projectsBarChart.stroke" [colors]="projectsBarChart.colors"
                        [fill]="projectsBarChart.fill" [dataLabels]="projectsBarChart.dataLabels"
                        [xaxis]="projectsBarChart.xaxis" [markers]="projectsBarChart.markers"
                        [plotOptions]="projectsBarChart.plotOptions" [yaxis]="projectsBarChart.yaxis"
                        [tooltip]="projectsBarChart.tooltip" [grid]="projectsBarChart.grid"
                        [labels]="projectsBarChart.labels">
                    </apx-chart>
                </div>
            </div> <!-- end card-body-->
        </div> <!-- end card-->
    </div> <!-- end col-->

</div>
<!-- end row -->

<div class="row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-body">
                <div ngbAccordion #accordion="ngbAccordion" activeIds="config-panel-one" [closeOthers]="true">
                    <div class="card rounded mb-3"></div>
                    <div ngbAccordionItem="config-panel-one" id="config-panel-one">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>REPORTE 1</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <button class="btn btn-primary"><i class="mdi mdi-file-excel-outline"></i> Descargar Excel</button>
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="table-responsive">
                                                <table class="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Proyecto</th>
                                                            <th>Investigador</th>
                                                            <th>Estado</th>
                                                            <th>Presupuesto</th>
                                                            <th>Colaboradores</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let i of [].constructor(20); let index = index">
                                                            <th scope="row">{{index + 1}}</th>
                                                            <td>Project {{index + 1}}</td>
                                                            <td>Dr. John Doe</td>
                                                            <td>En Ejecución</td>
                                                            <td>$500,000</td>
                                                            <td>Dr. Jane Smith, Dr. Emily Johnson</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"></apx-chart>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-two">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>REPORTE 2</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <button class="btn btn-primary"><i class="mdi mdi-file-excel-outline"></i> Descargar Excel</button>
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="table-responsive">
                                                <table class="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Proyecto</th>
                                                            <th>Investigador</th>
                                                            <th>Estado</th>
                                                            <th>Presupuesto</th>
                                                            <th>Colaboradores</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let i of [].constructor(20); let index = index">
                                                            <th scope="row">{{index + 1}}</th>
                                                            <td>Project {{index + 1}}</td>
                                                            <td>Dr. John Doe</td>
                                                            <td>En Ejecución</td>
                                                            <td>$500,000</td>
                                                            <td>Dr. Jane Smith, Dr. Emily Johnson</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart" [plotOptions]="chartOptions2.plotOptions" [dataLabels]="chartOptions2.dataLabels" [xaxis]="chartOptions2.xaxis"></apx-chart>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- end row -->