import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { Faculty, FacultyRequest } from 'src/app/core/models/faculty.model';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { of, switchMap, tap } from 'rxjs';


@Component({
    selector: 'app-gasto-formulario',
    templateUrl: './gasto-formulario.component.html',
    styleUrls: ['./gasto-formulario.component.scss']
})
export class GastoFormularioComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    formRendicion!: FormGroup;

    faculty?: Faculty = undefined;
    facultyId: number = 0;

    meses = [
        { numero: 1, nombre: 'ENERO' },
        { numero: 2, nombre: 'FEBRERO' },
        { numero: 3, nombre: 'MARZO' },
        { numero: 4, nombre: 'ABRIL' },
        { numero: 5, nombre: 'MAYO' },
        { numero: 6, nombre: 'JUNIO' },
        { numero: 7, nombre: 'JULIO' },
        { numero: 8, nombre: 'AGOSTO' },
        { numero: 9, nombre: 'SEPTIEMBRE' },
        { numero: 10, nombre: 'OCTUBRE' },
        { numero: 11, nombre: 'NOVIEMBRE' },
        { numero: 12, nombre: 'DICIEMBRE' }
    ]

    tiposDoc = [
        { tipo: 'BOL', texto: 'BOLETA'},
        { tipo: 'FACT', texto: 'FACTURA'}
    ]

    secciones = [
        {
          name: '1. GASTOS DE OPERACIÓN',
          categories: [
            '1.1 Maquinas y equipos', 
            '1.2 Software y licencias', 
            '1.3 Insumos de Laboratorio']
        },
        {
          name: '2. MATERIALES DE OFICINA',
          categories: [
            '2.1 Materiales de Oficina )paple, lápices, corchetera, fotocopias, etc', 
            '2.2 Otros'
          ]
        },
        {
          name: '3. HONORARIOS ASISTENTES',
          categories: [
            '3.1 Alumnos Ayudantes (max. $200.000 por alumno y año', 
            '3.2 Tesistas de pregrado (máximo $200.000 por tesista y semestre)',
            '3.3 Tesistas de posgrado (máximo $400.000 por tesista y semestre)'
          ]
        },
        {
          name: '4. VIAJES PARA REALIZAR EL PROYECTO',
          categories: [
            '4.1 Movilización (máximo $100.000 en Chile y 200.000 en el extranjero)', 
            '4.1.1 Movilización para recoger datos o consultas bibliográficas',
            '4.1.2 Movilización para asesoría científica',
          ]
        },
        {
          name: '5. PRESENTACIÓN DE LA INVESTIGACIÓN EN EVENTOS CIENTÍFICOS O ACADÉMICOS',
          categories: [
            '5.1 Gastos de impresión de pósteres, inscripción a congresos, movilización, viáticos, alojamiento investigación (máximo $100.000/evento, y con un máximo de un evento científico anual)', 
          ]
        },
        {
          name: '6. PUBLICACIÓN DE LA INVESTIGACIÓN',
          categories: [
            '6.1. Traducir el artículo para publicar en revista especializada', 
            '6.2 Gastos de edición',
            '6.3 Gastos de publicación',
            '6.4 Otros'
          ]
        },
        {
          name: '7. OTROS',
          categories: [
            '7.1 Otros', 
          ]
        }
      ];

    categorias = [
        { valor: '1.1', texto: 'Maquinas y equipos' },
        { valor: '1.2', texto: 'Software y licencias' },
        { valor: '1.3', texto: 'Insumos de Laboratorio' },
        { valor: '2.1', texto: 'Materiales de Oficina' },
        { valor: '2.2', texto: 'Otros' },
        { valor: '4.1', texto: 'Movilización' },
        { valor: '4.1.1', texto: 'Movilización para recoger datos o consultas bibliográficas' },
        { valor: '4.1.2', texto: 'Movilización para asesoría científica' },
        { valor: '5.1', texto: 'Gastos de impresión de pósteres, inscripción a congresos, movilización, viáticos, alojamiento investigación' },
        { valor: '6.1', texto: 'Traducir el artículo para publicar en revista especializada' },
        { valor: '6.2', texto: 'Gastos de edición' },
    ]

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private facultadService: FacultadesService,
        injector: Injector
    ) {
        super(injector);
        this.formRendicion = this.fb.group({
            requestDeposit: [true, Validators.required],
            Name: "Andrés Véliz Bernt",
            Date: [new Date(), [Validators.required]],
            Rut: [undefined],
            Bank: [undefined],
            Account: [undefined],
            Month: [new Date().getMonth() + 1, [Validators.required]],
            Year: [new Date().getFullYear(), [Validators.required]],
            DocDate: [undefined, [Validators.required]],
            DocType: [undefined, [Validators.required]],
            DocNumber: [undefined, [Validators.required]],
            Amount: [undefined, [Validators.required]],
            Description: [undefined, [Validators.required]],
            NroCuentaContable: [undefined, [Validators.required]],
            NroSubCuentaContable: [undefined, [Validators.required]],
            NroCentroCosto: [undefined, [Validators.required]],
            Category: [undefined, [Validators.required]]
        });
    }

    override async ngOnInit() {

        super.ngOnInit();
        this.openLoading();

        //BreadCrumb 
        this.breadCrumbItems = [
            { label: 'Rendición Gasto' },
            { label: 'Crear Rendición', active: true }
        ];    
        
        this.route.params.pipe(
            switchMap(params => {
                this.facultyId = params['id'];
    
                if (this.facultyId) {
                    return this.facultadService.getFacultad(this.facultyId);
                }
    
                // If there's no facultyId, just return an observable of null
                return of(null);
            })
        ).subscribe({
            next: (response) => {
                if (response && response) {
                    this.faculty = response;
                    if(this.faculty) {
                        this.formRendicion.patchValue({
                            nombre: this.faculty.name,
                            activo: this.faculty.is_active,
                            acronimo: this.faculty.acronym
                        });
                    }
                }
                this.breadCrumbItems = [
                    { label: 'Rendición Gasto' },
                    { label: 'Editar Rendición', active: true }
                  ];
                this.closeLoading();
            },
            error: (error) => {
                console.error('Error fetching facultad:', error);
                // Handle the error accordingly
                this.closeLoading();
            }
        });
        
    }

    async save() {
        let datos = this.formRendicion.value;
        let request: FacultyRequest = {
            name: datos.nombre,
            is_active: datos.activo,
            acronym: datos.acronimo

        };
        this.openLoading();
        if (this.faculty) {
            let data = await this.facultadService.putFacultad(request, this.faculty.id);
            this.closeLoading();
            await this.globalSuccess(`La facultad ${data.name} ha sido actualizada correctamente`);
            this.router.navigateByUrl('facultades' );
        } else {
            let data = await this.facultadService.postFacultad(request);
            this.closeLoading();
            await this.globalSuccess(`La facultad ${data.name} ha sido creada correctamente`);
            this.router.navigateByUrl('facultades');
        }
    }

    cancel(): void {
        this.router.navigateByUrl('facultades');
    }

}
