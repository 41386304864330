<form lang="es-CL">
    <h5>6. PRESUPUESTO DEL PROYECTO</h5>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table mb-0"  *ngFor="let seccion of secciones; index as i">
                    <thead class="table-light">
                        <tr>
                            <th style="width: 27%;">Concepto de costo</th>
                            <th style="width: 23%;">Objetivo</th>
                            <th style="width: 15%;">Cantidad</th>
                            <th style="width: 15%;">Valor Unidad ($)</th>
                            <th style="width: 15%; text-align: right;">Valor Total ($)</th>
                            <th style="width: 5%;">Adjunto</th>
                            <th style="width: 5%;">Elim</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colspan="5">{{seccion.name}}</th>
                        </tr>
                        <ng-container *ngFor="let cat of seccion.categories">
                            <tr>
                                <td colspan="3">&nbsp;&nbsp;{{cat.name}}</td>
                                <td colspan="2"><button class="btn btn-primary add-btn" (click)="addExpense(cat.id)"><i class="bx bx-plus"></i> Agregar Gasto</button></td>
                            </tr>
                            <tr *ngFor="let expense of getExpensesFromCat(cat.id); ; let j = index">
                                <td><input type="text" [(ngModel)]="expense.name" name="name-{{cat.id}}-{{j}}" class="form-control"></td> 
                                <td>
                                    <select class="form-select" [(ngModel)]="expense.objective" name="objective-{{cat.id}}-{{j}}">
                                        <option value="">Seleccione un Objetivo</option>
                                        <option *ngFor="let objective of projectObjectives" [value]="objective.id">
                                            {{objective.name}}
                                        </option>
                                    </select>
                                </td>
                                <td><input type="number" lang="es-CL" class="form-control" [(ngModel)]="expense.amount" name="amount-{{cat.id}}-{{j}}"></td>
                                <td><input type="number" lang="es-CL" class="form-control" [(ngModel)]="expense.unit_cost" name="unit_cost-{{cat.id}}-{{j}}"></td>
                                <td style="text-align: right;">{{ formatCurrency(getExpenseTotal(expense))}}</td>
                                <td>
                                    <div *ngIf="expense.attachmentBase64">
                                        <a [href]="'data:application/octet-stream;base64,' + expense.attachmentBase64" [download]="expense.attachmentName" class="file-name">{{ expense.attachmentName }}</a><br>
                                        <button class="btn btn-sm btn-danger" (click)="removeFile(expense)">Eliminar</button>
                                    </div>
                                    
                                    <div *ngIf="!expense.attachmentBase64">
                                        <label class="custom-file-upload">
                                            <span class="btn btn-primary">{{ expense.attachmentName ? expense.attachmentName : 'Seleccionar archivo...' }}</span>
                                            <input type="file" (change)="onFileSelected($event, expense)">
                                        </label>
                                    </div>
                                </td>
                                
                                <td style="vertical-align: middle;"><button class="btn btn-icon btn-lg btn-danger" type="button" (click)="removeExpense(expense)"><i class="bx bx-trash"></i></button></td>
                            </tr>

                        </ng-container>
                        <tr>
                            <td style="text-align: right; font-weight: bold;" colspan="4">SUBTOTAL {{seccion.name}}</td>
                            <td style="text-align: right;">{{ formatCurrency(getSectionTotal(seccion)) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <h5 class="mt-5">Resumen Subtotales</h5>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table mb-0">
                    <thead class="table-light">
                        <tr>
                            <th>SUBTOTAL</th>
                            <th>Valor Total ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let seccion of secciones; index as i">
                            <th>{{seccion.name}}</th>
                            <td style="text-align: right;">{{ formatCurrency(getSectionTotal(seccion)) }}</td>
                        </tr>
                    </tbody>
                    <tfoot class="table-light">
                        <tr>
                            <td style="text-align: right; font-weight: bold;">TOTAL PRESUPUESTO GASTOS</td>
                            <td style="text-align: right;">{{ formatCurrency(getGrandTotal()) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
                    
    <div class="d-flex flex-row-reverse mt-5">
        <button class="btn btn-primary" type="submit" (click)="nextStep()">Siguiente</button>
        <button class="btn btn-primary" style="margin-right: 5px;" type="button" (click)="save()">Guardar</button>
        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="previousStep()">Atrás</button>
        <span style="margin-right: 5px; padding-top: 6px;"><small>Último Guardado {{parentComponent.lastModified | date:'dd/MM/YYYY HH:mm:ss'}}</small></span>
    </div>

</form>
