<!-- start page title -->
<app-pagetitle title="Formulario Proyecto" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="callperiod" readonly value="202401">
                            <label for="callperiod">Convocatoria</label>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="folio" name="folio" readonly [value]="project?.folio">
                            <label for="folio">Folio</label>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="title" name="title" placeholder="Ej. Proyecto de Investigación" [value]="project?.title">
                            <label for="title">Título Proyecto</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingSelectFacu" name="faculty" readonly [value]="project?.faculty?.name">
                            <label for="floatingSelectFacu">Facultad / Unidad</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingSelect" name="career" readonly [value]="project?.career?.name">
                            <label for="floatingSelect">Programa / Carrera</label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <input type="text" class="form-control" id="projectType" name="type" readonly [value]="project?.type">
                            <label for="projectType">Tipo Proyecto</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h4>Resumen Gastos Proyecto</h4>

                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>Concepto</th>
                                <th>DÉBITO ($)</th>
                                <th>CRÉDITO ($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">TOTAL PRESUPUESTADO</th>
                                <td class="text-right">-</td>
                                <td class="table-success text-right">$1.000.000</td>
                            </tr>
                            <tr>
                                <th scope="row">TOTAL SIN RENDIR</th>
                                <td class="table-danger text-right">$200.000</td>
                                <td class="text-right">-</td>
                            </tr>
                            <tr>
                                <th scope="row">TOTAL GASTOS</th>
                                <td class="table-danger text-right">$300.000</td>
                                <td class="text-right">-</td>
                            </tr>
                            <tr>
                                <th scope="row">TOTAL COMPRAS</th>
                                <td class="table-danger text-right">$100.000</td>
                                <td class="text-right">-</td>
                            </tr>
                            <tr>
                                <th scope="row">TOTAL BOLETAS HONORARIO</th>
                                <td class="table-danger text-right">$50.000</td>
                                <td class="text-right">-</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th scope="row">TOTALES</th>
                                <th class="table-danger text-right">$650.000</th>
                                <th class="table-success text-right">$1.000.000</th>
                            </tr>
                            <tr>
                                <th scope="row">DIFERENCIA</th>
                                <th class="text-right">-</th>
                                <th class="table-success text-right">$350.000</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <a (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" href="javascript:void(0);">
                    <h4>Resumen Gastos por Categorías</h4>
                </a>
                <div class="collapse" id="collapseExample" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="card card-body">
                        <div class="table-responsive">
                            <table class="table mb-0"  *ngFor="let seccion of secciones; index as i">
                                <thead class="table-light">
                                    <tr>
                                        <th style="width: 40%;">Concepto de costo</th>
                                        <th style="width: 20%; text-align: right;">Monto Presupuestado ($)</th>
                                        <th style="width: 20%; text-align: right;">Monto Rendido ($)</th>
                                        <th style="width: 20%; text-align: right;">Diferencia ($)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th colspan="4">{{seccion.name}}</th>
                                    </tr>
                                    <ng-container *ngFor="let cat of seccion.categories">
                                        <tr>
                                            <td>&nbsp;&nbsp;{{cat}}</td>
                                            <td style="text-align: right;">{{ formatCurrency(getBudget(cat))}}</td>
                                            <td style="text-align: right;">{{ formatCurrency(getExpenses(cat))}}</td>
                                            <td style="text-align: right;">{{ formatCurrency(getDiff(cat))}}</td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td style="text-align: right; font-weight: bold;" colspan="3">SUBTOTAL {{seccion.name}}</td>
                                        <td style="text-align: right;">{{ formatCurrency(getSectionTotalDiff(seccion)) }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td style="text-align: right; font-weight: bold;">TOTALES</td>
                                        <td style="text-align: right;">{{ formatCurrency(getTotalBudget()) }}</td>
                                        <td style="text-align: right;">{{ formatCurrency(getTotalExpenses()) }}</td>
                                        <td style="text-align: right;">{{ formatCurrency(getTotalDiff()) }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h4>Procesos</h4>
                <div ngbAccordion #accordion="ngbAccordion" activeIds="config-panel-one" [closeOthers]="true">
                    <div class="card rounded mb-3"></div>
                    <div ngbAccordionItem="config-panel-one" id="config-panel-one">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Solicitudes Órdenes de Egreso <span class="badge bg-info">3</span></h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary" (click)="goToNewOE()">Nueva Solicitud Egreso</button>
                                </div>
                                <ng-template>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Monto</th>
                                                    <th>Estado</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-success">
                                                    <th scope="row">07/02/2024</th>
                                                    <td>$80.000</td>
                                                    <td><span class="badge bg-success">ACEPTADA</span></td>

                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToNewGasto()" ngbTooltip="Crear Rendición Gasto"><i class="bx bx-money"></i></button></td>
                                                </tr>
                                                <tr class="table-warning">
                                                    <th scope="row">13/02/2024</th>
                                                    <td>$50.000</td>
                                                    <td><span class="badge bg-warning">ENVIADA</span></td>
                                                    <td></td>
                                                </tr>
                                                <tr class="table-danger">
                                                    <th scope="row">18/02/2024</th>
                                                    <td>$100.000</td>
                                                    <td><span class="badge bg-danger">RECHAZADA</span></td>
                                                    <td></td>
                                                </tr>
                    
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-two">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Rendiciones de Gastos <span class="badge bg-info">3</span></h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary" (click)="goToNewGasto()">Nueva Rendición</button>
                                </div>
                                <ng-template>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Tipo Doc</th>
                                                    <th>Num Doc</th>
                                                    <th>Proveedor</th>
                                                    <th>Descripción</th>
                                                    <th class="text-right">Monto</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">03/02/2024</th>
                                                    <td>BOL</td>
                                                    <td>2345</td>
                                                    <td>SODIMAC</td>
                                                    <td>Herramientas Varias</td>
                                                    <td class="text-right">$25.500</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">08/02/2024</th>
                                                    <td>BOL</td>
                                                    <td>435532</td>
                                                    <td>PC Factory</td>
                                                    <td>Disco Duro Investigación</td>
                                                    <td class="text-right">$24.500</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">20/02/2024</th>
                                                    <td>FACT</td>
                                                    <td>6433563</td>
                                                    <td>PC Factory</td>
                                                    <td>Monitores PC</td>
                                                    <td class="text-right">$225.000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-three">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Solicitudes Órdenes de Compra <span class="badge bg-info">2</span></h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary" (click)="goToNewOC()">Nueva Solicitud OC</button>
                                </div>
                                <ng-template ngbPanelContent>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Proveedor</th>
                                                    <th>Detalle</th>
                                                    <th>Monto</th>
                                                    <th>Estado</th>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-warning">
                                                    <th scope="row">21/02/2024</th>
                                                    <td>Proveedor 1</td>
                                                    <td>Compra Investigación</td>
                                                    <td class="text-right">$250.000</td>
                                                    <td><span class="badge bg-warning">ENVIADA</span></td>
                                                    <td></td>
                                                </tr>
                                                <tr class="table-success">
                                                    <th scope="row">21/02/2024</th>
                                                    <td>Proveedor 1</td>
                                                    <td>Compra Investigación</td>
                                                    <td class="text-right">$220.000</td>
                                                    <td><span class="badge bg-success">ACEPTADA</span></td>
                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToNewGasto()" ngbTooltip="Crear Rendición Gasto"><i class="bx bx-money"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-four">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Solicitudes Compra DTI <span class="badge bg-info">1</span></h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary" (click)="goToNewDTI()">Nueva Solicitud Compra DTI</button>
                                </div>
                                <ng-template ngbPanelContent>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Proveedor</th>
                                                    <th>Detalle</th>
                                                    <th>Monto</th>
                                                    <th>Estado</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-success">
                                                    <th scope="row">16/02/2024</th>
                                                    <td>PC Factory</td>
                                                    <td>Notebook Investigación</td>
                                                    <td class="text-right">$220.000</td>
                                                    <td><span class="badge bg-success">ACEPTADA</span></td>
                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToActaAsignacion()" ngbTooltip="Crear Acta Asignación"><i class="bx bx-task"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-five">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Autorización Boleta Honorarios <span class="badge bg-info">2</span></h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary" (click)="goToNewBH()">Nueva Solicitud Autorización</button>
                                </div>
                                <ng-template ngbPanelContent>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Persona</th>
                                                    <th>Motivo</th>
                                                    <th>Monto</th>
                                                    <th>Estado</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-success">
                                                    <th scope="row">INVESTIGADOR 1</th>
                                                    <td>Asesoría Investigación</td>
                                                    <td class="text-right">$50.000</td>
                                                    <td><span class="badge bg-success">ACEPTADA</span></td>
                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToInformeActividades()" ngbTooltip="Adjuntar Boleta e Informe"><i class="bx bx-cloud-upload"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
