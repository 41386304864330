import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { of, switchMap } from 'rxjs';
import { Minute } from 'src/app/core/models/minute.model';
import { MinuteService } from'src/app/core/services/minute.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { Project } from 'src/app/core/models/project.model';
import { RubricAplicationService } from 'src/app/core/services/rubric-aplication.service';
import { RubricApplication } from 'src/app/core/models/rubrica.model';
import { WorkloadService } from 'src/app/core/services/workload.service';
import { WorkloadRequest } from 'src/app/core/models/workload.model';
import { Location } from '@angular/common';


@Component({
    selector: 'app-acta-dirinves',
    templateUrl: './acta-dirinves.component.html',
    styleUrls: ['./acta-dirinves.component.scss']
})
export class CouncilMinuteComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;

    formActa!: FormGroup;
    idMinute: number = 0;
    minute!: Minute;
    project?: Project;
    directiveEvals: RubricApplication[] = [];
    workload?: WorkloadRequest = undefined;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private minuteService: MinuteService,
        private projectService: ProjectService,
        private rubricApplicationService: RubricAplicationService,
        private workloadService: WorkloadService,
        private location: Location,
        injector: Injector
    ) {
        super(injector);
        this.formActa = this.fb.group({
            title: [undefined, [Validators.required]],
            date_council: [undefined, [Validators.required]],
            vote_number: [undefined, [Validators.required]],
            status: [undefined, [Validators.required]],
            body_text: [undefined, [Validators.required]]
        });
    }

    override async ngOnInit() {

        super.ngOnInit();
        this.openLoading();

        this.route.params.pipe(
            switchMap(params => {
                this.idMinute = params['id'];
    
                if (this.idMinute) {
                    return this.minuteService.getMinute(this.idMinute);
                }
    
                // If there's no idMinute, just return an observable of null
                return of(null);
            })
        ).subscribe({
            next: async (response) => {
                if (response && response) {
                    this.minute = response;
                    this.project = await this.projectService.getProject(this.minute.project!.id);
                    this.directiveEvals = await this.rubricApplicationService.getDirectiveRubricAplications(this.project!.id);
                    if(this.directiveEvals.length > 0){
                        let wl = await this.workloadService.getWorkloadFromEval(this.directiveEvals[0].id);
                        this.workload = {
                            requested_duration: wl.requested_duration,
                            recomended_duration: wl.recomended_duration,
                            requested_budget: wl.requested_budget,
                            recomended_budget: wl.recomended_budget,
                            hourly_rate: wl.hourly_rate,
                            project: wl.project,
                            rubric_application: wl.rubric_application,
                            investigadores: []
                        }
                        for(let wlInv of wl.investigadores){
                            this.workload.investigadores.push({
                                name: wlInv.name,
                                rut: wlInv.rut,
                                requested_hours: wlInv.requested_hours,
                                recomended_hours: wlInv.recomended_hours,
                                other_hours: wlInv.other_hours
                            });
                        }
                    }
    
                    this.formActa.patchValue({
                        title: this.minute.title,
                        date_council: this.minute.date_council,
                        vote_number: this.minute.vote_number,
                        status: this.minute.status,
                        body_text: this.minute.body_text
                    });
                }
    
                this.closeLoading();
            },
            error: (error) => {
                console.error('Error fetching minute:', error);
                // Handle the error accordingly
                this.closeLoading();
            }
        });
        
    }

    getProjectTypeLabel(project: Project): string {
        let ret = '';
        if(project.type === 'PI'){
            ret = 'Proyecto de Investigación ';
            if(project.is_initial){
                ret += 'Inicial (hasta 12 meses)';
            } else if(project.is_regular){
                ret += 'Regular (hasta 24 meses)';
            } else {
                ret += 'Transf. Tec. (hasta 24 meses)';
            }
        } else if(project.type === 'PRAC'){
            ret = 'Proyecto de Creación Artística y Cultural ';
            if(project.is_related_music){
                ret += 'Relacionado Música';
            } else if(project.is_related_literature){
                ret += 'Relacionado Literatura';
            } else {
                ret += 'Otro: '+ project.other_related;
            }
        }

        return ret;
    }

    getTotalSolicitado() {
        let total = 0;
        this.workload?.investigadores.forEach(investigador => {
            total += investigador.requested_hours;
        });
        return total;
    }

    getTotalRecomendado() {
        let total = 0;
        this.workload?.investigadores.forEach(investigador => {
            total += investigador.recomended_hours;
        });
        return total;
    }

    getTotalHoraSolicitado() {
        return this.getTotalSolicitado() * (this.workload?.requested_duration || 0);
    }

    getTotalHoraRecomendado() {
        return this.getTotalRecomendado() * (this.workload?.recomended_duration || 0);
    }

    async save() {

        // Marcar todos los controles como tocados para activar las validaciones
        this.formActa.markAllAsTouched();

        // Verificar si el formulario es válido
        if (this.formActa.valid) {
            this.openLoading();

            const request = this.formActa.getRawValue();
            request.workload = this.workload;
            request.project = this.project?.id;

            await this.minuteService.putMinute(this.idMinute, request);

            this.closeLoading();
            this.location.back();
            await this.globalSuccess('Acta de Consejo almacenada exitosamente');
        }
    }

    cancel(): void {
        this.router.navigateByUrl('mis-pendientes');
    }


}
