import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProyectoWizardComponent } from '../proyecto-wizard/proyecto-wizard.component';
import { ProjectWizardService } from 'src/app/core/services/project-wizard.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { InvestigativeLine } from 'src/app/core/models/utils.model';
import { ProjectRequest } from 'src/app/core/models/project.model';

@Component({
  selector: 'app-proyecto-step1',
  templateUrl: './proyecto-step1.component.html',
  styleUrls: ['./proyecto-step1.component.scss']
})
export class ProyectoStep1Component {

  formStep1: FormGroup;
  @Input() parentComponent!: ProyectoWizardComponent;

  investigativeLines: InvestigativeLine[] = [];

  genders: string[] = ['Femenino', 'Masculino', 'No Declara'];
  project?: Partial<ProjectRequest> = undefined;

  constructor(
    private fb: FormBuilder,
    private projectWizardService: ProjectWizardService,
    private utilsService: UtilsService
  ) { 
    this.formStep1 = this.fb.group({
      name_academic: [undefined],
      email_academic: [undefined],
      rut_academic: [undefined],
      gender_academic: [undefined],
      phone_academic: [undefined],
      cell_academic: [undefined],
      faculty: [undefined],
      career: [undefined],
      title: [undefined],
      folio: [undefined],
      is_TFC: [false],
      projectType: [undefined],
      is_related_music: [false],
      is_related_literature: [false],
      other_related: [false],
      is_initial: [false],
      is_regular: [false],
      is_technology_transfer: [false],
      with_VCM: [false],
      investigative_lines: [undefined],
      pcrac_relationship_other: [undefined],
      keywords: [undefined],
    });
  }

  async ngOnInit() {
    //this.parentComponent.openLoading();
    // Load existing data if available
    const self = this;
    this.projectWizardService.getProjectData().subscribe(async data => {
      self.project = data;
      self.investigativeLines = await self.utilsService.getInvestigativeLines(data.type || '');
      //set the checked attribute of the investigative lines all to false
      self.investigativeLines.forEach(line => { line.checked = false });
      self.parentComponent.closeLoading();

      
      self.formStep1.patchValue(data);
      if (data.investigative_lines) {
        const lines: (string | number)[] = data.investigative_lines.split(',').map(line => parseInt(line));
        self.investigativeLines.forEach(line => { line.checked = lines.includes(line.id) });
      }

      if (self.formStep1.get('is_initial')?.value) {
        self.formStep1.patchValue({ projectType: 'is_initial' });
      } else if (self.formStep1.get('is_regular')?.value) {
        self.formStep1.patchValue({ projectType: 'is_regular' });
      } else if (self.formStep1.get('is_technology_transfer')?.value) {
        self.formStep1.patchValue({ projectType: 'is_technology_transfer' });
      }

    });
    //Subscribe to last modified date
    this.projectWizardService.getLastModified().subscribe(date => {
      this.parentComponent.setLastModified(date);
    });

    this.onProjectTypeChange();
  }

  onProjectTypeChange(): void {
    this.formStep1.get('projectType')?.valueChanges.subscribe(value => {
      this.formStep1.patchValue({
        is_initial: value === 'is_initial',
        is_regular: value === 'is_regular',
        is_technology_transfer: value === 'is_technology_transfer'
      });
    });
  }

  updateInvestigativeLines(): void {
    const selectedLines = this.investigativeLines
      .filter(line => line.checked)
      .map(line => line.id)
      .join(',');
    this.formStep1.patchValue({ investigative_lines: selectedLines });
  }

  nextStep(): void {
    this.updateInvestigativeLines();
    this.parentComponent.nextStep(this.formStep1.value);
  }

  previousStep(): void {
    this.updateInvestigativeLines();
    this.parentComponent.previousStep(this.formStep1.value);
  }

  save(): void{
    this.updateInvestigativeLines();
    this.parentComponent.updateData(this.formStep1.value);
  }

}
