import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { RubricService } from 'src/app/core/services/rubric.service';
import { Rubric, RubricApplication, RubricQuestion } from 'src/app/core/models/rubrica.model';
import {CommentEvaluation} from "../../core/models/comment-evaluation.model";
import { ProjectService } from 'src/app/core/services/project.service';
import { RubricAplicationService } from 'src/app/core/services/rubric-aplication.service';
import { of, switchMap } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { Location } from '@angular/common';


export interface Semester{
    name: string;
    year: number;
    semester: number;
}

export interface QuestionAnswer{
  qID: number;
  selectedOption: string;
  value: number;
}

@Component({
    selector: 'app-evaluacion-pares-view',
    templateUrl: './evaluacion-pares-view.component.html',
    styleUrls: ['./evaluacion-pares-view.component.scss']
})
export class PeerReviewViewComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;

    rubric?: Rubric;
    rubricApplication?: RubricApplication;
    answers: QuestionAnswer[] = [];

    idRubricAplication!: number;
    project?: Project;
    commentEvaluation!: CommentEvaluation;

    constructor(
        private route: ActivatedRoute,
        private rubricService: RubricService,
        private rubricApplicationService: RubricAplicationService,
        private projectService: ProjectService,
        private location: Location,
        injector: Injector
    ) {
        super(injector);
    }

    override async ngOnInit() {
        super.ngOnInit();

        //this.commentEvaluation = await this.commentEvaluationService.getCommentEvaluation(1);
        this.commentEvaluation = {
            investigador: '',
            departamento: ''
        }

        this.openLoading();
        
        this.route.params.pipe(
            switchMap(params => {
                this.idRubricAplication = params['id'];
      
                if (this.idRubricAplication) {
                  return this.rubricApplicationService.getRubricAplication(this.idRubricAplication);
                }
      
                // If there's no idUser, just return an observable of null
                return of(null);
            })
          ).subscribe({
              next: async(response) => {
                  if (response) {
                    this.rubricApplication = response;
                    this.project = await this.projectService.getProject(this.rubricApplication.project!.id);
                    this.rubric = await this.rubricService.getRubric(12);
                    

                    //get selected option for each question
                    for(let c of this.rubric?.categories){
                      for(let q of c.questions){
                        this.answers.push({
                          qID: q.id,
                          selectedOption: this.getSelectedOption(q, this.rubricApplication.Answers),
                          value: this.getAnswerValue(q.id, this.rubricApplication.Answers)
                        });
                        q.answer = this.getAnswerValue(q.id, this.rubricApplication.Answers);
                      }
                    }

                    this.rubric.internalComment = this.rubricApplication.internalComment;
                    this.rubric.investigatorComment = this.rubricApplication.investigatorComment;

                    this.closeLoading();
                  } else {
                    throw new Error('Error fetching Rubric Application');
                  }

              },
              error: (error) => {
                  console.error('Error fetching Project:', error);
                  // Handle the error accordingly
                  this.closeLoading();
              }
          });
    } 
  getAnswerValue(id: number, Answers: any): number {
    const qAnswer = Answers.find((a: any) => a.rubricQuestion?.id === id);
    return qAnswer? qAnswer.value : 0;
  }
  getSelectedOption(q: RubricQuestion, Answers: any): string {
    const value = this.getAnswerValue(q.id, Answers);
    if (q.options) {
      const option = q.options.find((o: any) => o.minValue <= value && o.maxValue >= value);
      return option? option.option : '';
    } else { return ''; }
  }

    cancel(): void {
      if (window.opener) {
        // Si la ventana fue abierta por otra ventana
        window.close();
      } else {
        //go back to the previous page
        this.location.back();
      }
    }

    getEvalText(evalTotal:number): string {
      const json = JSON.parse(this.rubric?.evalJSON || '{}');
      if(json.hasOwnProperty('evals')){
          for(let e of json.evals){
              if(evalTotal >= e.min && evalTotal <= e.max){
                  return e.name;
              }
          }
      }
      return '';
  }

}
