import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { CECRequest, CECResponse } from 'src/app/core/models/cec.model';
import { User } from 'src/app/core/models/user.model';
import { CECService } from 'src/app/core/services/cec.service';
import { UserService } from 'src/app/core/services/user.service';
import { BasePage } from 'src/app/shared/base-page';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cec-evaluar',
  templateUrl: './cec-evaluar.component.html',
  styleUrls: ['./cec-evaluar.component.scss']
})
export class CECEvaluateComponent extends BasePage implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  idCEC: number = 0;
  cec: CECResponse | null = null;

  // Formularios de cada sección
  form: FormGroup;

  users: User[] = [];

  constructor(
    private fb: FormBuilder, 
    private cecService: CECService,
    private userService: UserService,
    private route: ActivatedRoute,
    injector: Injector
  ) {
    super(injector);

    // Inicialización de los formularios
    this.form = this.fb.group({
      coordinator_eval: [''],
      coordinator_report: [''],
      reject_reason: [''],
      cec_member_eval: [''],
      cec_member_report: [''],
      cec_report: [''],
    });
  }


  async ngOnInit() {
    super.ngOnInit();
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Solicitudes CEC' },
      { label: 'Configurar Evaluadores Solicitud', active: true }
    ];

    this.openLoading();

    this.route.params.pipe(
      switchMap(params => {
          this.idCEC = params['id'];

          if (this.idCEC) {
              return this.cecService.getCEC(this.idCEC);
          }

          // If there's no idCEC, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: async(response) => {
            if (response ) {
                this.cec = response;

                this.cec.start_date = new Date(this.cec.start_date).toISOString().split('T')[0];
                this.cec.end_date = new Date(this.cec.end_date).toISOString().split('T')[0];

                this.users = await this.userService.getUsers();

                //Patch form values with the retrieved CEC data
                this.form.patchValue({
                  coordinator: this.cec.coordinator?.id,
                  cec_member: this.cec.cec_member?.id,
                });
            }

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Support Letter:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  /**
   * Enviar el formulario completo.
   */
  async save() {
    /*if (
      this.form.valid
    ) {*/

      let resp = await Swal.fire({
        title: "Confirmación", 
        text: `¿Está seguro que desea Enviar su Evaluación a la Solicitud al CEC?`,
        icon: 'warning',
        confirmButtonText: `Si, Enviar`,
        cancelButtonText: 'No, cancelar',
        showCancelButton: true
      });
  
      if(resp.isConfirmed){

        this.openLoading();

        const investigation: CECRequest = {
          ...this.form.value
        };

        const response = await this.cecService.evaluateCEC(investigation, this.idCEC);
        this.closeLoading();
        await this.globalSuccess(`Su Evaluación a la Solicitud a CEC se ha guardado correctamente`);
        
        //Ir a la lista de solicitudes CEC
        this.router.navigate(['/solicitudes-comite']);
      }

    /*} else {
      console.log('Por favor, completa todos los campos requeridos.');
    }*/
  }

  cancel(){
    this.router.navigate(['/solicitudes-comite']);
  }

  getFileName(filePath: string | undefined): string {
    if(!filePath) return '';
    return filePath.split('/').pop() || '';
  }

  getFileNameB64(filePath: string | undefined): string {
    if(!filePath) return '';
    const file = this.getFileName(filePath);
    return file? btoa(file) : '';
  }
}
