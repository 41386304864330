import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {environment} from "../../../environments/environment";
import {Mensaje, Pending} from "../models/utils.model";

@Injectable({
  providedIn: 'root'
})
export class PendingService {

  constructor(private http: HttpClient) {
  }

  putPending(id: number, data: Pending[]): Promise<Mensaje> {
    return lastValueFrom(this.http.put<Mensaje>(`${environment.apiURL}/pending/${id}`, data));
  }

}