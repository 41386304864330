import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private errorMsg: BehaviorSubject<string>;

  constructor() {
    this.errorMsg = new BehaviorSubject<string>('');
  }

  getValue(): Observable<string> {
    return this.errorMsg.asObservable();
  }
  setValue(newValue: string): void {
    console.log("setting new error value", newValue);
    this.errorMsg.next(newValue);
  }

}