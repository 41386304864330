import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardsComponent } from './dashboard/dashboards/dashboards.component';
import { ConvocatoriaListaComponent } from './convocatoria/convocatoria-lista/convocatoria-lista.component';
import { ConvocatoriaFormularioComponent } from './convocatoria/convocatoria-formulario/convocatoria-formulario.component';
import { UsuarioListaComponent } from './usuario/usuario-lista/usuario-lista.component';
import { UsuarioFormularioComponent } from './usuario/usuario-formulario/usuario-formulario.component';
import { RolesListaComponent } from './roles/roles-lista/roles-lista.component';
import { RolesFormularioComponent } from './roles/roles-formulario/roles-formulario.component';
import { ProyectoWizardComponent } from './proyecto/proyecto-form/proyecto-wizard/proyecto-wizard.component';
import { ListFacultyComponent } from './facultad/list-facultad/list-facultad.component';
import { FormFacultyComponent } from './facultad/form-facultad/form-facultad.component';
import { ListCareerComponent } from './carrera/list-carrera/list-carrera.component';
import { FormCarreraComponent } from './carrera/form-carrera/form-carrera.component';
import { ProyectoListaComponent } from './proyecto/proyecto-lista/proyecto-lista.component';
import { CartaApoyoListaComponent } from './carta-apoyo/carta-apoyo-lista/carta-apoyo-lista.component';
import { CartaApoyoFormularioComponent } from './carta-apoyo/carta-apoyo-formulario/carta-apoyo-formulario.component';
import { ListaPlantillasComponent } from './plantillas/lista-plantillas/lista-plantillas.component';
import { ProyectoDetalleComponent } from './proyecto/proyecto-detalle/proyecto-detalle.component';
import { CurriculumListaComponent } from './curriculum/curriculum-lista/curriculum-lista.component';
import { CurriculimFormularioComponent } from './curriculum/curriculum-formulario/curriculum-formulario.component';
import { CostosIndirectosFormularioComponent } from './costos-indirectos/costos-indirectos-formulario/costos-indirectos-formulario.component';
import { CostosIndirectosListaComponent } from './costos-indirectos/costos-indirectos-lista/costos-indirectos-lista.component';
import { ContratoFormularioComponent } from './contrato/contrato-formulario/contrato-formulario.component';
import { ContratoListaComponent } from './contrato/contrato-lista/contrato-lista.component';
import { PeerReviewersComponent } from './proyecto/pares-evaluadores/pares-evaluadores.component';
import { MyPendingActionsComponent } from './mis-pendientes/mis-pendientes.component';
import { PeerReviewComponent } from './evaluacion-pares/evaluacion-pares.component';
import { DirectiveReviewComponent } from './evaluacion-dirinves/evaluacion-dirinves.component';
import { RubricaListaComponent } from './rubrica/rubrica-lista/rubrica-lista.component';
import { RubricaFormularioComponent } from './rubrica/rubrica-formulario/rubrica-formulario.component';
import { CouncilMinuteComponent } from './acta-dirinves/acta-dirinves.component';
import { ComitteeRequestWizardComponent } from './solicitud-comite/solicitud-comite-form/solicitud-comite-wizard/solicitud-comite-wizard.component';
import { ProyectoGastoComponent } from './proyecto/proyecto-gasto/proyecto-gasto.component';
import { SolicitudOEFormularioComponent } from './solicitud-oe/solicitud-oe-formulario/solicitud-oe-formulario.component';
import { SolicitudOEListaComponent } from './solicitud-oe/solicitud-oe-lista/solicitud-oe-lista.component';
import { GastoFormularioComponent } from './gasto/gasto-formulario/gasto-formulario.component';
import { GastoListaComponent } from './gasto/gasto-lista/gasto-lista.component';
import { SolicitudOCFormularioComponent } from './solicitud-oc/solicitud-oc-formulario/solicitud-oc-formulario.component';
import { SolicitudOCListaComponent } from './solicitud-oc/solicitud-oc-lista/solicitud-oc-lista.component';
import { SolicitudDTIFormularioComponent } from './solicitud-dti/solicitud-dti-formulario/solicitud-dti-formulario.component';
import { SolicitudDTIListaComponent } from './solicitud-dti/solicitud-dti-lista/solicitud-dti-lista.component';
import { ActaInventarioFormularioComponent } from './inventario-equipo/inventario-equipo-formulario/inventario-equipo-formulario.component';
import { ActaInventarioListaComponent } from './inventario-equipo/inventario-equipo-lista/inventario-equipo-lista.component';
import { SolicitudBHFormularioComponent } from './solicitud-bh/solicitud-bh-formulario/solicitud-bh-formulario.component';
import { SolicitudBHListaComponent } from './solicitud-bh/solicitud-bh-lista/solicitud-bh-lista.component';
import { SolicitudBHAdjuntosComponent } from './solicitud-bh/solicitud-bh-adjuntos/solicitud-bh-adjuntos.component';
import { InformeAvanceFormularioComponent } from './informe-avance/informe-avance-formulario/informe-avance-formulario.component';
import { InformeAvanceListaComponent } from './informe-avance/informe-avance-lista/informe-avance-lista.component';
import { TableroComponent } from './tablero/dashboards.component';
import { PeerReviewViewComponent } from './evaluacion-pares-view/evaluacion-pares-view.component';
import { CurriculumViewComponent } from './curriculum/curriculum-view/curriculum-view.component';

const routes: Routes = [
  {
    path: '',
    component: TableroComponent
  },
  //{ path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'mis-pendientes', component: MyPendingActionsComponent },
  { path: 'evaluacion-pares',
    children: [
      { path: ':id', component: PeerReviewComponent },
      { path: 'view/:id', component: PeerReviewViewComponent }
    ]
  },
  { 
    path: 'evaluacion-dirinves',
    children: [
      { path: ':id', component: DirectiveReviewComponent }
    ]
  },
  { 
    path: 'convocatorias',
    children: [
      { path: '', component: ConvocatoriaListaComponent },
      { path: 'crear', component: ConvocatoriaFormularioComponent },
      { path: 'editar/:id', component: ConvocatoriaFormularioComponent }
    ]
  },
  {
    path: 'usuarios',
    children: [
      { path: '', component: UsuarioListaComponent },
      { path: 'crear', component: UsuarioFormularioComponent },
      { path: 'editar/:id', component: UsuarioFormularioComponent }
    ]
  },
  {
    path: 'roles',
    children: [
      { path: '', component: RolesListaComponent},
      { path: 'crear', component: RolesFormularioComponent },
      { path: 'editar/:id', component: RolesFormularioComponent }
    ]
  },
  {
    path: 'facultades',
    children: [
      { path: '', component: ListFacultyComponent},
      { path: 'crear', component: FormFacultyComponent },
      { path: 'editar/:id', component: FormFacultyComponent }
    ]
  },
  {
    path: 'carreras',
    children: [
      { path: '', component: ListCareerComponent},
      { path: 'crear', component: FormCarreraComponent },
      { path: 'editar/:id', component: FormCarreraComponent }
    ]
  },
  { 
    path: 'proyectos',
    children: [
      { path: 'crear', component: ProyectoWizardComponent },
      { path: 'editar/:id', component: ProyectoWizardComponent },
      { path: 'lista', component: ProyectoListaComponent },
      { path: 'detalle/:id', component: ProyectoDetalleComponent },
      { path: 'pares-evaluadores/:id', component: PeerReviewersComponent },
      { path: 'gastos/:id', component: ProyectoGastoComponent },
    ]
  },
  { 
    path: 'cartas-apoyo',
    children: [
      { path: 'editar/:id', component: CartaApoyoFormularioComponent },
      { path: 'lista', component: CartaApoyoListaComponent },
    ]
  },
  { 
    path: 'plantillas',
    children: [
      { path: 'lista', component: ListaPlantillasComponent },
    ]
  },
  { 
    path: 'curriculos',
    children: [
      { path: 'lista', component: CurriculumListaComponent },
      { path: 'editar/:id', component: CurriculimFormularioComponent },
      { path: 'crear', component: CurriculimFormularioComponent },
      { path: 'crear/:rut', component: CurriculimFormularioComponent },
      { path: 'view/:id', component: CurriculumViewComponent },
    ]
  },
  { 
    path: 'costos-indirectos',
    children: [
      { path: 'editar/:id', component: CostosIndirectosFormularioComponent },
      { path: 'lista', component: CostosIndirectosListaComponent },
    ]
  },
  { 
    path: 'contratos',
    children: [
      { path: 'editar/:id', component: ContratoFormularioComponent },
      { path: 'lista', component: ContratoListaComponent },
    ]
  },
  {
    path: 'rubricas',
    children: [
      { path: '', component: RubricaListaComponent },
      { path: 'crear', component: RubricaFormularioComponent },
      { path: 'editar/:id', component: RubricaFormularioComponent },
      { path: 'copiar/:id', component: RubricaFormularioComponent }
    ]
  },
  {
    path: 'actas',
    children: [
      { path: '', component: RubricaListaComponent },
      { path: 'crear', component: CouncilMinuteComponent },
      { path: 'editar/:id', component: CouncilMinuteComponent }
    ]
  },
  {
    path: 'solicitudes-comite',
    children: [
      { path: 'crear', component: ComitteeRequestWizardComponent }
    ]
  },
  {
    path: 'solicitudes-oe',
    children: [
      { path: 'crear', component: SolicitudOEFormularioComponent },
      { path: 'editar/:id', component: SolicitudOEFormularioComponent },
      { path: '', component: SolicitudOEListaComponent }
    ]
  },
  {
    path: 'gastos',
    children: [
      { path: '', component: GastoListaComponent },
      { path: 'editar/:id', component: GastoFormularioComponent },
      { path: 'crear', component: GastoFormularioComponent },
    ]
  },
  {
    path: 'solicitudes-oc',
    children: [
      { path: 'crear', component: SolicitudOCFormularioComponent },
      { path: 'editar/:id', component: SolicitudOCFormularioComponent },
      { path: '', component: SolicitudOCListaComponent } 
    ]
  },
  {
    path: 'solicitudes-dti',
    children: [
      { path: 'crear', component: SolicitudDTIFormularioComponent },
      { path: 'editar/:id', component: SolicitudDTIFormularioComponent },
      { path: '', component: SolicitudDTIListaComponent } 
    ]
  },
  {
    path: 'acta-inventario',
    children: [
      { path: 'crear', component: ActaInventarioFormularioComponent },
      { path: 'editar/:id', component: ActaInventarioFormularioComponent },
      { path: '', component: ActaInventarioListaComponent }
    ]
  },
  {
    path: 'solicitudes-bh',
    children: [
      { path: 'crear', component: SolicitudBHFormularioComponent },
      { path: 'editar/:id', component: SolicitudBHFormularioComponent },
      { path: 'adjuntos/:id', component: SolicitudBHAdjuntosComponent },
      { path: '', component: SolicitudBHListaComponent } 
    ]
  },
  {
    path: 'informe-avance',
    children: [
      { path: 'crear', component: InformeAvanceFormularioComponent },
      { path: 'editar/:id', component: InformeAvanceFormularioComponent },
      { path: '', component: InformeAvanceListaComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }