<form [formGroup]="formStep">
    <h5>5. EJECUCIÓN DEL PROYECTO</h5>
    <h5>5.1 Estimación de la duración del Proyecto</h5>
    <div class="row mb-5">
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <input type="number" class="form-control" id="duration" [min]="minDuration" [max]="maxDuration" formControlName="estimated_duration_months" min="0" max="24" step="1">
                <label for="duration">Duración (En meses)</label>
            </div>
        </div>
    </div>
    <h5>Detallar la ejecución del Proyecto en el cronograma o plan de tareas</h5>

    <div class="row gantt-options">
        <div class="col-lg-7">
            <button (click)="newItem(activityModal)" class="btn btn-primary add-btn"><i class="bx bx-plus"></i> Nueva Actividad
            </button>
        </div>
        <div class="col-lg-4 offset-lg-1">
            <div class="btn-group add-btn" role="group" aria-label="Vistas">
                <button type="button" class="btn" *ngFor="let view of views"
                        [ngClass]="view.value === viewType ? 'btn-primary': 'btn-light'"
                        (click)="viewType = view.value">
                    {{ view.name }}
                </button>
            </div>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-lg-12">
            <ngx-gantt style="height: 500px;" #gantt [items]="items" [viewOptions]="viewOptions" [viewType]="viewType" [virtualScrollEnabled]="false" [disabledLoadOnScroll]="true">
                <ngx-gantt-table>
                    <ngx-gantt-column name="Actividad" width="250px" >
                        <ng-template #cell let-item="item"> 
                            <div>
                                <b>{{ item.title }}</b>
                                <button class="btn btn-icon btn-danger" type="button" (click)="deleteItem(item)">
                                    <i class="bx bx-trash"></i>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-gantt-column>
                </ngx-gantt-table>
            </ngx-gantt>
        </div>
    </div>


    <h5>5.2 Estimación del tiempo necesario para realizar la investigación</h5>
    <small>*Tiempo completo, medio tiempo, honorarios, no contratado (estudiantes, investigadores de otras universidades…).<br>
        **Las horas de investigación son horas cronológicas, no son horas académicas.</small>
    <div class="row mb-5">
        <div class="col-lg-12">
            <button class="btn btn-primary add-btn" (click)="addPeopleEstimation()"><i class="bx bx-plus"></i> Agregar Persona</button>
            <div class="table-responsive">
                <table class="table mb-0 small-padding-table" formArrayName="people_estimation">
                    <thead class="table-light">
                        <tr>
                            <th rowspan="3" style="width: 150px;">Investigador / Estudiante</th>
                            <th rowspan="3" style="width: 100px;">Facultad / Unidad</th>
                            <th rowspan="3" style="width: 100px;">Tipo de Contrato</th>
                            <th rowspan="3" style="width: 100px;">Grado</th>
                            <th [attr.colspan]="semesters.length + 2">N° horas cronológicas* asignadas para el Proyecto**</th>
                            <th rowspan="3">Elim.</th>
                        </tr>
                        <tr>
                            <th [attr.colspan]="semesters.length">N° horas</th>
                            <th rowspan="2" class="week_number">N° semanas</th>
                            <th rowspan="2" class="total_hours">N° horas totales</th>
                        </tr>
                        <tr>
                            <th *ngFor="let sem of semesters" class="semester_hours">
                                {{ sem.name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let pe of peopleEstimations.controls; let i = index" [formGroupName]="i">
                        <tr>
                            <td>
                                <input class="form-control" type="text" placeholder="RUT" formControlName="rut">
                                
                            </td>
                            <td>
                                <select class="form-select" formControlName="faculty">
                                    <option value="">Facultad</option>
                                    <option *ngFor="let faculty of faculties" [value]="faculty.id">
                                        {{faculty.acronym}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select" formControlName="contract_type"> 
                                    <option value="">Tipo Contrato</option>
                                    <option *ngFor="let ct of contractTypes" [value]="ct.id">
                                        {{ct.name}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select" formControlName="grade"> 
                                    <option value="">Grado Académico</option>
                                    <option *ngFor="let gt of gradeTypes" [value]="gt.id">
                                        {{gt.name}}
                                    </option>
                                </select>
                            </td>
                            <ng-container formArrayName="weekly_hours">
                                <td *ngFor="let hourIndex of getWeeklyHoursControls(i)">
                                    <input type="number" class="form-control" [formControlName]="hourIndex" min="0" max="4" />
                                </td>
                            </ng-container>
                            <td><input type="number" class="form-control" formControlName="weeks" min="0"/></td>
                            <td class="person_total_hours">{{getPersonTotalHours(i)}}h</td>
                            <td rowspan="2" style="vertical-align: middle;"><button class="btn btn-icon btn-lg btn-danger" type="button" (click)="removePerson(i)"><i class="bx bx-trash"></i></button></td>
                        </tr>
                        <tr style="border-bottom: 1px dashed darkgray;">
                            <td colspan="3">
                                <input class="form-control" type="text" placeholder="NOMBRE COMPLETO" formControlName="name">
                            </td>
                            <td [attr.colspan]="semesters.length + 3"></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td [attr.colspan]="semesters.length + 6" style="text-align: right;">TOTAL</td>
                            <td>{{getTotalHours()}}h</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>

    <h5>5.3 Otras iniciativas o Proyectos que el Investigador (es) contratado de la UnACh haya postulado o esté ejecutando</h5>
    <div class="row mb-5">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table mb-0">
                    <thead class="table-light">
                        <tr>
                            <th><b>Investigador contratado de la UnACh</b> que postula o participa en otra investigación distinta a la presente</th>
                            <th>Tiempo Adjudicado o solicitado en otros proyectos (horas semanales)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!--tr>

                            <td>1.- PATERNO MATERNO, NOMBRES<br>
                            <ul>
                                <li>Titulo de la Investigación: Investigación TEST</li>
                                <li>Entidad Patrocinante: Universidad del BioBio</li>
                                <li>Estado: En Curso</li>
                            </ul>
                            </td>
                            <td>10h</td>
                        </tr-->
                        <tr>
                            <td>No se registran otras Actividades</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <h5>Actividades por Investigador</h5>
    <div class="row mb-5">
        <div class="col-lg-12" formArrayName="other_academics">
            <div class="table-responsive" *ngFor="let person of otherAcademics.controls; let i = index" [formGroupName]="i">
                <table class="table mb-0">
                    <thead class="table-light">
                        <tr>
                            <th colspan="4"><b>{{person.value.academic.rut}} {{person.value.academic.name}}</b></th>
                        </tr>
                        <tr>
                            <th>Actividad (durante toda la duración del Proyecto)</th>
                            <th>Inicio (mes-año)</th>
                            <th>Fin (mes-año)</th>
                            <th>Elim.</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="activities">
                        <tr *ngFor="let activity of academicActivities(i).controls; let j = index" [formGroupName]="j">
                            <td>
                                <select class="form-select" formControlName="objective">
                                    <option value="">Seleccione un Objetivo</option>
                                    <option *ngFor="let objective of projectObjectives" [value]="objective.id">
                                        {{objective.name}}
                                    </option>
                                </select>
                            </td>
                            <td><input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="m/Y" mode="single" formControlName="start_date"></td>
                            <td><input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="m/Y" mode="single" formControlName="end_date"></td>
                            
                            <td style="width: 50px; vertical-align: middle;"><button class="btn btn-icon btn-lg btn-danger" type="button" (click)="removeAcademicActivity(i, j)"><i class="bx bx-trash"></i></button></td>
                        </tr>
                        <tr>
                            <td colspan="3" style="text-align: right;">
                                <button class="btn btn-primary add-btn" (click)="addPersonActivity(i)"><i class="bx bx-plus"></i> Agregar Actividad</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


    <div class="d-flex flex-row-reverse">
        <button class="btn btn-primary" type="submit" (click)="nextStep()">Siguiente</button>
        <button class="btn btn-primary" style="margin-right: 5px;" type="button" (click)="save()">Guardar</button>
        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="previousStep()">Atrás</button>
        <span style="margin-right: 5px; padding-top: 6px;"><small>Último Guardado {{parentComponent.lastModified | date:'dd/MM/YYYY HH:mm:ss'}}</small></span>
    </div>

</form>

<ng-template #activityModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Agregar Actividad</h5>
        <button type="button" class="btn-close"
            (click)="modal.dismiss('Cross click')"
            aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3">
                    <label>Actividad</label>
                    <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="currentActivity.name">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Inicio</label>
                    <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" mode="single" [convertModelValue]="true" (change)="setMinimo($event)" [max]="maximo" [(ngModel)]="currentActivity.start">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Término</label>
                    <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" mode="single" [convertModelValue]="true" (change)="setMaximo($event)" [min]="minimo" [(ngModel)]="currentActivity.end">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="addItem()" class="btn btn-primary add-btn"><i class="bx bx-save"></i>Guardar</button>
    </div>
</ng-template>
