import { BaseModel } from "./base.model";
import { User } from "./user.model";

export class Faculty extends BaseModel {
  name?: string;
  is_active?: boolean;
  acronym!: string;
  dean?: User;
}

export class FacultyRequest {
  name?: string;
  is_active?: boolean;
  acronym!: string;
  dean?: number;
}