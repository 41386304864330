import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    /*constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload();
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }*/

    constructor(private errorService: ErrorService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        return next.handle(request).pipe(catchError(err => {

            console.log("catch error", err);
            
            // Check for the custom HTTP header and skip the error handling if it's present
            if (request.headers.get('X-Skip-Error-Interceptor') === 'true') {
                return throwError(() => err);
            }
 
            if(typeof err.error === "string"){
                let displayMessage = err.error;
                if(displayMessage.indexOf("at module.exports")!== -1) displayMessage = displayMessage.substring(0, displayMessage.indexOf("at module"));
                this.errorService.setValue(displayMessage);
                return throwError(() => displayMessage);
            } else {
                let displayMessage = err.error.details;
                this.errorService.setValue(displayMessage);
                return throwError(() => displayMessage);
            }

            return throwError(() => err);
        }))
    }
}