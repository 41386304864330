<form [formGroup]="formStep1">
    <h5>1. IDENTIFICACION DEL PROYECTO</h5>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="academic" formControlName="name_academic">
                <label for="academic">Investigador Responsable</label>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="rut_academic" value="" formControlName="rut_academic">
                <label for="rut_academic">RUT</label>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-floating mb-3">
                <select class="form-select" id="academic_gender" formControlName="gender_academic">
                    <option *ngFor="let gender of genders" [value]="gender">
                        {{gender}}
                    </option>
                </select>
                <label for="academic_gender">Género</label>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-floating mb-3">
                <input type="email" class="form-control" id="email_academic" formControlName="email_academic" readonly>
                <label for="email_academic">Correo Electrónico</label>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="phone_academic" formControlName="phone_academic">
                <label for="phone_academic">Teléfono UnACh</label>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="cell_academic" formControlName="cell_academic">
                <label for="cell_academic">Celular</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-lg-3">
            <label>¿Corresponde a un TFC / TFP?</label><br>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="is_TFC" id="btnradiotfc1" autocomplete="off" [checked]="formStep1.get('is_TFC')?.value" formControlName="is_TFC" [value]="true">
                <label class="btn btn-outline-primary" for="btnradiotfc1">SI</label>
              
                <input type="radio" class="btn-check" name="is_TFC" id="btnradiotfc2" autocomplete="off" [checked]="!formStep1.get('is_TFC')?.value" formControlName="is_TFC" [value]="false">
                <label class="btn btn-outline-primary" for="btnradiotfc2">NO</label>
            </div>
        </div>
        <div class="col-lg-3" *ngIf="project?.type == 'PI'">
            <label>¿Proyecto con VCM?</label><br>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="with_VCM" id="btnradiovcm1" autocomplete="off" [checked]="formStep1.get('with_VCM')?.value" formControlName="with_VCM" [value]="true">
                <label class="btn btn-outline-primary" for="btnradiovcm1">SI</label>
              
                <input type="radio" class="btn-check" name="with_VCM" id="btnradiovcm2" autocomplete="off" [checked]="!formStep1.get('with_VCM')?.value" formControlName="with_VCM" [value]="false">
                <label class="btn btn-outline-primary" for="btnradiovcm2">NO</label>
            </div>
        </div>
        <div class="col-lg-6" *ngIf="project?.type == 'PI'">
            <label>Indique el tipo de proyecto que está postulando</label><br>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="projectType" id="btnradiotipoPI1" autocomplete="off" formControlName="projectType" value="is_initial">
                <label class="btn btn-outline-primary" for="btnradiotipoPI1">Iniciación (hasta 12 meses)</label>
              
                <input type="radio" class="btn-check" name="projectType" id="btnradiotipoPI2" autocomplete="off" formControlName="projectType" value="is_regular">
                <label class="btn btn-outline-primary" for="btnradiotipoPI2">Regular (hasta 24 meses)</label>

                <input type="radio" class="btn-check" name="projectType" id="btnradiotipoPI3" autocomplete="off" formControlName="projectType" value="is_technology_transfer">
                <label class="btn btn-outline-primary" for="btnradiotipoPI3">Transf. Tec. (hasta 24 meses)</label>
            </div>
        </div>
        <div class="col-lg-5" *ngIf="project?.type == 'PCRAC'" style="text-align: center;">
            <label>¿Con qué se relaciona su proyecto ?</label><br>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="projectType" id="btnradiotipoPCRAC1" [checked]="formStep1.get('projectType')?.value == 'is_related_music'" autocomplete="off" checked formControlName="projectType" value="is_related_music">
                <label class="btn btn-outline-primary" for="btnradiotipoPCRAC1">Música</label>
              
                <input type="radio" class="btn-check" name="projectType" id="btnradiotipoPCRAC2" [checked]="formStep1.get('projectType')?.value == 'is_related_literature'" autocomplete="off" formControlName="projectType" value="is_related_literature">
                <label class="btn btn-outline-primary" for="btnradiotipoPCRAC2">Literatura</label>

                <input type="radio" class="btn-check" name="projectType" id="btnradiotipoPCRAC3" [checked]="formStep1.get('projectType')?.value == 'other_related'" autocomplete="off" formControlName="projectType" value="other_related">
                <label class="btn btn-outline-primary" for="btnradiotipoPCRAC3">Otro (Indicar)</label>
            </div>
        </div>
        <div class="col-lg-4" *ngIf="project?.type == 'PCRAC' && formStep1.get('projectType')?.value == 'other_related'">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="pcrac_relationship_other" value="" formControlName="pcrac_relationship_other">
                <label for="pcrac_relationship_other">Otra Relación</label>
            </div>
        </div>
    </div>

    <h5 class="mt-5">1.1 Identifique la línea de investigación a la cual tributará su proyecto</h5>
    <div class="row mb-5">
        <div class="col-lg-12">
            <div class="list-group">
                <label class="list-group-item" *ngFor="let line of investigativeLines">
                    <input class="form-check-input me-1" type="checkbox" [(ngModel)]="line.checked" [ngModelOptions]="{standalone: true}">
                    {{line.name}}
                </label>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="keywords" name="keywords" formControlName="keywords" >
                <label for="keywords">Palabras Clave <small>(3 a 5 descriptores temáticos relevantes)</small></label>
            </div>
        </div>
    </div>            
    <div class="d-flex flex-row-reverse">
        <button class="btn btn-primary" type="submit" (click)="nextStep()">Siguiente</button>
        <button class="btn btn-primary" style="margin-right: 5px;" type="button" (click)="save()">Guardar</button>
        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="previousStep()">Atrás</button>
        <span style="margin-right: 5px; padding-top: 6px;"><small>Último Guardado {{parentComponent.lastModified | date:'dd/MM/YYYY HH:mm:ss'}}</small></span>
    </div>

</form>
