import { Component, Injector, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BasePage } from 'src/app/shared/base-page';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { PersonEstimation } from 'src/app/core/models/person_estimation.model';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project.service';
import { flatpickrFactory } from '../../pages.module';
import { Contract } from 'src/app/core/models/contract.model';
import { ContractService } from 'src/app/core/services/contract.service';
import { SignDocument } from "../../../core/models/utils.model";
import { Minute } from 'src/app/core/models/minute.model';
import { WorkloadRequest } from 'src/app/core/models/workload.model';
import { WorkloadService } from 'src/app/core/services/workload.service';
import { MinuteService } from 'src/app/core/services/minute.service';

export interface Semester{
  semester: number;
  year: number;
  name: string;
}

@Component({
  selector: 'app-contrato-formulario',
  templateUrl: './contrato-formulario.component.html',
  styleUrls: ['./contrato-formulario.component.scss']
})
export class ContratoFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formContract: FormGroup = this.fb.group({
    isSigned: [false],
    startDate: [undefined, [Validators.required]],
    endDate: [undefined, [Validators.required]],
    directCosts: [undefined, [Validators.required]],
    indirectCosts: [undefined, [Validators.required]],
    contractFile: [null],
    contractFileName: [undefined],
  });

  contract?: Contract = undefined;
  idContract: number = 0;
  personEstimations: PersonEstimation[] = [];
  project?: Project = undefined;


  minutes!: Minute[];
  workload?: WorkloadRequest = undefined;

  isSigned: boolean = false;
  contractFileBase64: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: ContractService,
    private projectService: ProjectService,
    private location: Location,
    private workloadService: WorkloadService,
    private minuteService: MinuteService,
    injector: Injector
  ) { 
    super(injector);

    this.formContract.get('isSigned')!.valueChanges.subscribe((value) => {
      //this.isSigned = value;
      if (value) {
        this.formContract.get('contractFile')!.setValidators([Validators.required]);
      } else {
        this.formContract.get('contractFile')!.clearValidators();
      }
      this.formContract.get('contractFile')!.updateValueAndValidity();
    });
  }

  async ngOnInit() {

    super.ngOnInit();
    this.openLoading();

    flatpickrFactory();
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Contratos' },
      { label: 'Editar Contrato', active: true }
    ];

    this.route.params.pipe(
      switchMap(params => {
          this.idContract = params['id'];

          if (this.idContract) {
              return this.contractService.getContract(this.idContract);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: async(response) => {
            if (response ) {
                this.contract = response;

                this.project = await this.projectService.getProject(this.contract.project.id);
                this.personEstimations = await this.contractService.getPersonEstimation(response.project.id);

                this.minutes = await this.minuteService.getMinuteByProject(response.project.id);
                if(this.minutes.length > 0){
                  this.workload = await this.workloadService.getWorkloadFromMinute(this.minutes[0].id);
                }

                
                this.formContract.patchValue({
                  isSigned: this.contract.isSigned,
                  startDate: this.contract.startDate,
                  endDate: this.contract.endDate,
                  directCosts: this.contract.directCosts,
                  indirectCosts: this.contract.indirectCosts
                });

                this.isSigned = this.contract.isSigned;

                
            }

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Contracts:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  get semesters(): Semester[] {
    const semesters: Semester[] = [];
    for (let pe of this.personEstimations) {
      for (let h of pe.hours ? pe.hours : []) {
        let auxSemester = semesters.find(s => s.semester === h.semester && s.year === h.year);
        if (!auxSemester) {
          auxSemester = {
            semester: h.semester,
            year: h.year,
            name: h.semester.toString().padStart(2, '0') + "" + h.year
          };
          semesters.push(auxSemester);
        }
      }
    }
    return semesters;
  }

  getWeeklyHoursControls(index: number): number[] {
    let result = [];
    for (let s of this.semesters) {
      let value = 0;
      for (let h of this.personEstimations[index].hours || []) {
        if (s.semester === h.semester && s.year === h.year) {
          value = h.hours;
        }
      }
      result.push(value);
    }
    return result;
  }

  getPersonTotalHours(index: number): number {
    let auxPerson = this.personEstimations[index];
    if (auxPerson) {
      const weeks = auxPerson.weeks || 0;
      const listHours = auxPerson.hours || [];
      const totalHours = listHours.map(control => control.hours || 0)
          .reduce((acc, hours) => acc + hours, 0) * weeks;
      return totalHours;
    }
    return 0;
  }

  getTotalHours(): number {
    let total = 0;
    for (let i = 0; i < this.personEstimations.length; i++) {
      total += this.getPersonTotalHours(i);
    }
    return total;
  }

  previewPDF(){
    window.open('/api/contract/pdf?id=' + this.idContract, '_blank');
  }

  async save() {
    // Marcar todos los controles como tocados para activar las validaciones
    this.formContract.markAllAsTouched();

    if (this.formContract.valid) {

      let datos = this.formContract.value;
      let request: SignDocument = {
        startDate: datos.startDate,
        endDate: datos.endDate,
        directCosts: datos.directCosts,
        indirectCosts: datos.indirectCosts,
        isSigned: datos.isSigned,
        contractFile: this.contractFileBase64 || '',
        contractFileName: datos.contractFileName
      };

      this.openLoading();
      await this.contractService.putContract(this.idContract, request);
      this.closeLoading();
      await this.globalSuccess('El contrato se ha actualizado correctamente.');
      this.cancel();
    }
  }

  cancel(): void {
    this.location.back();
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.contractFileBase64 = reader.result as string;
        this.formContract.patchValue({
          contractFile: this.contractFileBase64,
          contractFileName: file.name
        });
      };
      reader.readAsDataURL(file);
    }
  }

}
