<!-- start page title -->
<app-pagetitle title="Formulario Carta Apoyo" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formSupportLetter">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="academic" value="{{project?.academic?.fullName}}" readonly>
                                <label for="academic">Investigador Responsable</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="rut_academic" value="{{project?.rut_academic}}" readonly>
                                <label for="rut_academic">RUT</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="academic_gender" value="{{project?.gender_academic}}" readonly>
                                <label for="academic_gender">Género</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email_academic" value="{{project?.academic?.email}}" readonly>
                                <label for="email_academic">Correo Electrónico</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="phone_academic" value="{{project?.phone_academic}}" readonly>
                                <label for="phone_academic">Teléfono UnACh</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="cell_academic" value="{{project?.cell_academic}}" readonly>
                                <label for="cell_academic">Celular</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0 small-padding-table" formArrayName="people_estimation">
                            <thead class="table-light">
                                <tr>
                                    <th rowspan="3">Investigador / Estudiante</th>
                                    <th rowspan="3">Facultad / Unidad</th>
                                    <th rowspan="3">Tipo de Contrato</th>
                                    <th rowspan="3">Grado</th>
                                    <th [attr.colspan]="semesters.length + 2">N° horas cronológicas* asignadas para el Proyecto**</th>
                                </tr>
                                <tr>
                                    <th [attr.colspan]="semesters.length">N° horas</th>
                                    <th rowspan="2">N° semanas</th>
                                    <th rowspan="2">N° horas totales</th>
                                </tr>
                                <tr>
                                    <th *ngFor="let sem of semesters">
                                        {{ sem.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pe of peopleEstimations.controls; let i = index" [formGroupName]="i">
                                    <td>
                                        <input class="form-control" type="text" placeholder="RUT" formControlName="rut" readonly>
                                        <input class="form-control" type="text" placeholder="NOMBRE COMPLETO" formControlName="name" readonly>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" formControlName="faculty"  readonly>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" formControlName="contract_type"  readonly>
                                    </td>
                                    <td><input class="form-control" type="text" placeholder="GRADO" formControlName="grade"  readonly></td>
                                    <ng-container formArrayName="weekly_hours">
                                        <td *ngFor="let hourIndex of getWeeklyHoursControls(i)">
                                            <input type="number" class="form-control" [formControlName]="hourIndex" min="0" max="4"/>
                                        </td>
                                    </ng-container>
                                    <td><input type="number" class="form-control" formControlName="weeks" min="0"/></td>
                                    <td>{{getPersonTotalHours(i)}}h</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td [attr.colspan]="semesters.length + 5" style="text-align: right;">TOTAL</td>
                                    <td>{{getTotalHours()}}h</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="card" *ngIf="userDetails.id === supportLetter?.user?.id && !supportLetter?.isSigned">
                <div class="card-body row" style="text-align: center;">
                    <div class="col col-lg-8">
                        <div class="form-check form-check-warning mb-3">
                            <input class="form-check-input" type="checkbox" id="formCheckcolor4" formControlName="isSigned" style="width: 30px; height: 30px; margin-right: 15px">
                            <label class="form-check-label" for="formCheckcolor4" style="line-height: 33px;">
                                ¿Firmar Carta de Apoyo? <small>Una vez firmada no se puede modificar</small>
                            </label>
                        </div>
                    </div>
                    <div class="col col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="rut" placeholder="Ingrese su RUT para confirmar" formControlName="rut">
                            <label for="rut">R.U.T. Firmante</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card text-white bg-warning" *ngIf="!supportLetter?.isSigned && userDetails.id !== supportLetter?.user?.id">
                <div class="card-body" style="text-align: center;">
                    <div class="col col-lg-12">
                        <div class="form-check mb-3">
                            <h5>Carta de Apoyo Firmada NO FIRMADA</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card text-white bg-success" *ngIf="supportLetter?.isSigned">
                <div class="card-body" style="text-align: center;">
                    <div class="col col-lg-12">
                        <div class="form-check mb-3">
                            <h5>Carta de Apoyo Firmada Digitalmente el {{supportLetter?.dateSigned | date: 'dd/MM/yyyy'}}, por {{this.supportLetter?.user?.fullName}} - RUT: {{this.supportLetter?.rut}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">

                    <div class="d-flex justify-content-between">
                        <!-- Left-aligned button -->
                        <button class="btn btn-secondary" type="button" (click)="previewPDF()">{{this.supportLetter?.isSigned? 'Descargar PDF': 'Previsualizar PDF'}}</button>
                    
                        <!-- Right-aligned buttons -->
                        <div>
                          <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                          <button class="btn btn-primary" type="submit" (click)="save()" *ngIf="userDetails.id === supportLetter?.user?.id && !supportLetter?.isSigned">Guardar Carta de Apoyo</button>
                        </div>
                      </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>