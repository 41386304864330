import {Component, Input} from '@angular/core';
import {Rubric, RubricApplication, RubricCategory, RubricQuestion} from "../../../core/models/rubrica.model";

@Component({
    selector: 'app-rubrica-respuesta',
    templateUrl: './rubrica-respuesta.component.html',
    styleUrls: ['./rubrica-respuesta.component.scss']
})
export class RubricaRespuestaComponent {

    @Input() rubric?: Rubric;
    @Input() peerEvaluations: RubricApplication[] = [];

    getEvalTotal(): number {
        let total = 0;
        this.rubric?.categories.forEach(cat => {
            total += this.getCatTotal(cat);
        });
        return total;
    }

    getTotalTotal(): number {

        let total = 0;
        if(this.peerEvaluations.length > 0){
            total = this.peerEvaluations.reduce((acc, val) => acc + (val.totalScore || 0), 0);
        }
        this.rubric?.categories.forEach(cat => {
            total += this.getCatTotal(cat);
        });
        return total;
    }

    getEvalText(): string {
        const evalTotal = this.getTotalTotal();
        const json = JSON.parse(this.rubric?.evalJSON || '{}');
        if(json.hasOwnProperty('evals')){
            for(let e of json.evals){
                if(evalTotal >= e.min && evalTotal <= e.max){
                    return e.name;
                }
            }
        }
        return '';
    }

    numberToRoman(num: number): string {
        // Define the lookup object with string keys
        const lookup: { [key: string]: number } = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1
        };
        let roman = '';

        for (let key in lookup) {
            while (num >= lookup[key]) {
                roman += key;
                num -= lookup[key];
            }
        }
        return roman;
    }

    getOptionValues(question: RubricQuestion, optionIndex: number): string {
        return `(${question.options[optionIndex].minValue} - ${question.options[optionIndex].maxValue} puntos)`;
    }

    getMaxCat(cat: RubricCategory): number {
        let total = 0;
        cat.questions.forEach(q => {
            let max = 0;
            q.options.forEach(o => {
                if (o.maxValue > max) {
                    max = o.maxValue;
                }
            });
            total += max;
        });
        return total;
    }

    getCatTotal(cat: RubricCategory): number {
        return cat.questions.reduce((total, q) => total + q.answer || 0, 0);
    }
}