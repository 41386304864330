<!-- start page title -->
<app-pagetitle title="Evaluación Proyecto" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="row">
    <div class="col-12">
        <app-proyecto-view [project]="project"></app-proyecto-view>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <app-rubrica-respuesta [rubric]="rubric"></app-rubrica-respuesta>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table class="table mb-0" *ngIf="commentEvaluation">
                        <tbody>
                        <tr>
                            <th>Introduzca un comentario para el Investigador (se compartirá vía correo electrónico
                                anónimamente)
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <textarea class="form-control" rows="5" [(ngModel)]="commentEvaluation.investigador"></textarea>
                            </th>
                        </tr>
                        <tr>
                            <th>Introduzca un comentario para el Departamento de Investigación</th>
                        </tr>
                        <tr>
                            <th>
                                <textarea class="form-control" rows="5" [(ngModel)]="commentEvaluation.departamento"></textarea>
                            </th>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                    <button type="button" class="btn btn-light" (click)="cancel()">
                        <i class="bx bxs-x-square"></i> Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" (click)="save()">
                        <i class="bx bx-save"></i> Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end row -->
