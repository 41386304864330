<!-- start page title -->
<app-pagetitle title="Inventario de Equipos Proyecto de Investigación" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formSolicitud" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="name">
                                <label for="name">Nombre Propietario</label>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="tipoEquipo">Tipo de Equipo</label>
                                <textarea class="form-control" id="tipoEquipo" name="type" rows="3" formControlName="tipoEquipo"></textarea>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="description">Descripción</label>
                                <textarea class="form-control" id="description" name="type" rows="3" formControlName="description"></textarea>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="detalles">Fabricante, Modelo y Número de Serie</label>
                                <textarea class="form-control" id="detalles" name="type" rows="3" formControlName="detalles"></textarea>
                            </div>
                        </div>

                        <div class="col col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="anio" placeholder="2024" formControlName="anio">
                                <label for="anio">Año de Compra</label>
                            </div>
                        </div>

                        <div class="col col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="valor" placeholder="200.000" formControlName="valor">
                                <label for="valor">Valor en Pesos</label>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-floating mb-3"> ¿Activo?<br>
                                <input type="checkbox" id="switch6" switch="primary" formControlName="isActive" />
                                <label class="me-1" for="switch6"></label>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="comment">Comentario</label>
                                <textarea class="form-control" id="comment" name="type" rows="3" formControlName="comment"></textarea>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
