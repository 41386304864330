<!-- start page title -->
<app-pagetitle title="Formulario Rol" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formRole">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Ej. Administrador" formControlName="name">
                                <label for="name">Nombre del Rol</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <div formArrayName="sections">
                                <div *ngFor="let sectionControl of sectionsFormArray.controls; let i = index">
                                    <input type="checkbox" [formControlName]="i"> {{ allSections[i].name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="d-flex flex-row-reverse">
                        <button class="btn btn-primary" type="submit" (click)="save()">Guardar Rol</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>