<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Lista de Plantillas de PDF</h4>

                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>Nombre Plantilla</th>
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Plantilla Formulario de Carta Apoyo Facultades</td>
                                <td>
                                    <a href="https://docs.google.com/document/d/1ow5xmNeGKCQ7Bc3jJgCU5MYYODzHEgn_nIpuiOmODa0/edit" target="_blank" class="btn btn-outline-primary waves-effect">EDITAR</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Plantilla Formulario de Costos Indirectos</td>
                                <td>
                                    <a href="https://docs.google.com/document/d/1nRDblvKgdISpv96onRTIbheBNVNoVQkvxAK5QcjxAGs/edit" target="_blank" class="btn btn-outline-primary waves-effect">EDITAR</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Plantilla Formulario de Contratos</td>
                                <td>
                                    <a href="https://docs.google.com/document/d/1kImGvvoybOSgSxojsDxp0bMTzA1MUju_D7z0ldD8Yjc/edit" target="_blank" class="btn btn-outline-primary waves-effect">EDITAR</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
