<!-- start page title -->
<app-pagetitle title="Formulario Rendición Gasto" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formRendicion" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
                    <div class="row justify-content-end">
                        <div class="col-lg-2">
                            <div class="form-floating mb-3"> ¿Solicita Devolución?<br>
                                <input type="checkbox" id="switch6" switch="primary" formControlName="requestDeposit" />
                                <label class="me-1" for="switch6"></label>
                            </div>
                        </div>
                        <div class="col col-lg-4">
                            <div class="mb-3">
                                <label for="name">Fecha</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="Date">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="Name" readonly>
                                <label for="name">Presentada Por</label>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="Rut">
                                <label for="name">Rut</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="Bank">
                                <label for="name">Banco</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="Account">
                                <label for="name">Num Cuenta</label>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" formControlName="Month">
                                    <option value="">Eliga un Mes</option>
                                    <option *ngFor="let mes of meses" [value]="mes.numero">{{mes.nombre}}</option>
                                </select>
                                <label for="floatingSelect">Mes</label>
                            </div>
                        </div>

                        <div class="col col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="anio" placeholder="2024" formControlName="Year">
                                <label for="anio">Año</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-lg-2">
                            <div class="mb-3">
                                <label for="name">Fecha Documento</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="DocDate">
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect2" formControlName="DocType">
                                    <option value="">Eliga un Tipo Documento</option>
                                    <option *ngFor="let td of tiposDoc" [value]="td.tipo">{{td.texto}}</option>
                                </select>
                                <label for="floatingSelect2">Tipo Documento</label>
                            </div>
                        </div>
                        <div class="col col-lg-2">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="NumDoc" placeholder="2024" formControlName="DocNumber">
                                <label for="NumDoc">Num Documento</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="desc" formControlName="Provider">
                                <label for="desc">Proveedor</label>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="desc" formControlName="Description">
                                <label for="desc">Detalle del Gasto</label>
                            </div>
                        </div>
                        <div class="col col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="amount" placeholder="40000" formControlName="Amount">
                                <label for="amount">Monto</label>
                            </div>
                        </div>
                        <div class="col col-lg-3">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="NroCuentaContable" placeholder="40000" formControlName="NroCuentaContable">
                                <label for="NroCuentaContable">Num Cuenta Contable</label>
                            </div>
                        </div>
                        <div class="col col-lg-3">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="NroSubCuentaContable" placeholder="40000" formControlName="NroSubCuentaContable">
                                <label for="NroSubCuentaContable">Nro Sub Cuenta Contable</label>
                            </div>
                        </div>
                        <div class="col col-lg-3">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="NroCentroCosto" placeholder="40000" formControlName="NroCentroCosto">
                                <label for="NroCentroCosto">Num Centro Costo</label>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect3" formControlName="Category">
                                    <option value="">Eliga una Categoría</option>
                                    <option *ngFor="let cat of categorias" [value]="cat.valor">{{cat.texto}}</option>
                                </select>
                                <label for="floatingSelect3">Categoría</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
