import { Activity } from "./activity.model";
import { BaseModel } from "./base.model";
import { CallPeriod } from "./call-period.model";
import { Career } from "./career.model";
import { EstimateExpense } from "./estimate_expense.model";
import { Faculty } from "./faculty.model";
import { Objective } from "./objective.model";
import { OtherAcademics } from "./other_academics.model";
import { OtherProjects } from "./other_projects.model";
import { PersonEstimation } from "./person_estimation.model";
import { User } from "./user.model";

export class Project extends BaseModel {
  title!: string;
  folio!: string;
  type?: string;
  rut_academic!: string;
  phone_academic?: string;
  gender_academic?: string;
  cell_academic?: string;
  is_TFC?: boolean;
  is_related_music?: boolean;
  is_related_literature?: boolean;
  other_related?: string;
  is_initial?: boolean;
  is_regular?: boolean;
  is_technology_transfer?: boolean;
  with_VCM?: boolean;
  investigative_lines?: string;
  keywords?: string;
  pcrac_executive_summary?: string;
  pcrac_rationale?: string;
  pcrac_objectives_and_results?: string;
  pcrac_work_proposal?: string;
  pcrac_expected_impact?: string;
  pcrac_transfer_plan?: string;
  pcrac_teaching_support?: string;
  pcrac_expositions?: string;
  pcrac_publications?: string;
  pi_summary?: string;
  pi_introduction?: string;
  pi_objectives?: Objective[];
  pi_methodology?: string;
  pi_contribution_science?: string;
  pi_aplicability_results?: string;
  pi_transfer_plan?: string;
  pi_teaching_support?: string;
  pi_expositions?: string;
  pi_publications?: string;
  estimated_duration_months?: number;
  academic?: User;
  callPeriod?: CallPeriod;
  faculty?: Faculty;
  career?: Career;
  activities?: Activity[];
  people_estimation?: PersonEstimation[];
  other_projects?: OtherProjects[];
  other_academics?: OtherAcademics[];
  estimate_expenses?: EstimateExpense[];
  current_status?: string;
}

export class ProjectRequest {
  id?: number;
  title!: string;
  folio!: string;
  type!: string;
  callPeriod!: number;
  faculty!: number;
  career!: number;
  name_academic?: string;
  rut_academic!: string;
  phone_academic?: string;
  cell_academic?: string;
  is_TFC?: boolean;
  is_related_music?: boolean;
  is_related_literature?: boolean;
  other_related?: string;
  is_initial?: boolean;
  is_regular?: boolean;
  is_technology_transfer?: boolean;
  with_VCM?: boolean;
  investigative_lines?: string;
  keywords?: string;
  pcrac_executive_summary?: string;
  pcrac_rationale?: string;
  pcrac_objectives_and_results?: string;
  pcrac_work_proposal?: string;
  pcrac_expected_impact?: string;
  pcrac_transfer_plan?: string;
  pcrac_teaching_support?: string;
  pcrac_expositions?: string;
  pcrac_publications?: string;
  pi_summary?: string;
  pi_introduction?: string;
  pi_objectives?: Objective[];
  pi_methodology?: string;
  pi_contribution_science?: string;
  pi_aplicability_results?: string;
  pi_transfer_plan?: string;
  pi_teaching_support?: string;
  pi_expositions?: string;
  pi_publications?: string;
  estimated_duration_months?: number;
  academic?: number;
  activities?: Activity[];
  people_estimation?: PersonEstimation[];
  other_projects?: OtherProjects[];
  other_academics?: OtherAcademics[];
  estimate_expenses?: EstimateExpense[];
  deleted_expenses?: number[];

  static fromProject(project: Project): Partial<ProjectRequest> {
    return {
      ...project,
      callPeriod: project.callPeriod?.id,
      faculty: project.faculty?.id,
      career: project.career?.id,
      academic: project.academic?.id,
      name_academic: project.academic?.fullName,
    }
  }
}