import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { Faculty, FacultyRequest } from 'src/app/core/models/faculty.model';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { of, switchMap, tap } from 'rxjs';


@Component({
    selector: 'app-informe-avance-formulario',
    templateUrl: './informe-avance-formulario.component.html',
    styleUrls: ['./informe-avance-formulario.component.scss']
})
export class InformeAvanceFormularioComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    formInformeAvance!: FormGroup;

    faculty?: Faculty = undefined;
    facultyId: number = 0;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private facultadService: FacultadesService,
        injector: Injector
    ) {
        super(injector);
        this.formInformeAvance = this.fb.group({
            objetivosCumplidos: this.fb.array([this.fb.control('')]),
            actividadesRestantes: this.fb.array([this.fb.control('')]),
            cambiosObjetivos: this.fb.array([this.fb.group({
                objetivoOriginal: [''],
                objetivoNuevo: ['']
            })]),
            resultadosObtenidos: this.fb.array([this.fb.control('')]),
            problemasSurgidos: this.fb.array([this.fb.control('')]),
            ponencia: [false],
            ponenciaTexto: [""],
            comunicacion: [false],
            comunicacionTexto: [""],
            poster: [false],
            posterTexto: [""],
            articuloMagazine: [false],
            articuloMagazineTexto: [""],
            articuloReview: [false],
            articuloReviewTexto: [""],
            capituloLibro: [false],
            capituloLibroTexto: [""],
            otro: [false],
            otroTexto: [""],
            date: [new Date(), [Validators.required]]
        });
    }

    override async ngOnInit() {

        super.ngOnInit();
        this.openLoading();
        this.closeLoading();

        //BreadCrumb 
        this.breadCrumbItems = [
            { label: 'Informe Avance' },
            { label: 'Crear', active: true }
        ];    
        
        /*this.route.params.pipe(
            switchMap(params => {
                this.facultyId = params['id'];
    
                if (this.facultyId) {
                    return this.facultadService.getFacultad(this.facultyId);
                }
    
                // If there's no facultyId, just return an observable of null
                return of(null);
            })
        ).subscribe({
            next: (response) => {
                if (response && response) {
                    this.faculty = response;
                    if(this.faculty) {
                        this.formInformeAvance.patchValue({
                            nombre: this.faculty.name,
                            activo: this.faculty.is_active,
                            acronimo: this.faculty.acronym
                        });
                    }
                }
                this.breadCrumbItems = [
                    { label: 'Informe Avance' },
                    { label: 'Editar', active: true }
                  ];
                this.closeLoading();
            },
            error: (error) => {
                console.error('Error fetching facultad:', error);
                // Handle the error accordingly
                this.closeLoading();
            }
        });*/
        
    }

    get objetivosCumplidos() {
        return this.formInformeAvance.get('objetivosCumplidos') as FormArray;
    }
    
    addObjective() {
        this.objetivosCumplidos.push(this.fb.control(''));
    }

    get actividadesRestantes() {
        return this.formInformeAvance.get('actividadesRestantes') as FormArray;
    }
    
    addActivity() {
        this.actividadesRestantes.push(this.fb.control(''));
    }

    get cambiosObjetivos() {
        return this.formInformeAvance.get('cambiosObjetivos') as FormArray;
    }
    
    addObjectiveChange() {
        this.cambiosObjetivos.push(this.fb.group({
            objetivoOriginal: [''],
            objetivoNuevo: ['']
        }));
    }

    get resultadosObtenidos() {
        return this.formInformeAvance.get('resultadosObtenidos') as FormArray;
    }
    
    addResult() {
        this.resultadosObtenidos.push(this.fb.control(''));
    }

    get problemasSurgidos() {
        return this.formInformeAvance.get('problemasSurgidos') as FormArray;
    }
    
    addProblem() {
        this.problemasSurgidos.push(this.fb.control(''));
    }
    async save() {
        /*let datos = this.formInformeAvance.value;
        let request: FacultyRequest = {
            name: datos.nombre,
            is_active: datos.activo,
            acronym: datos.acronimo

        };
        this.openLoading();
        if (this.faculty) {
            let data = await this.facultadService.putFacultad(request, this.faculty.id);
            this.closeLoading();
            await this.globalSuccess(`El Informe de Avance ${data.name} ha sido actualizado correctamente`);
            this.router.navigateByUrl('informe-avance' );
        } else {
            let data = await this.facultadService.postFacultad(request);
            this.closeLoading();
            await this.globalSuccess(`El Informe de Avance ${data.name} ha sido creado correctamente`);
            this.router.navigateByUrl('informe-avance');
        }*/
    }

    cancel(): void {
        this.router.navigateByUrl('informe-avance');
    }

}
