import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { Role } from 'src/app/core/models/role.model';
import { RoleService } from 'src/app/core/services/role.service';

@Component({
  selector: 'app-roles-lista',
  templateUrl: './roles-lista.component.html',
  styleUrls: ['./roles-lista.component.scss']
})
export class RolesListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['name', 'permissionAmount', 'actions'];
  dataSource: MatTableDataSource<Role> = new MatTableDataSource<Role>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  roles: Role[] = [];

  constructor(private roleService: RoleService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getUsers();
  }

  async getUsers() {
    this.openLoading();
    this.roles = await this.roleService.getRoles();
    this.roles.map((cp) => { 
      cp.actions = [
        {
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/roles/editar/${cp.id}`
        },
        {
          name: 'Ver',
          icon: 'bx bx-search-alt',
          url: `/roles/detalle/${cp.id}`
        }
      ]
    });
    this.dataSource.data = this.roles;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  getPermissionAmount(role: Role){
    return role.siteSections?.length || 0;
  }

  doAction(accion: Action, id: number){
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/roles/crear');
  }

}
