import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { LanguageService } from '../../core/services/language.service';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs'; 
import { AuthStateService } from 'src/app/account/auth/auth.state.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;

  userDetails: any = {};

  constructor(@Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    private authStateService: AuthStateService,
    private authService: SocialAuthService
  ) { 
    this.authStateService.authState$.subscribe(authState => {
      this.userDetails.name = authState?.name
      this.userDetails.photoURL = authState?.photoURL
    });
  }


  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit(): void {

    this.element = document.documentElement;
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.signOut().then(() => {
      
    }).catch((error) => {
      console.error('Logout failed', error);
    }).finally(() => {
      this.authStateService.logout();
      this.router.navigate(['/account/login']);
    })
  }

}
