import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const auth = localStorage.getItem('unach-pi.ms-token');
        if (auth) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${auth}`
                }
            });
        }
        return next.handle(request).pipe( 
            catchError((error): Observable<any>  => {
                if (error.global !== 'E_LOGIN_REQUIRED') {
                    return new Observable<null>;
                }
                console.log("JWT Interceptor ERROR");
                this.router.navigateByUrl('account/login');
                return new Observable<null>;
            })
        );
    }
}
