import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { Role } from 'src/app/core/models/role.model';
import { IndirectCost } from 'src/app/core/models/indirect_cost.model';
import { IndirectCostService } from 'src/app/core/services/indirect-cost.service';

@Component({
  selector: 'app-costos-indirectos-lista',
  templateUrl: './costos-indirectos-lista.component.html',
  styleUrls: ['./costos-indirectos-lista.component.scss']
})
export class CostosIndirectosListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['name', 'faculty', 'isSigned', 'dateSigned', 'actions'];
  dataSource: MatTableDataSource<IndirectCost> = new MatTableDataSource<IndirectCost>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  indirectCosts: IndirectCost[] = [];

  constructor(private indirectCostService: IndirectCostService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getSupportLetters();
  }

  async getSupportLetters() {
    this.openLoading();
    this.indirectCosts = await this.indirectCostService.getIndirectCosts()
    this.indirectCosts.map((cp) => { 
      cp.actions = [
        {
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/costos-indirectos/editar/${cp.id}`
        },
        {
          name: 'Ver PDF',
          icon: 'bx bx-search-alt',
          url: `/api/indirectcost/pdf?id=${cp.id}`
        }
      ]
    });
    this.dataSource.data = this.indirectCosts;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  getPermissionAmount(role: Role){
    return role.siteSections?.length || 0;
  }

  doAction(accion: Action, id: number){
    if(accion.name === 'Ver PDF') window.open(accion.url as string, '_blank');
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/costos-indirectos/crear');
  }

}
