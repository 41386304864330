import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { Faculty, FacultyRequest } from 'src/app/core/models/faculty.model';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { of, switchMap, tap } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/core/models/user.model';


@Component({
    selector: 'app-form-facultad',
    templateUrl: './form-facultad.component.html',
    styleUrls: ['./form-facultad.component.scss']
})
export class FormFacultyComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    formFacultad!: FormGroup;

    faculty?: Faculty = undefined;
    facultyId: number = 0;

    users?: User[] = [];

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private facultadService: FacultadesService,
        private usuariosService: UserService,
        injector: Injector
    ) {
        super(injector);
        this.formFacultad = this.fb.group({
            nombre: [undefined, [Validators.required]],
            activo: [true],
            acronimo: [undefined, [Validators.required]],
            decano: [undefined, [Validators.required]]
        });
    }

    override async ngOnInit() {

        super.ngOnInit();
        this.openLoading();

        //BreadCrumb 
        this.breadCrumbItems = [
            { label: 'Facultad' },
            { label: 'Crear Facultad', active: true }
        ];
        
        this.users = await this.usuariosService.getUsers();
        
        this.route.params.pipe(
            switchMap(params => {
                this.facultyId = params['id'];
    
                if (this.facultyId) {
                    return this.facultadService.getFacultad(this.facultyId);
                }
    
                // If there's no facultyId, just return an observable of null
                return of(null);
            })
        ).subscribe({
            next: (response) => {
                if (response && response) {
                    this.faculty = response;
                    if(this.faculty) {
                        this.formFacultad.patchValue({
                            nombre: this.faculty.name,
                            activo: this.faculty.is_active,
                            acronimo: this.faculty.acronym,
                            decano: this.faculty.dean?.id
                        });
                    }
                }
                this.breadCrumbItems = [
                    { label: 'Facultad' },
                    { label: 'Editar Facultad', active: true }
                  ];
                this.closeLoading();
            },
            error: (error) => {
                console.error('Error fetching facultad:', error);
                // Handle the error accordingly
                this.closeLoading();
            }
        });
        
    }

    async save() {
        let datos = this.formFacultad.value;
        let request: FacultyRequest = {
            name: datos.nombre,
            is_active: datos.activo,
            acronym: datos.acronimo,
            dean: datos.decano
        };
        this.openLoading();
        if (this.faculty) {
            let data = await this.facultadService.putFacultad(request, this.faculty.id);
            this.closeLoading();
            await this.globalSuccess(`La facultad ${data.name} ha sido actualizada correctamente`);
            this.router.navigateByUrl('facultades' );
        } else {
            let data = await this.facultadService.postFacultad(request);
            this.closeLoading();
            await this.globalSuccess(`La facultad ${data.name} ha sido creada correctamente`);
            this.router.navigateByUrl('facultades');
        }
    }

    cancel(): void {
        this.router.navigateByUrl('facultades');
    }

}
