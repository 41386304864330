<!-- start page title -->
<app-pagetitle title="Evaluación Proyecto" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4>Detalle del Proyecto</h4>
                <div ngbAccordion #accordion="ngbAccordion" activeIds="config-panel-one" [closeOthers]="true">
                    <div class="card rounded mb-3"></div>
                    <div ngbAccordionItem="config-panel-one" id="config-panel-one">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>1. Identificación</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <div *ngIf="project">
                                        <h5>1. IDENTIFICACION DEL PROYECTO</h5>
                                        <div class="row mb-3">
                                            <div class="col-lg-3">
                                                <label>¿Corresponde a un TFC / TFP?</label><br>
                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" id="is_TFC" value="{{ project.is_TFC ? 'SI' : 'NO' }}" readonly>
                                                    <label for="is_TFC">¿Corresponde a un TFC / TFP?</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-3" *ngIf="project.type === 'PI'">
                                                <label>¿Proyecto con VCM?</label><br>
                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" id="with_VCM" value="{{ project.with_VCM ? 'SI' : 'NO' }}" readonly>
                                                    <label for="with_VCM">¿Proyecto con VCM?</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6" *ngIf="project.type === 'PI'">
                                                <label>Indique el tipo de proyecto que está postulando</label><br>
                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" id="projectType" value="{{ getProjectTypeLabel(project.type) }}" readonly>
                                                    <label for="projectType">Tipo de Proyecto</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-5" *ngIf="project.type === 'PCRAC'" style="text-align: center;">
                                                <label>¿Con qué se relaciona su proyecto?</label><br>
                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" id="projectRelation" value="{{ getProjectTypeLabel(project.type) }}" readonly>
                                                    <label for="projectRelation">Relación del Proyecto</label>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <h5 class="mt-5">1.1 Identifique la línea de investigación a la cual tributará su proyecto</h5>
                                        <div class="row mb-5">
                                            <div class="col-lg-12">
                                                <div class="list-group">
                                                    <label class="list-group-item" *ngFor="let line of project.investigative_lines?.split(',')">
                                                        {{line}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" id="keywords" name="keywords" value="{{ project.keywords }}" readonly>
                                                    <label for="keywords">Palabras Clave <small>(3 a 5 descriptores temáticos relevantes)</small></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                                  
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-two">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>2. Descripción</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <div *ngIf="project">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="resumen"><h5>2.1 Resumen</h5></label>
                                                    <div [innerHTML]="project.pi_summary"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_introduction"><h5>2.2 Introducción</h5></label>
                                                    <div [innerHTML]="project.pi_introduction"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_objectives"><h5>2.3 Objetivos</h5></label>
                                                    <div *ngIf="project.pi_objectives && project.pi_objectives.length > 0">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Objetivo</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let objective of project.pi_objectives; let i = index">
                                                                    <td>{{ i + 1 }}</td>
                                                                    <td>
                                                                        <div>{{ objective.name }}</div>
                                                                        <div [innerHTML]="objective.text"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_methodology"><h5>2.4 Metodología</h5></label>
                                                    <div [innerHTML]="project.pi_methodology"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-three">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>3. Impacto</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                    <div *ngIf="project">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_contribution_science"><h5>3.1 Contribución al avance del Conocimiento Científico</h5></label>
                                                    <div [innerHTML]="project.pi_contribution_science"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_aplicability_results"><h5>3.2 Aplicabilidad de los Resultados</h5></label>
                                                    <div [innerHTML]="project.pi_aplicability_results"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_transfer_plan"><h5>3.3 Transferencia de Resultados</h5></label>
                                                    <div [innerHTML]="project.pi_transfer_plan"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_teaching_support"><h5>3.4 Apoyo a la Docencia</h5></label>
                                                    <div [innerHTML]="project.pi_teaching_support"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-four">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>4. Publicación</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                    <div *ngIf="project">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_expositions"><h5>4.1 Publicación (Artículos de Revista, Capítulos de Libros y Libros)</h5></label>
                                                    <div [innerHTML]="project.pi_expositions"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="pi_publications"><h5>4.2 Exposición (Ponencias, Pósteres)</h5></label>
                                                    <div [innerHTML]="project.pi_publications"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-five">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>5. Ejecución</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                    <div *ngIf="project">
                                        <h5>5. EJECUCIÓN DEL PROYECTO</h5>
                                        <h5>5.1 Estimación de la duración del Proyecto</h5>
                                        <div class="row mb-5">
                                            <div class="col-lg-12">
                                                <div class="form-floating mb-3">
                                                    <input type="number" class="form-control" id="duration" [value]="project.estimated_duration_months" readonly>
                                                    <label for="duration">Duración (En meses)</label>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Detallar la ejecución del Proyecto en el cronograma o plan de tareas</h5>
                                    
                                        <div class="row mb-5">
                                            <div class="col-lg-12">
                                                <h5>Actividades</h5>
                                                <ul>
                                                    <li *ngFor="let item of project.activities">
                                                        <b>{{ item.name }}</b> ({{ item.start_date | date: 'DD/MM/yyyy' }} - {{ item.end_date | date: 'DD/MM/yyyy' }})
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    
                                        <h5>5.2 Estimación del tiempo necesario para realizar la investigación</h5>
                                        <small>*Tiempo completo, medio tiempo, honorarios, no contratado (estudiantes, investigadores de otras universidades…).<br>
                                            **Las horas de investigación son horas cronológicas, no son horas académicas.</small>
                                        <div class="row mb-5">
                                            <div class="col-lg-12">
                                                <div class="table-responsive">
                                                    <table class="table mb-0 small-padding-table">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th rowspan="3" style="width: 150px;">Investigador / Estudiante</th>
                                                                <th rowspan="3" style="width: 100px;">Facultad / Unidad</th>
                                                                <th rowspan="3" style="width: 100px;">Tipo de Contrato</th>
                                                                <th rowspan="3" style="width: 100px;">Grado</th>
                                                                <th [attr.colspan]="semesters.length + 2">N° horas cronológicas* asignadas para el Proyecto**</th>
                                                            </tr>
                                                            <tr>
                                                                <th [attr.colspan]="semesters.length">N° horas</th>
                                                                <th rowspan="2" class="week_number">N° semanas</th>
                                                                <th rowspan="2" class="total_hours">N° horas totales</th>
                                                            </tr>
                                                            <tr>
                                                                <th *ngFor="let sem of semesters" class="semester_hours">
                                                                    {{ sem.name }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngFor="let pe of project.people_estimation; let i = index">
                                                            <tr>
                                                                <td>{{ pe.rut }}</td>
                                                                <td>{{ pe.faculty!.acronym }}</td>
                                                                <td>{{ getContractTypeName(pe.contract_type) }}</td>
                                                                <td>{{ getGradeName(pe.grade) }}</td>
                                                                <td *ngFor="let hour of pe.hours">{{ hour.hours }}</td>
                                                                <td>{{ pe.weeks }}</td>
                                                                <td>{{ getPersonTotalHours(pe) }}h</td>
                                                            </tr>
                                                            <tr style="border-bottom: 1px dashed darkgray;">
                                                                <td colspan="3">{{ pe.name }}</td>
                                                                <td [attr.colspan]="semesters.length + 3"></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td [attr.colspan]="semesters.length + 6" style="text-align: right;">TOTAL</td>
                                                                <td>{{ getTotalHours() }}h</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <h5>5.3 Otras iniciativas o Proyectos que el Investigador (es) contratado de la UnACh haya postulado o esté ejecutando</h5>
                                        <div class="row mb-5">
                                            <div class="col-lg-12">
                                                <div class="table-responsive">
                                                    <table class="table mb-0">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th>Investigador contratado de la UnACh</th>
                                                                <th>Tiempo Adjudicado o solicitado en otros proyectos (horas semanales)</th>
                                                            </tr>
                                                        </thead>
                                                        <!--tbody>
                                                            <tr *ngIf="!project.other_projects || project.other_projects.length === 0">
                                                                <td>No se registran otras Actividades</td>
                                                            </tr>
                                                            <tr *ngFor="let otherProject of project.other_projects">
                                                                <td>
                                                                    {{ otherProject.academic.name }}<br>
                                                                    <ul>
                                                                        <li>Titulo de la Investigación: {{ otherProject.title }}</li>
                                                                        <li>Entidad Patrocinante: {{ otherProject.sponsoring_entity }}</li>
                                                                        <li>Estado: {{ otherProject.status }}</li>
                                                                    </ul>
                                                                </td>
                                                                <td>{{ otherProject.hours_per_week }}h</td>
                                                            </tr>
                                                        </tbody-->
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <h5>Actividades por Investigador</h5>
                                        <div class="row mb-5">
                                            <div class="col-lg-12">
                                                <div class="table-responsive">
                                                    <table class="table mb-0">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th>Investigador</th>
                                                                <th>Actividades</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let person of otherAcademics">
                                                                <td>{{ person.rut }}</td>
                                                                <td>
                                                                    <ul>
                                                                        <li *ngFor="let activity of person.activities">
                                                                            {{ getObjectiveName(activity.objective) }} ({{ activity.start_date | date: 'MM/yyyy' }} - {{ activity.end_date | date: 'MM/yyyy' }})
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-six">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>6. Presupuesto</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <app-rubrica-respuesta [rubric]="rubric" [peerEvaluations]="peerEvaluations"></app-rubrica-respuesta>
            </div>

            <div class="card-body" *ngIf="workload">
                <h4 class="mt-5">RECOMENDACIÓN CARGA HORARIA</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th style="width: 40%;">INVESTIGADOR</th>
                                <th style="width: 20%;" class="table-warning">Solicitado</th>
                                <th style="width: 20%;" class="table-success">Recomendación Dirección Investigación</th>
                                <th style="width: 20%;">Horas en otra Investigación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of workload.investigadores">
                                <th>{{item.name}}</th>
                                <th class="table-warning" style="text-align: center;">{{item.requested_hours}}</th>
                                <th class="table-success">
                                    <input type="number" class="form-control" [(ngModel)]="item.recomended_hours">
                                </th>
                                <th style="text-align: center;">{{item.other_hours > 0 ? item.other_hours : '-'}}</th>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Duración (semanas)</th>
                                <th class="table-warning" style="text-align: center;">{{workload.requested_duration}}</th>
                                <th class="table-success" style="text-align: center;">{{workload.recomended_duration}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Total Horas</th>
                                <th class="table-warning" style="text-align: center;">{{getTotalHoraSolicitado() | number}}</th>
                                <th class="table-success" style="text-align: center;">{{getTotalHoraRecomendado() | number}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Presupuesto Operativo</th>
                                <th class="table-warning" style="text-align: center;">{{workload.requested_budget | number}}</th>
                                <th class="table-success" style="text-align: center;">
                                    <input type="number" class="form-control" [(ngModel)]="workload.recomended_budget">
                                </th>
                                <th></th>
                            </tr>
                            <tr>
                                <th style="color: red; font-weight: bold;">Total Horas x ${{workload.hourly_rate | number}}/hora</th>
                                <th class="table-warning" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraSolicitado()*workload.hourly_rate | number}}</th>
                                <th class="table-success" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraRecomendado()*workload.hourly_rate | number}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th style="color: red; font-weight: bold;">Presupuesto Total II</th>
                                <th class="table-warning" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraSolicitado()*workload.hourly_rate + workload.requested_budget | number}}</th>
                                <th class="table-success" style="text-align: center; color: red; font-weight: bold;">{{getTotalHoraRecomendado()*workload.hourly_rate + workload.recomended_budget  | number}}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                    <button type="button" class="btn btn-light" (click)="cancel()">
                        <i class="bx bxs-x-square"></i> Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" (click)="save()">
                        <i class="bx bx-save"></i> Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end row -->
