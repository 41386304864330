import { Component, Input, TemplateRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ProyectoWizardComponent } from '../proyecto-wizard/proyecto-wizard.component';
import { GanttItem, GanttViewOptions, GanttViewType } from '@worktile/gantt';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectWizardService } from 'src/app/core/services/project-wizard.service';
import { Faculty } from 'src/app/core/models/faculty.model';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { Objective } from 'src/app/core/models/objective.model';
import { Subscription } from 'rxjs';
import { flatpickrFactory } from '../../../pages.module';
import * as moment from 'moment';

export interface GanttActivity{
  id: string;
  name: string;
  start: Date;
  end: Date;
}

export interface Semester{
  name: string;
  year: number;
  semester: number;
}

export interface PeopleEstimation{
  name: string;
  rut: string;
  faculty?: number;
  contract_type?: number;
  grade: number;
  weekly_hours: number[];
  weeks: number;
}

export interface ContractType{
  id: number;
  name: string;
}

export interface AcademicGrades{
  id: number;
  name: string;
}

export interface ActivitiesByAcademics{
  academic: {
    name: string;
    rut: string;
  },
  objective: Objective,
  start_date: Date,
  end_date: Date
}



@Component({
  selector: 'app-proyecto-step5',
  templateUrl: './proyecto-step5.component.html',
  styleUrls: ['./proyecto-step5.component.scss']
})
export class ProyectoStep5Component{

  formStep: FormGroup;
  @Input() parentComponent!: ProyectoWizardComponent;

  minimo?: Date;
  maximo?: Date;
  items: GanttItem[] = [];
  currentActivity!: GanttActivity;
  currentItemID?: string;

  contractTypes: ContractType[] = [];
  gradeTypes: AcademicGrades[] = [];
  faculties: Faculty[] = [];
  minDuration: number = 1;
  maxDuration: number = 12;

  projectObjectives: Objective[] = [];

  private nameChangeSubscriptions: Subscription[] = [];
  private rutChangeSubscriptions: Subscription[] = [];

  views = [
    { name: 'Diario', value: GanttViewType.day },
    { name: 'Semanal', value: GanttViewType.week },
    { name: 'Mensual', value: GanttViewType.month },
    { name: 'Anual', value: GanttViewType.year }
  ];
  viewType: GanttViewType = GanttViewType.day;

  viewOptions: GanttViewOptions = {
      dateFormat: {
          week: 'w',
          month: 'MM',
          quarter: 'Q',
          year: 'yyyy',
          yearMonth: 'MM/yyyy',
          yearQuarter: 'yyyy'
      }
  };

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private projectWizardService: ProjectWizardService,
    private facultiesService: FacultadesService,
  ) { 
    this.formStep = this.fb.group({
      estimated_duration_months: ['12'],
      activities: this.fb.array([]),
      people_estimation: this.fb.array([]),
      other_academics: this.fb.array([]),
      semesters: this.fb.array([])
    });

  }

  async ngOnInit() {
    flatpickrFactory();
    // Load existing data if available
    this.projectWizardService.getProjectData().subscribe(data => {
      this.formStep.patchValue(data);
      this.projectObjectives = data.pi_objectives || [];

      if(data.type == 'PI' && (data.is_regular || data.is_technology_transfer)){
        this.maxDuration = 24;
      }

      if(data.estimated_duration_months == 0){
        //No tiene valor, proponer los estándar
        this.formStep.get('estimated_duration_months')!.setValue(this.maxDuration, {emitEvent: false});
      }

      if(data.activities){
        for(let activity of data.activities){
          this.items.push({
            id: this.getNewID(),
            title: activity.name,
            start: this.toTimestamp(new Date(activity.start_date)),
            end: this.toTimestamp(new Date(activity.end_date)),
          });
        }
      }

      if(data.people_estimation){
        this.peopleEstimations.clear();
        this.otherAcademics.clear();
        for(let estimation of data.people_estimation){

          const hours = estimation.hours || estimation.weekly_hours || [];

          const newPerson = this.createPeopleEstimationFormGroup({
            name: estimation.name,
            rut: estimation.rut,
            faculty: estimation.faculty?.id,
            contract_type: estimation.contract_type!,
            grade: parseInt(estimation.grade!),
            weekly_hours: hours.map(hour => hour.hours) || [],
            weeks: estimation.weeks || 0
          });

          this.peopleEstimations.push(newPerson);
          this.otherAcademics.push(this.createAcademicActivitiesFormGroup(newPerson));
          this.setupNameChangeListener(newPerson, this.peopleEstimations.length - 1);
        }
      }

      if(data.other_academics){
        //get the correct academic, using the rut field
        for(let academic of data.other_academics){
          this.insertActivity(academic.rut, academic.objective, academic.start_date, academic.end_date);
        }

      }

    });

    this.faculties = await this.facultiesService.getTodasFacultadesActivas();
    //Get Contract Types
    this.contractTypes = [
      {id: 1, name: 'CONTRATO'},
      {id: 2, name: 'HONORARIOS'},
      {id: 3, name: 'ESTUDIANTE'},
    ]

    //Get Contract Types
    this.gradeTypes = [
      {id: 1, name: 'N/A'},
      {id: 2, name: 'TSU'},
      {id: 3, name: 'LIC'},
      {id: 4, name: 'MAG'},
      {id: 5, name: 'DR'},
    ]

    this.peopleEstimations.controls.forEach((control, index) => {
      this.setupNameChangeListener(control, index);
    });
  }

  insertActivity(rut: string, objective: string, startDate: Date, endDate: Date) {
    // Find the index of the academic using their rut
    const academicIndex = this.otherAcademics.controls.findIndex(academicControl => 
      academicControl.get('academic')!.get('rut')!.value === rut
    );
  
    // If academic with the specified rut is found
    if (academicIndex !== -1) {
      // Get the activities FormArray for the specific academic
      const activitiesArray = this.academicActivities(academicIndex);
  
      console.log("inserting activity for academic", rut, objective, startDate, endDate);

      // Create a new activity FormGroup
      const newActivity = this.fb.group({
        objective: [objective],
        start_date: [moment(startDate).format('MM/YYYY')],
        end_date: [moment(endDate).format('MM/YYYY')],
      });
  
      // Push the new activity into the activities FormArray
      activitiesArray.push(newActivity);
    } else {
      console.error(`Academic with RUT ${rut} not found.`);
    }
  }
  

  getContractTypeById(id: number){
    return this.contractTypes.find(type => type.id == id);
  }

  getGradeByValue(value: string){
    return this.gradeTypes.find(type => type.name == value);
  }

  getGradeById(id: string){
    const grade = this.gradeTypes.find(type => type.name == id);
    return grade?.name || 'N/A';
  }

  setupNameChangeListener(control: AbstractControl, index: number) {
    // Listen to name field changes
    const subscription = control.get('name')!.valueChanges.subscribe(newName => {
      // Update the corresponding academic's name in other_academics
      const academicControl = (this.otherAcademics.at(index) as FormGroup).get('academic') as FormGroup;
      academicControl.get('name')!.setValue(newName, { emitEvent: false }); // Prevent triggering further events
    });
    this.nameChangeSubscriptions[index] = subscription;

    const subscriptionRut = control.get('rut')!.valueChanges.subscribe(newRut => {
      // Update the corresponding academic's name in other_academics
      const academicControl = (this.otherAcademics.at(index) as FormGroup).get('academic') as FormGroup;
      academicControl.get('rut')!.setValue(newRut, { emitEvent: false }); // Prevent triggering further events
    });
    this.rutChangeSubscriptions[index] = subscriptionRut;
  }

  get semesters(): Semester[] {
    //Todo: Get starting semester:
    let currentSemester = 2;
    let currentYear = 2024;
    let current_months = 0;

    const semesters: Semester[] = [];

    while(current_months < parseInt(this.formStep.value.estimated_duration_months)){
      // Get current semester
      semesters.push({
        name: `${currentYear} - ${currentSemester.toString().padStart(2, '0')}`,
        year: currentYear,
        semester: currentSemester
      });
      //advance to next semester
      currentSemester++;
      if(currentSemester > 2){
        currentSemester = 1;
        currentYear++;
      }
      current_months += 6;
    }

    return semesters;
  }

  get peopleEstimations(): FormArray {
    return this.formStep.get('people_estimation') as FormArray;
  }

  get otherAcademics(): FormArray {
    return this.formStep.get('other_academics') as FormArray;
  }

  academicActivities(otherAcademicIndex: number): FormArray {
    return (this.otherAcademics.at(otherAcademicIndex) as FormGroup).get('activities') as FormArray;
  }

  createPeopleEstimationFormGroup(pe: PeopleEstimation): FormGroup {
    return this.fb.group({
      name: [pe.name],
      rut: [pe.rut],
      faculty: [pe.faculty],
      contract_type: [pe.contract_type],
      grade: [pe.grade],
      weekly_hours: this.fb.array(pe.weekly_hours.map(hour => this.fb.control(hour))),
      weeks: [pe.weeks]
    });
  }

  createAcademicActivitiesFormGroup(person: FormGroup): FormGroup {
    return this.fb.group({
      academic: this.fb.group({
        name: person.value.name,
        rut: person.value.rut,
      }),
      activities: this.fb.array([]),
      
    });
  }

  createActivityFormGroup(): FormGroup {
    return this.fb.group({
      objective: [undefined], // Initialize as needed
      start_date: [undefined], // Initialize as needed
      end_date: [undefined], // Initialize as needed
    });
  }

  addPeopleEstimation() {
    const weeklyHours = this.semesters.map(() => 0); // Create an array of zeros
    const newPerson = this.createPeopleEstimationFormGroup(
      {
        name: '', 
        rut: '', 
        faculty: undefined,
        contract_type: undefined,
        grade: 0, 
        weekly_hours: weeklyHours,
        weeks: 0
      }
    )
    this.peopleEstimations.push(newPerson);
    this.otherAcademics.push(this.createAcademicActivitiesFormGroup(newPerson));
    this.setupNameChangeListener(newPerson, this.peopleEstimations.length - 1);
  }

  addPersonActivity(academicIndex: number) {
    const activitiesArray = this.academicActivities(academicIndex);

    const newActivity = this.createActivityFormGroup();
     activitiesArray.push(newActivity);
  }

  removePerson(index: number): void {
    this.nameChangeSubscriptions[index].unsubscribe();
    this.rutChangeSubscriptions[index].unsubscribe();

    this.peopleEstimations.removeAt(index);
    this.otherAcademics.removeAt(index);

    // Re-setup the listeners for the remaining peopleEstimations
    this.nameChangeSubscriptions.splice(index, 1); // Remove the subscription from the array
    this.rutChangeSubscriptions.splice(index, 1); // Remove the subscription from the array
    this.peopleEstimations.controls.forEach((control, i) => {
      // If you are already listening, unsubscribe first
      if (this.nameChangeSubscriptions[i]) {
        this.nameChangeSubscriptions[i].unsubscribe();
      }
      if (this.rutChangeSubscriptions[i]) {
        this.rutChangeSubscriptions[i].unsubscribe();
      }
      this.setupNameChangeListener(control, i);
    });
  }

  removeAcademicActivity(academicIndex: number, activityIndex: number): void {
    const activitiesArray = this.academicActivities(academicIndex);
    activitiesArray.removeAt(activityIndex);
  }

  getWeeklyHoursControls(index: number): number[] {
    const weeklyHoursArray = this.peopleEstimations.at(index).get('weekly_hours') as FormArray;
    return Array.from({ length: weeklyHoursArray.length }, (_, i) => i);
  }

  getPersonTotalHours(index: number): number {
    const personEstimation = this.peopleEstimations.at(index) as FormGroup;
    const weeklyHoursArray = personEstimation.get('weekly_hours') as FormArray;
    const weeks = personEstimation.get('weeks')!.value || 0;
    const totalHours = weeklyHoursArray.controls
      .map(control => control.value || 0)
      .reduce((acc, hours) => acc + hours, 0) * weeks;
    return totalHours;
  }

  getTotalHours(): number {
    let total = 0;
    for (let i = 0; i < this.peopleEstimations.length; i++) {
      total += this.getPersonTotalHours(i);
    }
    return total;
  }

  setMinimo(e: any) {
    //this.minimo = this.form.value.start;
  }
  setMaximo(e: any) {
      //this.maximo = this.form.value.end;
  }

  toTimestamp(date: Date): number {
    return Math.floor(date.getTime() / 1000)
  }

  async addItem() {
    this.currentActivity.id = this.getNewID();

    delete this.minimo;
    delete this.maximo;

    this.items = this.items.concat({
      id: this.currentActivity.id,
      title: this.currentActivity.name,
      start: this.toTimestamp(this.currentActivity.start),
      end: this.toTimestamp(this.currentActivity.end),
      origin: this.currentActivity,
      expandable: true
    });

    this.modalService.dismissAll();
  }

  async deleteItem(item: GanttItem) {
    let resp = await Swal.fire({
      title: "Confirmación", 
      text: "¿Está seguro que desea eliminar la Actividad?",
      icon: 'warning',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'No, cancelar',
      showCancelButton: false
    });
    if(resp.isConfirmed){
        this.items = this.items.filter(value => value.id !== item.id);

        this.currentItemID = undefined;
    }
  }

  getNewID() {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  newItem(activityModal: any){
      this.clearActivity();
      this.currentItemID = undefined;
      this.modalService.open(activityModal, { size: 'md', windowClass: 'modal-holder', centered: true });
  }

  clearActivity() {
    this.currentActivity = {
      id: '',
      name: '',
      start: new Date(),
      end: new Date()
    }
  }

  // Method to map a GanttItem to a FormGroup
  createActivityFormGroupFromGanttItem(item: GanttItem): FormGroup {
    return this.fb.group({
      id: [item.id],
      name: [item.title],
      start: [new Date(item.start! * 1000)], // Convert from timestamp
      end: [new Date(item.end! * 1000)], // Convert from timestamp
    });
  }

  createSemesterFormGroup(semester: Semester): FormGroup {
    return this.fb.group({
      year: [semester.year],
      semester: [semester.semester]
    });
  }

  prepareActivities() {
    const activitiesArray = this.formStep.get('activities') as FormArray;
    activitiesArray.clear(); // Clear existing activities

    this.items.forEach(item => {
      const activityGroup = this.createActivityFormGroupFromGanttItem(item);
      activitiesArray.push(activityGroup);
    });

    //add semesters to form
    const semestersArray = this.formStep.get('semesters') as FormArray;
    semestersArray.clear();

    for(let s of this.semesters){
      semestersArray.push(this.createSemesterFormGroup(s));
    }
  }

  nextStep(): void {
    this.prepareActivities();
    this.parentComponent.nextStep();
  }

  previousStep(): void {
    this.prepareActivities();
    this.parentComponent.previousStep();
  }

  save(): void{
    this.prepareActivities();
    this.parentComponent.updateData(this.formStep.value);
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions on component destruction
    this.nameChangeSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.rutChangeSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
