<!-- start page title -->
<app-pagetitle title="Inventario de Equipos Proyecto de Investigación" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formInformeAvance" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h3>1. Identificación del Proyecto</h3>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="folio" value="2024-FAIN-0001" readonly>
                                <label for="folio">Folio</label>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="proyecto" value="Proyecto Demo 1" readonly>
                                <label for="proyecto">Título Proyecto</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="academic" value="Investigador Responsable" readonly>
                                <label for="academic">Investigador Responsable</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email_academic" value="av@magnussoluciones.cl" readonly>
                                <label for="email_academic">Correo Electrónico</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="phone_academic" value="+56 9 3187 6833" readonly>
                                <label for="phone_academic">Teléfono UnACh</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="coinvestigadores" value="Coinvestigador 1, Coinvestigador 2" readonly>
                                <label for="coinvestigadores">Coinvestigadores</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="faculty" value="FAIN" readonly>
                                <label for="faculty">Facultad / Unidad</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="career" value="INGENIERIA CIVIL INFORMATICA" readonly>
                                <label for="career">Carrera</label>
                            </div>
                        </div>
                        <div class="col col-lg-4">
                            <div class="mb-3">
                                <label for="name">Fecha Informe</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="date">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h3>2. Estado Actual de la Investigación</h3>
                    <div class="row">
                        <div class="col-12">
                            <h5>Objetivos: Enumere brevemente los objetivos cumplidos a la fecha</h5>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="objetivosCumplidos">
                                    <thead>
                                        <tr>
                                            <td style="text-align: right;" colspan="2">
                                                <button class="btn btn-primary add-btn" (click)="addObjective()"><i class="bx bx-plus"></i> Agregar Objetivo Cumplido</button>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let objetivo of objetivosCumplidos.controls; let i = index">
                                            <td style="vertical-align: middle; width: 20px;"><b>{{i+1}}.-</b></td>
                                            <td>
                                                <div class="form-floating">
                                                    <input type="number" class="form-control" id="objetivo{{i}}" placeholder="Indique Objetivo Cumplido" [formControlName]="i">
                                                    <label for="objetivo{{i}}">Objetivo Cumplido</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12">
                            <h5>Indique lo que resta realizar para culminar el proyecto</h5>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="actividadesRestantes">
                                    <thead>
                                        <tr>
                                            <td style="text-align: right;" colspan="2">
                                                <button class="btn btn-primary add-btn" (click)="addActivity()"><i class="bx bx-plus"></i> Agregar Actividad Restante</button>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let actividad of actividadesRestantes.controls; let i = index">
                                            <td style="vertical-align: middle; width: 20px;"><b>{{i+1}}.-</b></td>
                                            <td>
                                                <div class="form-floating">
                                                    <input type="number" class="form-control" id="objetivo" placeholder="Actividad Restante" [formControlName]="i">
                                                    <label for="actividad">Actividad</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12">
                            <h5>Si sus objetivos han cambiado, indicarlos a continuación</h5>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="cambiosObjetivos">
                                    <thead>
                                        <tr>
                                            <td style="text-align: right;" colspan="3">
                                                <button class="btn btn-primary add-btn" (click)="addObjectiveChange()"><i class="bx bx-plus"></i> Agregar Actividad Restante</button>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cambio of cambiosObjetivos.controls; let i = index" [formGroupName]="i">
                                            <td style="vertical-align: middle; width: 20px;"><b>{{i+1}}.-</b></td>
                                            <td>
                                                <div class="form-floating">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example" formControlName="objetivoOriginal">
                                                        <option value="">Eliga un Objetivo</option>
                                                        <option value="1">Objetivo 1</option>
                                                        <option value="2">Objetivo 2</option>
                                                        <option value="3">Objetivo 3</option>
                                                    </select>
                                                    <label for="floatingSelect">Objetivo Original</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-floating">
                                                    <input type="number" class="form-control" id="objetivo_nuevo" placeholder="Objetivo Nuevo" formControlName="objetivoNuevo">
                                                    <label for="objetivo_nuevo">Objetivo Nuevo</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h3>3. Resultados Obtenidos</h3>
                    <div class="row">
                        <div class="col-12">
                            <h5>Describa los resultados parciales obtenidos a la fecha</h5>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="resultadosObtenidos">
                                    <thead>
                                        <tr>
                                            <td style="text-align: right;" colspan="2">
                                                <button class="btn btn-primary add-btn" (click)="addResult()"><i class="bx bx-plus"></i> Agregar Resultado Obtenido</button>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let resultado of resultadosObtenidos.controls; let i = index">
                                            <td style="vertical-align: middle; width: 20px;"><b>{{i+1}}.-</b></td>
                                            <td>
                                                <div class="form-floating">
                                                    <input type="number" class="form-control" id="resultado" [formControlName]="i">
                                                    <label for="resultado">Resultado Obtenido</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h3>4. Problemas en el desarrollo del proyecto</h3>
                    <div class="row">
                        <div class="col-12">
                            <h5>Describa los problemas que hayan surgido a la fecha en el desarrollo de la investigación</h5>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="problemasSurgidos">
                                    <thead>
                                        <tr>
                                            <td style="text-align: right;" colspan="2">
                                                <button class="btn btn-primary add-btn" (click)="addProblem()"><i class="bx bx-plus"></i> Agregar Problema Surgido</button>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let problema of problemasSurgidos.controls; let i = index">
                                            <td style="vertical-align: middle; width: 20px;"><b>{{i+1}}.-</b></td>
                                            <td>
                                                <div class="form-floating">
                                                    <input type="number" class="form-control" id="problema" [formControlName]="i">
                                                    <label for="problema">Problema</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h3>5. Publicación o presentación</h3>
                    <div class="row">
                        <div class="col-12">
                            <h5>Indicar si ha realizado hasta ahora alguna presentación en conferencia, seminario u otro evento o si se ha publicado algún artículo o capítulo de un libro con los resultados parciales </h5>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <tbody>
                                        <tr>
                                            <td style="width: 90%;">Ponencia</td>
                                            <td style="width: 10%;"><input type="checkbox" class="large-checkbox" formControlName="ponencia"></td>
                                        </tr>
                                        <tr *ngIf="formInformeAvance.get('ponencia')?.value">
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="ponenciaTexto" placeholder="Descripción de la ponencia">
                                            </td>
                                        </tr>
                                
                                        <tr>
                                            <td>Comunicación</td>
                                            <td><input type="checkbox" class="large-checkbox" formControlName="comunicacion"></td>
                                        </tr>
                                        <tr *ngIf="formInformeAvance.get('comunicacion')?.value">
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="comunicacionTexto" placeholder="Descripción de la comunicación">
                                            </td>
                                        </tr>
                                
                                        <tr>
                                            <td>Póster</td>
                                            <td><input type="checkbox" class="large-checkbox" formControlName="poster"></td>
                                        </tr>
                                        <tr *ngIf="formInformeAvance.get('poster')?.value">
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="posterTexto" placeholder="Descripción del poster">
                                            </td>
                                        </tr>
                                
                                        <tr>
                                            <td>Artículo en revista de divulgación (magazine)</td>
                                            <td><input type="checkbox" class="large-checkbox" formControlName="articuloMagazine"></td>
                                        </tr>
                                        <tr *ngIf="formInformeAvance.get('articuloMagazine')?.value">
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="articuloMagazineTexto" placeholder="Descripción del artículo en revista">
                                            </td>
                                        </tr>
                                
                                        <tr>
                                            <td>Artículo en revista académica o profesional (review)</td>
                                            <td><input type="checkbox" class="large-checkbox" formControlName="articuloReview"></td>
                                        </tr>
                                        <tr *ngIf="formInformeAvance.get('articuloReview')?.value">
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="articuloReviewTexto" placeholder="Descripción del artículo en revista">
                                            </td>
                                        </tr>
                                
                                        <tr>
                                            <td>Capítulo de un libro</td>
                                            <td><input type="checkbox" class="large-checkbox" formControlName="capituloLibro"></td>
                                        </tr>
                                        <tr *ngIf="formInformeAvance.get('capituloLibro')?.value">
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="capituloLibroTexto" placeholder="Descripción del capítulo de libro">
                                            </td>
                                        </tr>
                                
                                        <tr>
                                            <td>Otro (especificar)</td>
                                            <td><input type="checkbox" class="large-checkbox" formControlName="otro"></td>
                                        </tr>
                                        <tr *ngIf="formInformeAvance.get('otro')?.value">
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="otroTexto" placeholder="Descripción de otro resultado">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
