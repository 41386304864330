<!-- start page title -->
<app-pagetitle title="Formulario Adjuntos Boleta Honorarios" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formSolicitud" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
                    <div class="row justify-content-end">
                        <div class="col col-lg-4">
                            <div class="mb-3">
                                <label for="name">Fecha</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="date">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="name" readonly>
                                <label for="name">Nombre Investigador</label>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="motivo" formControlName="motivo" readonly>
                                <label for="motivo">Motivo</label>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="description">Descripción Solicitud</label>
                                <textarea class="form-control" id="description" name="type" rows="3" formControlName="description" readonly></textarea>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="amount" placeholder="40000" formControlName="amount" readonly>
                                <label for="amount">Monto Boleta</label>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="type" formControlName="type">
                                <label for="type">Tipo Gasto (Viaje o Viático)</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="destiny" formControlName="destiny">
                                <label for="destiny">Destino (Solo en caso de viaje)</label>
                            </div>
                        </div>

                        <div class="col col-lg-6">
                            <div class="mb-3">
                                <label for="name">Fecha Inicio</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="date">
                            </div>
                        </div>
                        <div class="col col-lg-6">
                            <div class="mb-3">
                                <label for="name">Fecha Término</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="date">
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="motivoActividad">Motivo Principal de las actividades desarrolladas o del viaje</label>
                                <textarea class="form-control" id="motivoActividad" name="type" rows="3" formControlName="motivoActividad"></textarea>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="descripcionActividad">Descripción de las principales actividades realizadas</label>
                                <textarea class="form-control" id="descripcionActividad" name="type" rows="3" formControlName="descripcionActividad"></textarea>
                            </div>
                        </div>

                        <ngx-dropzone (change)="onSelect($event)" class="mt-4 mb-4">
                            <ngx-dropzone-label><i class="display-4 text-muted bx bxs-cloud-upload"></i>
                              <h4>Adjunte Documentación y Boleta.</h4>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>

                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
