import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { PendingService } from "../../core/services/pending.service";
import { Pending } from "../../core/models/utils.model";
import { Rubric, RubricApplication } from "../../core/models/rubrica.model";
import { RubricService } from "../../core/services/rubric.service";
import { WorkloadService } from "../../core/services/workload.service";
import { WorkloadInvestigatorRequest, WorkloadRequest, WorkloadResponse } from "../../core/models/workload.model";
import { Project } from 'src/app/core/models/project.model';
import { OtherAcademicsView } from '../proyecto/proyecto-detalle/proyecto-detalle.component';
import { CommentEvaluation } from 'src/app/core/models/comment-evaluation.model';
import { of, switchMap } from 'rxjs';
import { RubricAplicationService } from 'src/app/core/services/rubric-aplication.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { Faculty } from 'src/app/core/models/faculty.model';
import { PersonEstimation } from 'src/app/core/models/person_estimation.model';


export interface Semester{
    name: string;
    year: number;
    semester: number;
}

@Component({
    selector: 'app-evaluacion-dirinves',
    templateUrl: './evaluacion-dirinves.component.html',
    styleUrls: ['./evaluacion-dirinves.component.scss']
})

export class DirectiveReviewComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;

    rubric?: Rubric;

    idDirinves!: string;

    rubricApplication?: RubricApplication;

    idRubricAplication!: number;
    project?: Project;


    semesters: Semester[] = [];

    otherAcademics: OtherAcademicsView[] = [];

    workload!: WorkloadRequest;

    commentEvaluation!: CommentEvaluation;
    peerEvaluations: RubricApplication[] = [];

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private workloadService: WorkloadService,
        private rubricService: RubricService,
        private pendingService: PendingService,
        private rubricApplicationService: RubricAplicationService,
        private projectService: ProjectService,
        injector: Injector
    ) {
        super(injector);
    }

    override async ngOnInit() {
        super.ngOnInit();

        //this.rubric = await this.rubricService.getRubric(2);
        //this.workload = await this.workloadService.getWorkload(1);

        //this.commentEvaluation = await this.commentEvaluationService.getCommentEvaluation(1);
        this.commentEvaluation = {
            investigador: '',
            departamento: ''
        }

        this.workload = {
            investigadores: [],
            requested_duration: 0,
            recomended_duration: 0,
            requested_budget: 0,
            recomended_budget: 0,
            hourly_rate: 7000,
            project: 0,
            rubric_application: 0
        }

        this.openLoading();
        
        this.route.params.pipe(
            switchMap(params => {
                this.idRubricAplication = params['id'];
      
                if (this.idRubricAplication) {
                  return this.rubricApplicationService.getRubricAplication(this.idRubricAplication);
                }
      
                // If there's no idUser, just return an observable of null
                return of(null);
            })
          ).subscribe({
              next: async(response) => {
                  if (response) {
                    this.rubricApplication = response;
                    this.project = await this.projectService.getProject(this.rubricApplication.project!.id);
                    this.rubric = await this.rubricService.getRubric(2);
                    this.peerEvaluations = await this.rubricApplicationService.getPeerRubricAplications(this.project!.id);
                    this.workload.project = this.project!.id;
                    this.workload.rubric_application = this.rubricApplication!.id;

                    //bundle all other academics, grouped by rut
                    const otherAcademics: OtherAcademicsView[] = [];
                    for(let oa of this.project!.other_academics!){
                    const foundOA = otherAcademics.find(oa => oa.rut === oa.rut);
                    if(!foundOA){
                        otherAcademics.push({
                        name: '',
                        rut: oa.rut,
                        activities: [
                            {
                            objective: oa.objective,
                            start_date: oa.start_date,
                            end_date: oa.end_date,
                            },
                        ],
                        });
                    }
                    if(foundOA){
                        foundOA.activities.push({
                        objective: oa.objective,
                        start_date: oa.start_date,
                        end_date: oa.end_date,
                        });
                    }
                    }
                    this.otherAcademics = otherAcademics;

                    //Transform PeopleEstimation to WorkloadInvestigatorRequest
                    for(let pe of this.project!.people_estimation!){
                        const wi:WorkloadInvestigatorRequest = {
                            name: pe.name,
                            rut: pe.rut,
                            requested_hours: this.getPETotalHours(pe),
                            recomended_hours: this.getPETotalHours(pe),
                            other_hours: 0
                        }

                        this.workload!.investigadores.push(wi);
                    }

                    const weeks: number[] = this.project!.people_estimation!.map(pe => pe.weeks || 0)

                    this.workload!.requested_duration = Math.max(...weeks);
                    this.workload!.recomended_duration = Math.max(...weeks);
                    this.workload!.requested_budget = this.project!.estimate_expenses!.reduce((acc, curr) => acc + (curr.amount * curr.unit_cost), 0);
                    this.workload!.recomended_budget = this.project!.estimate_expenses!.reduce((acc, curr) => acc + (curr.amount * curr.unit_cost), 0);

                    this.calculateSemesters();
                    this.closeLoading();
                  } else {
                    throw new Error('Error fetching Rubric Application');
                  }
      
                  
              },
              error: (error) => {
                  console.error('Error fetching Project:', error);
                  // Handle the error accordingly
                  this.closeLoading();
              }
          });
    }

    getPETotalHours(pe: PersonEstimation): number{
        let total = 0;
        total = pe.hours!.reduce((acc, curr) => acc + curr.hours, 0);
        return total;
    }

    calculateSemesters() {
        //Todo: Get starting semester:
        let currentSemester = 2;
        let currentYear = 2024;
        let current_months = 0;
    
        const semesters: Semester[] = [];
    
        while(current_months < this.project!.estimated_duration_months!){
          // Get current semester
          semesters.push({
            name: `${currentYear} - ${currentSemester.toString().padStart(2, '0')}`,
            year: currentYear,
            semester: currentSemester
          });
          //advance to next semester
          currentSemester++;
          if(currentSemester > 2){
            currentSemester = 1;
            currentYear++;
          }
          current_months += 6;
        }
    
        this.semesters = semesters;
    }

    async save() {
        this.openLoading();
        let request: any = {
          answers: [],
          investigatiorComment: this.commentEvaluation.investigador,
          internalComment: this.commentEvaluation.departamento
        };

        let totalScore = 0;

        if(this.peerEvaluations.length > 0){
            totalScore = this.peerEvaluations.reduce((acc, val) => acc + (val.totalScore || 0), 0);
        }

        this.rubric?.categories.forEach(category => {
            category.questions.forEach(question => {
               let obj: Pending = {
                   idQuestion: question.id,
                   value: question.answer
               };
               totalScore += question.answer;
                request.answers.push(obj);
            });
        });

        request.totalScore = totalScore;
        request.qualitativeEvaluation = this.getEvalText(request.totalScore);

        await this.rubricApplicationService.saveAnswers(this.idRubricAplication, request);
        await this.workloadService.postWorkload(this.workload);
        await this.projectService.changeStatus('EV', this.project!.id);


        this.closeLoading();
        this.router.navigateByUrl('/projectos/lista' );
        await this.globalSuccess('Evaluación enviada exitosamente');
    }

    cancel(): void {
        this.router.navigateByUrl('mis-pendientes');
    }

    getTotalSolicitado() {
        let total = 0;
        this.workload.investigadores.forEach(investigador => {
            total += investigador.recomended_hours;
        });
        return total;
    }

    getTotalRecomendado() {
        let total = 0;
        this.workload.investigadores.forEach(investigador => {
            total += investigador.recomended_hours;
        });
        return total;
    }

    getTotalHoraSolicitado() {
        return this.getTotalSolicitado()*this.workload.requested_duration;
    }

    getTotalHoraRecomendado() {
        return this.getTotalRecomendado()*this.workload.recomended_duration;
    }

    getEvalText(evalTotal:number): string {
        const json = JSON.parse(this.rubric?.evalJSON || '{}');
        if(json.hasOwnProperty('evals')){
            for(let e of json.evals){
                if(evalTotal >= e.min && evalTotal <= e.max){
                    return e.name;
                }
            }
        }
        return '';
    }

    getProjectTypeLabel(projectType: string): string {
        switch (projectType) {
          case 'is_initial':
            return 'Iniciación (hasta 12 meses)';
          case 'is_regular':
            return 'Regular (hasta 24 meses)';
          case 'is_technology_transfer':
            return 'Transf. Tec. (hasta 24 meses)';
          case 'is_related_music':
            return 'Música';
          case 'is_related_literature':
            return 'Literatura';
          case 'other_related':
            return 'Otro (Indicar)';
          default:
            return '';
        }
    }
    
    getFacultyName(faculty: Faculty | undefined): string {
        // logic to get faculty name by id
        return '';
    }

    getContractTypeName(contractTypeId: number| undefined): string {
        // logic to get contract type name by id
        return '';
    }

    getGradeName(grade: string| undefined): string {
        // logic to get grade name by id
        return '';
    }

    getObjectiveName(objectiveId: string| undefined): string {
        // logic to get objective name by id
        return '';
    }
    
    getPersonTotalHours(person:any): number {
        let total = 0;
        person.hours.forEach((hour: { hours: number; }) => {
            total += hour.hours;
        });
        return total * person.weeks || 0;
    }
    
    getTotalHours(): number {
        // logic to calculate total hours
        return 0;
    }

}
