import { Component, Injector, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ErrorService } from '../core/services/error.service';
import Swal from 'sweetalert2';
import { AuthStateService } from '../account/auth/auth.state.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
    selector: 'app-base-page',
    template: '',
})
export class BasePage implements OnInit {

    protected router: Router;
    protected store: Store;
    protected spinner!: NgxSpinnerService;
    protected datePipe: DatePipe;
    private service: ErrorService;
    private authStateService: AuthStateService;

    userDetails: any = {};

    constructor(injector: Injector) {
        this.router = injector.get(Router);
        this.store = injector.get(Store);
        this.datePipe = injector.get(DatePipe);
        this.service = injector.get(ErrorService);
        this.spinner = injector.get(NgxSpinnerService);
        this.authStateService = injector.get(AuthStateService);

        this.authStateService.authState$.subscribe(authState => {
            this.userDetails.name = authState?.name
            this.userDetails.photoURL = authState?.photoURL,
            this.userDetails.id = authState?.id
        });
    }

    ngOnInit(): void {
        console.log('BasePage initialized');
        this.service.getValue().subscribe((value) => {
            if (value) {
                console.log("error suscrito", value);
                this.globalErrorIntercepted(value);
                this.service.setValue('');
            }
        });
    }

    openLoading() {
        this.spinner.show();
    }

    closeLoading() {
        this.spinner.hide();
    }

    async globalErrorIntercepted(err: any) {
        this.closeLoading();
        await Swal.fire('Error', err, 'error');
    }

    async globalError(err: any) {
        this.closeLoading();
        await Swal.fire('Error', err.message, 'error');
    }

    async globalSuccess(msg: string) {
        this.closeLoading();
        await Swal.fire('Éxito', msg, 'success');
    }

}