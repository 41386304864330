import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {environment} from "../../../environments/environment";
import {
  RubricAnswerRequest,
  RubricApplication,
  RubricApplicationPatchRequest,
  RubricApplicationRequest
} from "../models/rubrica.model";
import {Reviewers} from "../models/user.model";
import {Mensaje} from "../models/utils.model";

@Injectable({
  providedIn: 'root'
})
export class RubricAplicationService {
  

  constructor(private http: HttpClient) {
  }

  getReviewers(): Promise<Reviewers[]> {
    return lastValueFrom(this.http.get<Reviewers[]>(`${environment.apiURL}/evaluadores`));
  }

  getRubricAplications(idProject: number): Promise<RubricApplication[]> {
    return lastValueFrom(this.http.get<RubricApplication[]>(`${environment.apiURL}/rubricapplication?project=${idProject}`));
  }

  getRubricAplication(idRubricApplication: number): Promise<RubricApplication> {
    return lastValueFrom(this.http.get<RubricApplication>(`${environment.apiURL}/rubricapplication/${idRubricApplication}`));
  }

  postRubricAplication(data: RubricApplicationRequest): Promise<Mensaje> {
    return lastValueFrom(this.http.post<Mensaje>(`${environment.apiURL}/rubricapplication`, data));
  }

  patchRubricAplication(id: number, data: RubricApplicationPatchRequest): Promise<Mensaje> {
    return lastValueFrom(this.http.patch<Mensaje>(`${environment.apiURL}/rubricapplication/${id}`, data));
  }

  deleteRubricAplication(id: number): Promise<Mensaje> {
    return lastValueFrom(this.http.delete<Mensaje>(`${environment.apiURL}/rubricapplication/${id}`));
  }

  saveAnswers(idRubricAplication: number, request: any) {
    return lastValueFrom(this.http.put<string>(`${environment.apiURL}/rubricapplication/answers/${idRubricAplication}`, request));
  }

  getPeerRubricAplications(id: number): RubricApplication[] | PromiseLike<RubricApplication[]> {
    return lastValueFrom(this.http.get<RubricApplication[]>(`${environment.apiURL}/rubricapplication?project=${id}&type=PEER`));
  }

  getDirectiveRubricAplications(id: number): RubricApplication[] | PromiseLike<RubricApplication[]> {
    return lastValueFrom(this.http.get<RubricApplication[]>(`${environment.apiURL}/rubricapplication?project=${id}&type=DIRECTIVE`));
  }

}