<h4 class="text-center">Evaluación Rubrica</h4>

<div class="table-responsive">
    <table class="table mb-0" *ngFor="let cat of rubric?.categories; index as i; let last = last">
        <thead class="table-light">
        <tr class="table-info">
            <th colspan="3"><b>{{numberToRoman(i + 1)}}. {{cat.name}}</b></th>
        </tr>
        </thead>
        <tbody *ngFor="let question of cat.questions; index as j">
        <tr class="table-secondary">
            <th></th>
            <th>{{j + 1}}. {{question.question}}</th>
            <th>{{getOptionValues(question, 0)}}</th>
        </tr>
        <tr>
            <th style="width: 2%;"></th>
            <th>
                <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                    <option value="{{option.id}}"
                            *ngFor="let option of question.options">{{option.option}}</option>
                </select>
            </th>
            <th style="width: 10%;">
                <input type="number" class="form-control" [(ngModel)]="question.answer">
            </th>
        </tr>
        </tbody>
        <tfoot class="table-light">
        <tr>
            <td colspan="2" class="fw-bold">Total ITEM {{numberToRoman(i + 1)}}. (puntaje máximo: {{getMaxCat(cat)}})</td>
            <td>{{getCatTotal(cat)}}</td>
        </tr>
        <tr *ngIf="last" class="table-dark">
            <td class="text-center" colspan="3"><h4>Resultado</h4></td>
        </tr>
        <tr *ngIf="peerEvaluations">
            <td colspan="3">Observaciones al PI</td>
        </tr>
        <tr *ngFor="let eval of peerEvaluations; index as i">
            <td>{{i+1}}.-</td>
            <td colspan="3">{{eval.internalComment}}</td>
        </tr>
        <tr *ngFor="let eval of peerEvaluations; index as i" class="table-info">
            <td colspan="2">Total Par Evaluador {{i+1}}</td>
            <td>{{eval.totalScore}}</td>
        </tr>
        <tr *ngIf="last" class="table-info">
            <td colspan="2" class="fw-bold">Total Evaluación</td>
            <td>{{getEvalTotal()}}</td>
        </tr>
        <tr *ngIf="peerEvaluations" class="table-info">
            <td colspan="2" class="fw-bold">Total General</td>
            <td>{{getTotalTotal()}}</td>
        </tr>
        <tr *ngIf="last" class="table-warning">
            <td colspan="3" class="text-center"><h3>{{getEvalText()}}</h3></td>
        </tr>
        </tfoot>
    </table>
</div>