<form [formGroup]="formStep">
    <h5>2. DESCRIPCION DEL PROYECTO</h5>
    <div class="row">
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="resumen"><h5>2.1 Resumen</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstResumen)">Instrucciones</button>
                <ckeditor [editor]="Editor" data="" id="resumen" name="pi_summary" formControlName="pi_summary"></ckeditor>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="pi_introduction"><h5>2.2 Introducción</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstIntro)">Instrucciones</button>
                <ckeditor [editor]="Editor" data="" id="pi_introduction" name="pi_introduction" formControlName="pi_introduction"></ckeditor>
            </div>
        </div>
        <div class="col-lg-12">              
            <div class="mb-3">
                <label for="pi_objectives"><h5>2.3 Objetivos</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstResumen)">Instrucciones</button>
                <div formArrayName="pi_objectives">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Objetivo</th>
                                <th scope="col">Elim.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let objective of piObjectives.controls; let i = index" [formGroupName]="i">
                                <td>{{i + 1}}</td>
                                <td>
                                    <input type="text" class="form-control" formControlName="name" placeholder="Nombre Objetivo">
                                    <ckeditor [id]="'pi_objectives_' + i" [editor]="Editor" formControlName="text" aria-placeholder="Texto Objetivo"></ckeditor>
                                </td>
                                <td><button class="btn btn-icon btn-lg btn-danger" type="button" (click)="removeObjective(i)"><i class="bx bx-trash"></i></button></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3"><button class="btn btn-primary btn-md" type="button" (click)="addObjective()"> + Agregar Objetivo</button></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                
            </div>
        </div>
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="pi_methodology"><h5>2.4 Metodología</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstResumen)">Instrucciones</button>
                <ckeditor [editor]="Editor" data="" id="pi_methodology" name="pi_methodology" formControlName="pi_methodology"></ckeditor>
            </div>
        </div>
    </div>
                    
    <div class="d-flex flex-row-reverse">
        <button class="btn btn-primary" type="submit" (click)="nextStep()">Siguiente</button>
        <button class="btn btn-primary" style="margin-right: 5px;" type="button" (click)="save()">Guardar</button>
        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="previousStep()">Atrás</button>
        <span style="margin-right: 5px; padding-top: 6px;"><small>Último Guardado {{parentComponent.lastModified | date:'dd/MM/YYYY HH:mm:ss'}}</small></span>
    </div>

    <ng-template #InstResumen let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasTopLabel">Instrucciones Orientativas: RESUMEN</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <p>Es una breve reseña de los aspectos más relevantes de la investigación. El resumen se puede presentar en los mismos subapartados de la descripción de la investigación (introducción, objetivo y metodología).</p>
            <p>Introducción: ¿Cuál es el problema y por qué es importante? Describe el problema y el contexto. </p>
            <p>Objetivo: ¿Qué se pretende lograr o demostrar?</p>
            Metodología: ¿Cómo se soluciona el problema? Incluye el diseño de la investigación, ámbito, participantes, de medidas principales, etcétera. En el apartado de metodología en el resumen defina a cuál tipo de trabajo corresponde el suyo:<br>
                <ol>
                    <li>Trabajos empíricos o con datos primarios describe el diseño, los participantes, el entorno, las intervenciones (instrumentos de recogida de datos…), el análisis estadístico y cualquier otra característica esencial del método a usar.</li>
                    <li>Trabajos teóricos se menciona como funciona la teoría o el modelo, los principios en que se basa, que fenómenos explica la teoría o el modelo y sus vínculos con los resultados empíricos. </li>
                    <li>Trabajos metodológicos se considera el tipo general del método, las características esenciales del método, el rango de aplicación del método, en caso de procedimientos estadísticos, alguna de sus características esenciales. </li>
                    <li>Un estudio de caso, se considera el tema y las características relevantes del individuo u organización, la naturaleza del problema o solución presentada a través del caso y las preguntas con relación a otra investigación o teoría. </li>
                    <li>Un metaanálisis o revisión sistemática de la literatura, defina el problema, los criterios de elegibilidad del problema, los participantes, los resultados, las conclusiones y las implicaciones para la teoría.</li>
                </ol>
            
        </div>
    </ng-template>

    <ng-template #InstIntro let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasTopLabel">Instrucciones Orientativas: INTRODUCCION</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <p style="font-weight: bold;">Se debe incluir la lista de referencias bibliográficas que sustentan este apartado, sobre todo por lo que se refiere a los antecedentes o estado actual del conocimiento sobre el tema o problema de investigación.</p>
            <p>Contenido: </p>
            <ul>
                <li>La introducción debe <b>indicar claramente</b> el problema objeto de investigación, el contexto que explica la importancia del problema y las razones que existen para llevar a cabo la investigación, es decir, por qué el problema merece que se realice una nueva investigación. </li>
                <li>Se menciona y citan los <b>antecedentes</b> más relevantes, es decir, las investigaciones pertinentes anteriores de otros autores o de los mismos autores de la investigación para mostrar el contexto de la investigación, o bien, la teoría reciente relacionada con el tema a tratar. </li>
                <li>Se explica cuál es la <b>originalidad de la investigación</b>, por lo tanto en que se diferencia el abordaje de su trabajo en relación con otros trabajos publicados con anterioridad. </li>
                <li>Se <b>justifica el estudio</b> al identificar las preguntas a las que se está respondiendo. </li>
                <li>Pone de relieve los <b>desafíos del estudio</b> de manera que explica que otras conclusiones, si las hubiere, desafía, contradice o amplia. </li>
                <li><b>¿Y cómo responde a las preguntas que plantea?</b>, ello lo hace al describir brevemente el experimento, hipótesis, pregunta de investigación, y cuál ha sido el diseño experimental general o método; o sea, se expone la hipótesis y su correspondencia con el diseño de la investigación.</li> 
            </ul>
            <p><b>¿Cómo empezar?</b>: Se sugieren dos maneras de empezar a escribir la introducción: </p>
            <ol>
                <li>Mostrando la magnitud del problema en términos de prevalencia, incidencia, morbilidad, mortalidad o costos; </li>
                <li>Citando la controversia actual sobre el tema.</li>
            </ol>
            <p><b>Importante:</b> Siempre que se pretenda publicar una observación muy infrecuente debe precisarse en el texto el método de búsqueda bibliográfica, las palabras clave empleadas, los años de cobertura y la fecha de actualización.</p>
            <p><b>Preguntas a las que responde:</b> En resumen, la introducción se plantea estas preguntas: ¿Cuál es el problema? ¿Existe alguna solución? ¿Cuál es la mejor solución? ¿Cuál es la mayor limitación? ¿Qué se espera lograr?</p>

        </div>
    </ng-template>

</form>
