import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BasePage } from 'src/app/shared/base-page';
import { NgForm } from '@angular/forms';
import { CommitteeRequest } from 'src/app/core/models/committee-request.model';
import { CommitteeRequestWizardService } from 'src/app/core/services/committee-request-wizard.service';

@Component({
  selector: 'app-solicitud-comite-wizard',
  templateUrl: './solicitud-comite-wizard.component.html',
  styleUrls: ['./solicitud-comite-wizard.component.scss']
})
export class ComitteeRequestWizardComponent extends BasePage implements OnInit {

  @ViewChild('cdkStepper') stepper!: CdkStepper;
  @ViewChild('projectForm') projectForm!: NgForm;
  
  firstFormGroup!: FormGroup;
  currentStepIndex: number = 0;
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  committeeRequest?: Partial<CommitteeRequest> = undefined;


  constructor(
    private _formBuilder: FormBuilder, 
    private committeeRequestWizardService: CommitteeRequestWizardService,
    injector: Injector) 
  { super(injector); }

  async ngOnInit() {

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Solicitudes Comité' },
      { label: 'Crear Solicitud Comité', active: true }
    ];

    //start with no validation
    //this.project = {id: 0}

    this.firstFormGroup = this._formBuilder.group({
      // define controls and validators
    });
    // Initialize other form groups for each step
  }


  updateData(updatedFields: Partial<CommitteeRequest>) {
    this.committeeRequestWizardService.updateCommitteeRequestData(updatedFields);
  }

  nextStep(committeeRequest?: Partial<CommitteeRequest>) {
    if(committeeRequest) this.updateData(committeeRequest);
    this.stepper.next();
  }
  
  previousStep(committeeRequest?: Partial<CommitteeRequest>) {
    if(committeeRequest) this.updateData(committeeRequest);
    this.stepper.previous();
  }


}
