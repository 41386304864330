import { Component, OnInit, ViewChild } from '@angular/core';
import { projectsBarChart, emailSentBarChart, monthlyEarningChart, transactions, orders, users } from './data';
import { ChartType } from './dashboard.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss']
})

/**
 * Dashboard Component
 */
export class TableroComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  emailSentBarChart!: ChartType;
  projectsBarChart!: ChartType;
  monthlyEarningChart!: ChartType;
  transactions: any;
  orders: any;
  users: any;
  chartOptions: any;
  chartOptions2: any;
  @ViewChild('content') content: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Dashboard' },
      { label: 'Dashboard', active: true }
    ];

    /**
     * Fetches the data
     */
    this.fetchData();
  }

  ngAfterViewInit() {
    console.log("afterViewInit 2");
    /*setTimeout(() => {
      this.openModal();
    }, 2000);*/
  }

  /**
   * Fetches the data
   */
  private fetchData() {
    this.projectsBarChart = projectsBarChart;
    this.emailSentBarChart = emailSentBarChart;
    this.monthlyEarningChart = monthlyEarningChart;
    this.transactions = transactions;
    this.orders = orders;
    this.users = users;

    this.chartOptions = {
      series: [44, 55, 13, 43, 22], // Datos de ejemplo
      chart: {
        type: 'pie',
        width: 380
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'], // Etiquetas de ejemplo
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    this.chartOptions2 = {
      series: [
        {
          name: 'Sales',
          data: [30, 40, 45, 50, 49, 60, 70, 91, 125] // Datos de ejemplo
        }
      ],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'], // Etiquetas de ejemplo
      }
    };
  }

  /***
   * Subscribe Model open
   */
  openModal() {
    this.modalService.open(this.content, { centered: true });
  }
}
