<!-- project-wizard.component.html -->
<!-- start page title -->
<app-pagetitle title="Formulario Proyecto" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <form #projectForm="ngForm">
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="callperiod" name="callperiod" readonly value="..." [(ngModel)]="cpCode">
                                <label for="callperiod">Convocatoria</label>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelectFacu" [(ngModel)]="faculty" name="faculty" (change)="getFolio(); updateHeaders()" required>
                                    <option value="">Eliga una Facultad / Unidad</option>
                                    <option *ngFor="let faculty of faculties" [value]="faculty.id">
                                        {{faculty.name}}
                                    </option>
                                </select>
                                <label for="floatingSelectFacu">Facultad / Unidad</label>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" [(ngModel)]="career" name="career" (change)="updateHeaders()" required>
                                    <option value="">Eliga un Programa / Carrera</option>
                                    <option *ngFor="let career of careersForFaculty(faculty)" [value]="career.id">
                                        {{career.name}}
                                    </option>
                                </select>
                                <label for="floatingSelect">Programa / Carrera</label>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="folio" name="folio" readonly value="..." [(ngModel)]="folio" required>
                                <label for="folio">Folio</label>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="title" name="title" placeholder="Ej. Proyecto de Investigación" [(ngModel)]="name" (change)="updateHeaders()" required>
                                <label for="title">Título Proyecto</label>
                            </div>
                        </div>
                        
                        <div class="col-lg-12">
                            <div style="width: 100%" class="btn-group btn-group-lg" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked [disabled]="!!project" [(ngModel)]="type" value="PI">
                                <label class="btn btn-outline-primary" for="btnradio1">
                                    <img class="img-responsive" src="/assets/images/pi.jpg" height="100"/><br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Proyecto de Investigación&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </label>
                            
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [disabled]="!!project"  [(ngModel)]="type" value="PCRAC">
                                <label class="btn btn-outline-primary" for="btnradio2">
                                    <img class="img-responsive" src="/assets/images/pcrac.jpg" height="100"/><br>
                                    Proyecto de Creación Artística y Cultural
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row-reverse" *ngIf="!project">
                        <button class="btn btn-primary" type="submit" (click)="create()" [disabled]="!projectForm.valid">Crear Proyecto</button>
                    </div>
                </form>

                <ng-stepper #cdkStepper [linear]="false" class="wizard" *ngIf="project">

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">1.</span>
                        <span>Identificación</span>
                        </ng-template>
                        <app-proyecto-step1 [parentComponent]="this"></app-proyecto-step1>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">2.</span>
                        <span>Descripción</span>
                        </ng-template>
                        <app-proyecto-step2 [parentComponent]="this"></app-proyecto-step2>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">3.</span>
                        <span>Impacto</span>
                        </ng-template>
                        <app-proyecto-step3 [parentComponent]="this"></app-proyecto-step3>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">4.</span>
                        <span>Publicación</span>
                        </ng-template>
                        <app-proyecto-step4 [parentComponent]="this"></app-proyecto-step4>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">5.</span>
                        <span>Ejecución</span>
                        </ng-template>
                        <app-proyecto-step5 [parentComponent]="this"></app-proyecto-step5>
                    </cdk-step>

                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">6.</span>
                        <span>Presupuesto</span>
                        </ng-template>
                        <app-proyecto-step6 [parentComponent]="this"></app-proyecto-step6>
                    </cdk-step>
                </ng-stepper>
            </div>
        </div>
    </div>
</div>