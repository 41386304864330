<div class="card" id="projectDisplay">
    <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
            <h4>Detalle del Proyecto</h4>
            <button type="button" class="btn btn-primary mb-2" (click)="downloadPDF()">Descargar PDF</button>
        </div>
        <div ngbAccordion #accordion="ngbAccordion" activeIds="config-panel-one" [closeOthers]="closeOthers">
            <div class="card rounded mb-3"></div>
            <div ngbAccordionItem="config-panel-one" id="config-panel-one">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton><h5>1. Identificación</h5></button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            <div *ngIf="project">
                                <h5>1. IDENTIFICACION DEL PROYECTO</h5>
                                <div class="row mb-3">
                                    <div class="col-lg-3">
                                        <label>¿Corresponde a un TFC / TFP?</label><br>
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="is_TFC" value="{{ project.is_TFC ? 'SI' : 'NO' }}" readonly>
                                            <label for="is_TFC">¿Corresponde a un TFC / TFP?</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-3" *ngIf="project.type === 'PI'">
                                        <label>¿Proyecto con VCM?</label><br>
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="with_VCM" value="{{ project.with_VCM ? 'SI' : 'NO' }}" readonly>
                                            <label for="with_VCM">¿Proyecto con VCM?</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" *ngIf="project.type === 'PI'">
                                        <label>Indique el tipo de proyecto que está postulando</label><br>
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="projectType" value="{{ getProjectTypeLabel(project.type) }}" readonly>
                                            <label for="projectType">Tipo de Proyecto</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-5" *ngIf="project.type === 'PCRAC'" style="text-align: center;">
                                        <label>¿Con qué se relaciona su proyecto?</label><br>
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="projectRelation" value="{{ getProjectTypeLabel(project.type) }}" readonly>
                                            <label for="projectRelation">Relación del Proyecto</label>
                                        </div>
                                    </div>
                                </div>
                            
                                <h5 class="mt-5">1.1 Identifique la línea de investigación a la cual tributará su proyecto</h5>
                                <div class="row mb-5">
                                    <div class="col-lg-12">
                                        <div class="list-group">
                                            <label class="list-group-item" *ngFor="let line of project.investigative_lines?.split(',')">
                                                {{getLineName(line)}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="keywords" name="keywords" value="{{ project.keywords }}" readonly>
                                            <label for="keywords">Palabras Clave <small>(3 a 5 descriptores temáticos relevantes)</small></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                          
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem="config-panel-two">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton><h5>2. Descripción</h5></button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            <div *ngIf="project">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="resumen"><h5>2.1 Resumen</h5></label>
                                            <div [innerHTML]="project.pi_summary"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_introduction"><h5>2.2 Introducción</h5></label>
                                            <div [innerHTML]="project.pi_introduction"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_objectives"><h5>2.3 Objetivos</h5></label>
                                            <div *ngIf="project.pi_objectives && project.pi_objectives.length > 0">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Objetivo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let objective of project.pi_objectives; let i = index">
                                                            <td>{{ i + 1 }}</td>
                                                            <td>
                                                                <div>{{ objective.name }}</div>
                                                                <div [innerHTML]="objective.text"></div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_methodology"><h5>2.4 Metodología</h5></label>
                                            <div [innerHTML]="project.pi_methodology"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem="config-panel-three">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton><h5>3. Impacto</h5></button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template ngbPanelContent>
                            <div *ngIf="project">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_contribution_science"><h5>3.1 Contribución al avance del Conocimiento Científico</h5></label>
                                            <div [innerHTML]="project.pi_contribution_science"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_aplicability_results"><h5>3.2 Aplicabilidad de los Resultados</h5></label>
                                            <div [innerHTML]="project.pi_aplicability_results"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_transfer_plan"><h5>3.3 Transferencia de Resultados</h5></label>
                                            <div [innerHTML]="project.pi_transfer_plan"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_teaching_support"><h5>3.4 Apoyo a la Docencia</h5></label>
                                            <div [innerHTML]="project.pi_teaching_support"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem="config-panel-four">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton><h5>4. Publicación</h5></button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template ngbPanelContent>
                            <div *ngIf="project">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_expositions"><h5>4.1 Publicación (Artículos de Revista, Capítulos de Libros y Libros)</h5></label>
                                            <div [innerHTML]="project.pi_expositions"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="pi_publications"><h5>4.2 Exposición (Ponencias, Pósteres)</h5></label>
                                            <div [innerHTML]="project.pi_publications"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem="config-panel-five">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton><h5>5. Ejecución</h5></button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template ngbPanelContent>
                            <div *ngIf="project">
                                <h5>5. EJECUCIÓN DEL PROYECTO</h5>
                                <h5>5.1 Estimación de la duración del Proyecto</h5>
                                <div class="row mb-5">
                                    <div class="col-lg-12">
                                        <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="duration" [value]="project.estimated_duration_months" readonly>
                                            <label for="duration">Duración (En meses)</label>
                                        </div>
                                    </div>
                                </div>
                                <h5>Detallar la ejecución del Proyecto en el cronograma o plan de tareas</h5>
                            
                                <div class="row mb-5">
                                    <div class="col-lg-12">
                                        <h5>Actividades</h5>
                                        <ul>
                                            <li *ngFor="let item of project.activities">
                                                <b>{{ item.name }}</b> ({{ item.start_date | date: 'DD/MM/yyyy' }} - {{ item.end_date | date: 'DD/MM/yyyy' }})
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            
                                <h5>5.2 Estimación del tiempo necesario para realizar la investigación</h5>
                                <small>*Tiempo completo, medio tiempo, honorarios, no contratado (estudiantes, investigadores de otras universidades…).<br>
                                    **Las horas de investigación son horas cronológicas, no son horas académicas.</small>
                                <div class="row mb-5">
                                    <div class="col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table mb-0 small-padding-table">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th rowspan="3" style="width: 150px;">Investigador / Estudiante</th>
                                                        <th rowspan="3" style="width: 100px;">Facultad / Unidad</th>
                                                        <th rowspan="3" style="width: 100px;">Tipo de Contrato</th>
                                                        <th rowspan="3" style="width: 100px;">Grado</th>
                                                        <th [attr.colspan]="semesters.length + 2">N° horas cronológicas* asignadas para el Proyecto**</th>
                                                    </tr>
                                                    <tr>
                                                        <th [attr.colspan]="semesters.length">N° horas</th>
                                                        <th rowspan="2" class="week_number">N° semanas</th>
                                                        <th rowspan="2" class="total_hours">N° horas totales</th>
                                                    </tr>
                                                    <tr>
                                                        <th *ngFor="let sem of semesters" class="semester_hours">
                                                            {{ sem.name }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let pe of project.people_estimation; let i = index">
                                                    <tr>
                                                        <td>{{ pe.rut }}</td>
                                                        <td>{{ pe.faculty }}</td>
                                                        <td>{{ getContractTypeName(pe.contract_type) }}</td>
                                                        <td>{{ getGradeName(pe.grade) }}</td>
                                                        <td *ngFor="let hour of pe.weekly_hours">{{ hour }}</td>
                                                        <td>{{ pe.weeks }}</td>
                                                        <td>{{ getPersonTotalHours(pe) }}h</td>
                                                    </tr>
                                                    <tr style="border-bottom: 1px dashed darkgray;">
                                                        <td colspan="3">{{ pe.name }}</td>
                                                        <td [attr.colspan]="semesters.length + 3"></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td [attr.colspan]="semesters.length + 6" style="text-align: right;">TOTAL</td>
                                                        <td>{{ getTotalHours() }}h</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            
                                <h5>5.3 Otras iniciativas o Proyectos que el Investigador (es) contratado de la UnACh haya postulado o esté ejecutando</h5>
                                <div class="row mb-5">
                                    <div class="col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th>Investigador contratado de la UnACh</th>
                                                        <th>Tiempo Adjudicado o solicitado en otros proyectos (horas semanales)</th>
                                                    </tr>
                                                </thead>
                                                <!--tbody>
                                                    <tr *ngIf="!project.other_projects || project.other_projects.length === 0">
                                                        <td>No se registran otras Actividades</td>
                                                    </tr>
                                                    <tr *ngFor="let otherProject of project.other_projects">
                                                        <td>
                                                            {{ otherProject.academic.name }}<br>
                                                            <ul>
                                                                <li>Titulo de la Investigación: {{ otherProject.title }}</li>
                                                                <li>Entidad Patrocinante: {{ otherProject.sponsoring_entity }}</li>
                                                                <li>Estado: {{ otherProject.status }}</li>
                                                            </ul>
                                                        </td>
                                                        <td>{{ otherProject.hours_per_week }}h</td>
                                                    </tr>
                                                </tbody-->
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            
                                <h5>Actividades por Investigador</h5>
                                <div class="row mb-5">
                                    <div class="col-lg-12">
                                        <div class="table-responsive">
                                            <table class="table mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th>Investigador</th>
                                                        <th>Actividades</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let person of otherAcademics">
                                                        <td>{{ person.rut }}</td>
                                                        <td>
                                                            <ul>
                                                                <li *ngFor="let activity of person.activities">
                                                                    {{ getObjectiveName(activity.objective) }} ({{ activity.start_date | date: 'MM/yyyy' }} - {{ activity.end_date | date: 'MM/yyyy' }})
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem="config-panel-six">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton><h5>6. Presupuesto</h5></button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="table-responsive">
                                        <table class="table mb-0"  *ngFor="let seccion of secciones; index as i">
                                            <thead class="table-light">
                                                <tr>
                                                    <th style="width: 27%;">Concepto de costo</th>
                                                    <th style="width: 23%;">Objetivo</th>
                                                    <th style="width: 15%;">Cantidad</th>
                                                    <th style="width: 15%;">Valor Unidad ($)</th>
                                                    <th style="width: 15%; text-align: right;">Valor Total ($)</th>
                                                    <th style="width: 5%;">Adjunto</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th colspan="5">{{seccion.name}}</th>
                                                </tr>
                                                <ng-container *ngFor="let cat of seccion.categories">
                                                    <tr>
                                                        <td colspan="5">&nbsp;&nbsp;{{cat.name}}</td>
                                                    </tr>
                                                    <tr *ngFor="let expense of getExpensesFromCat(cat.id); ; let j = index">
                                                        <td>{{expense.name}}</td> 
                                                        <td>{{getObjective(expense.objective)}}</td>
                                                        <td>{{expense.amount}}</td>
                                                        <td style="text-align: right;">{{formatCurrency(expense.unit_cost)}}</td>
                                                        <td style="text-align: right;">{{ formatCurrency(getExpenseTotal(expense))}}</td>
                                                        <td>
                                                            <div *ngIf="expense.attachmentBase64">
                                                                <a [href]="'data:application/octet-stream;base64,' + expense.attachmentBase64" [download]="expense.attachmentName" class="file-name">{{ expense.attachmentName }}</a><br>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <tr>
                                                    <td style="text-align: right; font-weight: bold;" colspan="4">SUBTOTAL {{seccion.name}}</td>
                                                    <td style="text-align: right;">{{ formatCurrency(getSectionTotal(seccion)) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        
                            <h5 class="mt-5">Resumen Subtotales</h5>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead class="table-light">
                                                <tr>
                                                    <th>SUBTOTAL</th>
                                                    <th>Valor Total ($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let seccion of secciones; index as i">
                                                    <th>{{seccion.name}}</th>
                                                    <td style="text-align: right;">{{ formatCurrency(getSectionTotal(seccion)) }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="table-light">
                                                <tr>
                                                    <td style="text-align: right; font-weight: bold;">TOTAL PRESUPUESTO GASTOS</td>
                                                    <td style="text-align: right;">{{ formatCurrency(getGrandTotal()) }}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>