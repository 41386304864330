import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthStateService } from 'src/app/account/auth/auth.state.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

    userDetails: any = {};

    constructor(
        private router: Router,
        private authStateService: AuthStateService
    ) {
        this.authStateService.authState$.subscribe(authState => {
           this.userDetails = authState;
        });
     }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(!this.userDetails){
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }
}
