<!-- start page title -->
<app-pagetitle title="Formulario Rúbrica" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formRubric">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Ej. Rúbrica 2023 PI" formControlName="name">
                                <label for="name">Nombre de la Rúbrica</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" formControlName="type">
                                    <option value="">Eliga un Tipo de Rúbrica</option>
                                    <option *ngFor="let type of types" [value]="type.id">{{type.name}}</option>
                                </select>
                                <label for="floatingSelect">Tipo</label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="mb-4">
                                <h5 class="font-size-14 mb-2">¿Activo?</h5>
                                <div class="form-check form-switch form-switch-lg mb-lg-3" dir="ltr">
                                    <input class="form-check-input" type="checkbox" id="formCheckActivo" formControlName="isActive">
                                    <label class="form-check-label" for="formCheckActivo">
                                        {{ formRubric.value.isActive ? 'Activo' : 'Inactivo' }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <button class="btn btn-success" type="button" (click)="addCategory()">Agregar Categoría</button>
                    <fieldset formArrayName="categories">
                        <div *ngFor="let category of categories.controls; let i=index" [formGroupName]="i">
                            <div class="row mt-4">
                                <div class="col-lg-11">
                                    <label for="category-name">Nombre Categoría:</label>
                                    <input class="form-control" type="text" formControlName="name" id="category-name" placeholder="Nombre de la Categoría">
                                </div>
                                <div class="col-lg-1">
                                    <button class="mt-4 btn btn-danger btn-icon btn-lg" type="button" (click)="deleteCategory(i)" ><i class="bx bx-trash"></i></button>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-10">
                                    
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn btn-success" type="button" (click)="addQuestion(i)">Agregar Pregunta</button>
                                </div>
                            </div>

                            
                            <table class="table mt-2 mb-3" formArrayName="questions" *ngIf="questionsArray(i)">
                                <thead class="table-light">
                                    <tr>
                                        <th>Preguntas</th>
                                        <th style="width: 30px; text-align: center;"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let question of questionsArray(i).controls; let j=index" [formGroupName]="j">
                                    <tr>
                                        <td>
                                            <input placeholder="Texto de la Pregunta" class="form-control" type="text" formControlName="question" id="question">
                                        </td>
                                        <td>
                                            <button  class="btn btn-danger btn-icon" type="button" (click)="deleteQuestion(i, j)"><i class="bx bx-trash"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <table class="table mb-3" formArrayName="options" *ngIf="optionsArray(i,j)">
                                                <tbody>
                                                    <tr *ngFor="let option of optionsArray(i,j).controls; let k=index" [formGroupName]="k">
                                                        <td style="width: 20px;"></td>
                                                        <td>
                                                            <input placeholder="Opción" class="form-control" type="text" formControlName="option" id="option">
                                                        </td>
                                                        <td>
                                                            <input placeholder="Valor Mínimo" class="form-control" type="text" formControlName="min" id="min">
                                                        </td>
                                                        <td>
                                                            <input placeholder="Valor Máximo" class="form-control" type="text" formControlName="max" id="max">
                                                        </td>
                                                        <td style="width: 30px; text-align: center;">
                                                            <button  class="btn btn-danger btn-icon" type="button" (click)="deleteOption(i, j, k)"><i class="bx bx-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colspan="4" style="text-align: right;">
                                                            <button class="btn btn-success" type="button" (click)="addOption(i,j)">Agregar Opción</button>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <table class="table mt-2 mb-3">
                        <thead class="table-light">
                            <tr>
                                <th>Evaluación</th>
                                <th style="width: 25%;">Mínimo</th>
                                <th style="width: 25%;">Máximo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-success">Muy Recomendable</td>
                                <td><input placeholder="Valor Mínimo" class="form-control" type="text" formControlName="minMR"></td>
                                <td><input placeholder="Valor Máximo" class="form-control" type="text" formControlName="maxMR"></td>
                            </tr>
                            <tr>
                                <td class="text-warning">Recomendable</td>
                                <td><input placeholder="Valor Mínimo" class="form-control" type="text" formControlName="minR"></td>
                                <td><input placeholder="Valor Máximo" class="form-control" type="text" formControlName="maxR"></td>
                            </tr>
                            <tr>
                                <td class="text-danger">No Recomendable</td>
                                <td><input placeholder="Valor Mínimo" class="form-control" type="text" formControlName="minNR"></td>
                                <td><input placeholder="Valor Máximo" class="form-control" type="text" formControlName="maxNR"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="d-flex flex-row-reverse">
                        <button class="btn btn-primary" type="submit" (click)="save()">Guardar Rúbrica</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>