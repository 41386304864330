import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bxs-dashboard',
        subItems: [
            {
                id: 3,
                label: 'MENUITEMS.DASHBOARDS.LIST.ECOMMERCE',
                link: '/',
                parentId: 1
            },
            {
                id: 4,
                label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
                link: '/',
                parentId: 1
            },
        ]
    },
    {
        id: 2,
        label: 'MENUITEMS.UIELEMENTS.TEXT',
        icon: 'bx bx-briefcase',
        link: '/',

    },
    {
        id: 3,
        label: 'MENUITEMS.ADVANCEDKIT.TEXT',
        icon: 'bx bxs-grid me-2',
        subItems: [
            {
                id: 4,
                label: 'MENUITEMS.ADVANCEDKIT.LIST.SWEETALERT',
                link: '/',
                parentId: 3
            },
            {
                id: 5,
                label: 'MENUITEMS.ADVANCEDKIT.LIST.RANGESLIDER',
                link: '/',
                parentId: 3
            },
            {
                id: 6,
                label: 'MENUITEMS.ADVANCEDKIT.LIST.NOTIFICATIONS',
                link: '/',
                parentId: 3
            },
            {
                id: 7,
                label: 'MENUITEMS.ADVANCEDKIT.LIST.CAROUSEL',
                link: '/',
                parentId: 3
            }
        ]
    },
    {
        id: 8,
        label: 'MENUITEMS.APPS.TEXT',
        icon: 'bx bxs-cube-alt me-2',
        subItems: [
            {
                id: 9,
                label: 'MENUITEMS.APPS.LIST.CALENDAR',
                link: '/',
                parentId: 8
            },
            {
                id: 10,
                label: 'MENUITEMS.APPS.LIST.CHAT',
                link: '/',
                parentId: 8
            },
            {
                id: 11,
                label: 'MENUITEMS.APPS.LIST.EMAIL',
                badge: {
                    variant: 'success',
                    text: 'Em',
                },
                parentId: 8,
                subItems: [
                    {
                        id: 12,
                        label: 'MENUITEMS.APPS.LIST.SUB.INBOX',
                        link: '/',
                        parentId: 11
                    },
                    {
                        id: 13,
                        label: 'MENUITEMS.APPS.LIST.SUB.READEMAIL',
                        link: '/',
                        parentId: 11
                    }
                ]
            }
        ]
    },
    {
        id: 14,
        label: 'MENUITEMS.ADMINKIT.TEXT',
        icon: 'bx bx-layer me-2',
        subItems: [
            {
                id: 15,
                label: 'MENUITEMS.TYPOGRAPHY.TEXT',
                icon: 'bx bx-text',
                link: '/',
                parentId: 14

            },
            {
                id: 16,
                label: 'MENUITEMS.FORMS.TEXT',
                icon: 'bx bxs-magic-wand',
                subItems: [
                    {
                        id: 17,
                        label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
                        link: '/',
                        parentId: 16
                    },
                    {
                        id: 18,
                        label: 'MENUITEMS.FORMS.LIST.ADVANCED',
                        link: '/',
                        parentId: 16
                    }
                ]
            },
            {
                id: 19,
                label: 'MENUITEMS.TABLES.TEXT',
                icon: 'bx bx-table',
                subItems: [
                    {
                        id: 20,
                        label: 'MENUITEMS.TABLES.LIST.BASIC',
                        link: '/',
                        parentId: 19
                    },
                    {
                        id: 21,
                        label: 'MENUITEMS.TABLES.LIST.ADVANCED',
                        link: '/',
                        parentId: 19
                    }
                ]
            },
            {
                id: 22,
                label: 'MENUITEMS.CHARTS.TEXT',
                icon: 'bx bx-doughnut-chart',
                link: '/',

            },
            {
                id: 23,
                label: 'MENUITEMS.ICONS.TEXT',
                icon: 'bx bx-layer',
                subItems: [
                    {
                        id: 24,
                        label: 'MENUITEMS.ICONS.LIST.BOXICONS',
                        link: '/',
                        parentId: 23
                    },
                    {
                        id: 25,
                        label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
                        link: '/',
                        parentId: 23
                    },
                    {
                        id: 26,
                        label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
                        link: '/',
                        parentId: 23
                    },
                    {
                        id: 27,
                        label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
                        link: '/',
                        parentId: 23
                    }
                ]
            },
            {
                id: 28,
                label: 'MENUITEMS.MAPS.TEXT',
                icon: 'bx bx-map',
                link: '/',

            },
        ]
    },
    {
        id: 29,
        label: 'HEADER.EXTRA_PAGES.TITLE',
        icon: 'bx bx-file me-2',
        subItems: [
            {
                id: 30,
                label: 'MENUITEMS.AUTHENTICATION.TEXT',
                icon: 'bx bx-user-circle',
                subItems: [
                    {
                        id: 31,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
                        link: '/',
                        parentId: 30
                    },
                    {
                        id: 32,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
                        link: '/',
                        parentId: 30
                    },
                    {
                        id: 33,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
                        link: '/',
                        parentId: 30
                    },
                    {
                        id: 34,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
                        link: '/',
                        parentId: 30
                    },
                    {
                        id: 35,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
                        link: '/',
                        parentId: 30
                    },
                    {
                        id: 36,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
                        link: '/',
                        parentId: 30
                    },
                    {
                        id: 37,
                        label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
                        link: '/',
                        parentId: 30
                    }
                ]
            },
            {
                id: 38,
                label: 'MENUITEMS.PAGES.TEXT',
                icon: 'bx bx-file',
                subItems: [
                    {
                        id: 39,
                        label: 'MENUITEMS.PAGES.LIST.STARTER',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 41,
                        label: 'MENUITEMS.PAGES.LIST.PROFILE',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 42,
                        label: 'MENUITEMS.PAGES.LIST.INVOICE',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 43,
                        label: 'MENUITEMS.PAGES.LIST.MAINTENANCE',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 44,
                        label: 'MENUITEMS.PAGES.LIST.COMINGSOON',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 45,
                        label: 'MENUITEMS.PAGES.LIST.TIMELINE',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 46,
                        label: 'MENUITEMS.PAGES.LIST.PRICING',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 47,
                        label: 'MENUITEMS.PAGES.LIST.ERROR404',
                        link: '/',
                        parentId: 38
                    },
                    {
                        id: 48,
                        label: 'MENUITEMS.PAGES.LIST.ERROR500',
                        link: '/',
                        parentId: 38
                    }
                ]
            },
        ]
    },

];

