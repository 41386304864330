<form [formGroup]="formStep">
    <h5>3. IMPACTO DEL PROYECTO</h5>
    <div class="row">
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="pi_contribution_science"><h5>3.1 Contribución al avance del Conocimiento Científico</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstResumen)">Instrucciones</button>
                <ckeditor [editor]="Editor" data="" id="pi_contribution_science" name="pi_contribution_science" formControlName="pi_contribution_science"></ckeditor>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="pi_aplicability_results"><h5>3.2 Aplicabilidad de los Resultados</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstIntro)">Instrucciones</button>
                <ckeditor [editor]="Editor" data="" id="pi_aplicability_results" name="pi_aplicability_results" formControlName="pi_aplicability_results"></ckeditor>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="pi_transfer_plan"><h5>3.3 Transferencia de Resultados</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstResumen)">Instrucciones</button>
                <ckeditor [editor]="Editor" data="" id="pi_transfer_plan" name="pi_transfer_plan" formControlName="pi_transfer_plan"></ckeditor>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="pi_teaching_support"><h5>3.4 Apoyo a la Docencia</h5></label>&nbsp;
                <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" (click)="openNoBackdrop(InstResumen)">Instrucciones</button>
                <ckeditor [editor]="Editor" data="" id="pi_teaching_support" name="pi_teaching_support" formControlName="pi_teaching_support"></ckeditor>
            </div>
        </div>
    </div>
                    
    <div class="d-flex flex-row-reverse">
        <button class="btn btn-primary" type="submit" (click)="nextStep()">Siguiente</button>
        <button class="btn btn-primary" style="margin-right: 5px;" type="button" (click)="save()">Guardar</button>
        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="previousStep()">Atrás</button>
        <span style="margin-right: 5px; padding-top: 6px;"><small>Último Guardado {{parentComponent.lastModified | date:'dd/MM/YYYY HH:mm:ss'}}</small></span>
    </div>

    <ng-template #InstResumen let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasTopLabel">Instrucciones Orientativas: RESUMEN</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <p>Es una breve reseña de los aspectos más relevantes de la investigación. El resumen se puede presentar en los mismos subapartados de la descripción de la investigación (introducción, objetivo y metodología).</p>
            <p>Introducción: ¿Cuál es el problema y por qué es importante? Describe el problema y el contexto. </p>
            <p>Objetivo: ¿Qué se pretende lograr o demostrar?</p>
            Metodología: ¿Cómo se soluciona el problema? Incluye el diseño de la investigación, ámbito, participantes, de medidas principales, etcétera. En el apartado de metodología en el resumen defina a cuál tipo de trabajo corresponde el suyo:<br>
                <ol>
                    <li>Trabajos empíricos o con datos primarios describe el diseño, los participantes, el entorno, las intervenciones (instrumentos de recogida de datos…), el análisis estadístico y cualquier otra característica esencial del método a usar.</li>
                    <li>Trabajos teóricos se menciona como funciona la teoría o el modelo, los principios en que se basa, que fenómenos explica la teoría o el modelo y sus vínculos con los resultados empíricos. </li>
                    <li>Trabajos metodológicos se considera el tipo general del método, las características esenciales del método, el rango de aplicación del método, en caso de procedimientos estadísticos, alguna de sus características esenciales. </li>
                    <li>Un estudio de caso, se considera el tema y las características relevantes del individuo u organización, la naturaleza del problema o solución presentada a través del caso y las preguntas con relación a otra investigación o teoría. </li>
                    <li>Un metaanálisis o revisión sistemática de la literatura, defina el problema, los criterios de elegibilidad del problema, los participantes, los resultados, las conclusiones y las implicaciones para la teoría.</li>
                </ol>
            
        </div>
    </ng-template>

    <ng-template #InstIntro let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasTopLabel">Instrucciones Orientativas: INTRODUCCION</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <p style="font-weight: bold;">Se debe incluir la lista de referencias bibliográficas que sustentan este apartado, sobre todo por lo que se refiere a los antecedentes o estado actual del conocimiento sobre el tema o problema de investigación.</p>
            <p>Contenido: </p>
            <ul>
                <li>La introducción debe <b>indicar claramente</b> el problema objeto de investigación, el contexto que explica la importancia del problema y las razones que existen para llevar a cabo la investigación, es decir, por qué el problema merece que se realice una nueva investigación. </li>
                <li>Se menciona y citan los <b>antecedentes</b> más relevantes, es decir, las investigaciones pertinentes anteriores de otros autores o de los mismos autores de la investigación para mostrar el contexto de la investigación, o bien, la teoría reciente relacionada con el tema a tratar. </li>
                <li>Se explica cuál es la <b>originalidad de la investigación</b>, por lo tanto en que se diferencia el abordaje de su trabajo en relación con otros trabajos publicados con anterioridad. </li>
                <li>Se <b>justifica el estudio</b> al identificar las preguntas a las que se está respondiendo. </li>
                <li>Pone de relieve los <b>desafíos del estudio</b> de manera que explica que otras conclusiones, si las hubiere, desafía, contradice o amplia. </li>
                <li><b>¿Y cómo responde a las preguntas que plantea?</b>, ello lo hace al describir brevemente el experimento, hipótesis, pregunta de investigación, y cuál ha sido el diseño experimental general o método; o sea, se expone la hipótesis y su correspondencia con el diseño de la investigación.</li> 
            </ul>
            <p><b>¿Cómo empezar?</b>: Se sugieren dos maneras de empezar a escribir la introducción: </p>
            <ol>
                <li>Mostrando la magnitud del problema en términos de prevalencia, incidencia, morbilidad, mortalidad o costos; </li>
                <li>Citando la controversia actual sobre el tema.</li>
            </ol>
            <p><b>Importante:</b> Siempre que se pretenda publicar una observación muy infrecuente debe precisarse en el texto el método de búsqueda bibliográfica, las palabras clave empleadas, los años de cobertura y la fecha de actualización.</p>
            <p><b>Preguntas a las que responde:</b> En resumen, la introducción se plantea estas preguntas: ¿Cuál es el problema? ¿Existe alguna solución? ¿Cuál es la mejor solución? ¿Cuál es la mayor limitación? ¿Qué se espera lograr?</p>

        </div>
    </ng-template>

</form>
