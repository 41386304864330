import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { UserService } from 'src/app/core/services/user.service';
import { InventoryRecord } from 'src/app/core/models/inventory-record.model';

@Component({
  selector: 'app-inventario-equipo-lista',
  templateUrl: './inventario-equipo-lista.component.html',
  styleUrls: ['./inventario-equipo-lista.component.scss']
})

export class ActaInventarioListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['projectName', 'owner', 'type', 'amount', 'isActive', 'actions'];
  dataSource: MatTableDataSource<InventoryRecord> = new MatTableDataSource<InventoryRecord>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  requests: InventoryRecord[] = [];

  constructor(private userService: UserService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getRequests();
  }

  async getRequests() {
    this.openLoading();
    //this.users = await this.userService.getUsers();
    this.requests = [
      {
        id: 1,
        projectName: '202302-FAIN-001 - Proyecto TEST',
        owner: 'UNACH',
        type: 'EQUIPO COMPUTACIONAL',
        amount: 100000,
        isActive: true,
        actions: []
      }
    ]
    this.requests.map((cp) => { 
      cp.actions = [
        {
          name: 'Ver',
          icon: 'bx bx-search-alt',
          url: `/usuarios/detalle/${cp.id}`
        }
      ]
    });
    this.dataSource.data = this.requests;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  doAction(accion: Action, id: number){
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/usuarios/crear');
  }

}
