<!-- start page title -->
<app-pagetitle title="Formulario Solicitud Orden Egreso" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formSolicitud" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
                    <div class="row justify-content-end">
                        <div class="col col-lg-4">
                            <div class="mb-3">
                                <label for="name">Fecha</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="date">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" formControlName="name" readonly>
                                <label for="name">Nombre Solicitante</label>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="amount" placeholder="40000" formControlName="amount">
                                <label for="amount">Monto Solicitado</label>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="mb-3">
                                <label for="description">Descripción Solicitud</label>
                                <textarea class="form-control" id="description" name="type" rows="3" formControlName="description"></textarea>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
