import { Component, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProyectoWizardComponent } from '../proyecto-wizard/proyecto-wizard.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ProjectWizardService } from 'src/app/core/services/project-wizard.service';

@Component({
  selector: 'app-proyecto-step3',
  templateUrl: './proyecto-step3.component.html',
  styleUrls: ['./proyecto-step3.component.scss']
})
export class ProyectoStep3Component {

  formStep: FormGroup;
  @Input() parentComponent!: ProyectoWizardComponent;
  public Editor = ClassicEditor;

  constructor(
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private projectWizardService: ProjectWizardService,
  ) { 
    this.formStep = this.fb.group({
      pi_contribution_science: [undefined],
      pi_aplicability_results: [undefined],
      pi_transfer_plan: [undefined],
      pi_teaching_support: [undefined]
    });
  }

  async ngOnInit() {
    // Load existing data if available
    this.projectWizardService.getProjectData().subscribe(data => {
      this.formStep.patchValue(data);
    });
  }

  openNoBackdrop(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { backdrop: false, position: 'top', scroll: true });
  }

  nextStep(): void {
    this.parentComponent.nextStep(this.formStep.value);
  }

  save(): void{
    this.parentComponent.updateData(this.formStep.value);
  }

  previousStep(): void {
    this.parentComponent.previousStep(this.formStep.value);
  }

}
