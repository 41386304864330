import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { Faculty } from 'src/app/core/models/faculty.model';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { BasePage } from 'src/app/shared/base-page';

@Component({
    selector: 'app-list-facultad',
    templateUrl: './list-facultad.component.html',
    styleUrls: ['./list-facultad.component.scss']
})
export class ListFacultyComponent extends BasePage implements OnInit{

    displayedColumns: string[] = ['name', 'acronym', 'is_active', 'actions'];
    dataSource!: MatTableDataSource<Faculty>;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    pageSize = 10;

    faculties: Faculty[] = [];

    constructor(
        private facultadesService: FacultadesService,
        injector: Injector,
    ) {
        super(injector);
    }

    override async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.openLoading();
        this.faculties = (await this.facultadesService.getTodasFacultades());
        this.faculties.map((cp) => { 
            cp.actions = [
              {
                name: 'Editar',
                icon: 'bx bx-pencil',
                url: `/facultades/editar/${cp.id}`
              },
              {
                name: 'Ver',
                icon: 'bx bx-search-alt',
                url: `/facultades/detalle/${cp.id}`
              }
            ]
          });
        this.refresh();
        this.closeLoading();
    }

    refresh() {
        this.dataSource = new MatTableDataSource(this.faculties);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    edit(id:number): void {
        this.router.navigateByUrl('/facultades/editar/'+id);
    }

    create(): void {
        this.router.navigateByUrl('/facultades/crear');
    }

    doAction(accion: Action, id: number){
        if(accion.name.indexOf("Editar") > -1) this.edit(id);
    }

}