<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Usuarios</h4>
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary" (click)="create()">Nuevo Usuario</button>
                </div>  
                <form>
                <mat-form-field>
                    <mat-label>Filtro</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese términos para buscar..." #input>
                </mat-form-field>
                </form> 

                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="fullName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                            <td mat-cell *matCellDef="let row"> {{row.fullName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo Electrónico </th>
                            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="isActive">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ¿Activo? </th>
                            <td mat-cell *matCellDef="let row"> {{row.isActive? "SI": "NO"}} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <div *ngFor="let action of row.actions">
                                        <button type="button" class="btn btn-primary" (click)="doAction(action, row.id)" [ngbTooltip]="action.name"><i class="{{action.icon}}"></i></button>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No se encontraron Usuarios</td>
                        </tr>
                    </table>

                    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Seleccione Página de Convocatorias"></mat-paginator>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- end row -->