<!-- start page title -->
<app-pagetitle title="Formulario Facultad" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<form [formGroup]="formFacultad" class="example-form">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
        
                    <div class="row">
                        <div class="col col-lg-10">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Ej. Facultad de Ingeniería" formControlName="nombre">
                                <label for="name">Nombre Facultad</label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="mb-4">
                                <h5 class="font-size-14 mb-2">Estado</h5>
                                <div class="form-check form-switch form-switch-lg mb-lg-3" dir="ltr">
                                    <input class="form-check-input" type="checkbox" id="formCheckActivo" formControlName="activo">
                                    <label class="form-check-label" for="formCheckActivo">
                                        {{ formFacultad.value.activo ? 'Activo' : 'Inactivo' }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="acronym" placeholder="Ej. FAIN" formControlName="acronimo">
                                <label for="acronym">Acrónimo Facultad</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelectDecano" formControlName="decano" name="decano" required>
                                    <option value="">Decano</option>
                                    <option *ngFor="let user of users" [value]="user.id">
                                        {{user.fullName}}
                                    </option>
                                </select>
                                <label for="floatingSelectDecano">Decano</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- end row -->
