import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { Role } from 'src/app/core/models/role.model';
import { SupportLetter } from 'src/app/core/models/support_letter.model';
import { SupportLetterService } from 'src/app/core/services/support-letter.service';

@Component({
  selector: 'app-carta-apoyo-lista',
  templateUrl: './carta-apoyo-lista.component.html',
  styleUrls: ['./carta-apoyo-lista.component.scss']
})
export class CartaApoyoListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['name', 'faculty', 'dean', 'isSigned', 'dateSigned', 'actions'];
  dataSource: MatTableDataSource<SupportLetter> = new MatTableDataSource<SupportLetter>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  supportLetters: SupportLetter[] = [];

  constructor(private supportLetterService: SupportLetterService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getSupportLetters();
  }

  async getSupportLetters() {
    this.openLoading();
    this.supportLetters = await this.supportLetterService.getSupportLetters();
    this.supportLetters.map((cp) => { 
      cp.actions = [
        {
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/cartas-apoyo/editar/${cp.id}`
        },
        {
          name: 'Ver PDF',
          icon: 'bx bx-search-alt',
          url: `/api/supportletter/pdf?id=${cp.id}`
        }
      ]
    });
    this.dataSource.data = this.supportLetters;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  getPermissionAmount(role: Role){
    return role.siteSections?.length || 0;
  }

  doAction(accion: Action, id: number){
    if(accion.name === 'Ver PDF') window.open(accion.url as string, '_blank');
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/cartas-apoyo/crear');
  }

}
