<!-- start page title -->
<app-pagetitle title="Creando Convocatoria" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formCallPeriod">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Ej. Primera Convocatoria 2024" formControlName="name">
                                <label for="name">Nombre Convocatoria</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="year" placeholder="Ej. 2024" formControlName="year">
                                <label for="year">Año Convocatoria</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="code" placeholder="Ej. 202401" formControlName="code">
                                <label for="code">Código Convocatoria</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-6">
                            <label for="startDate">Fecha Inicio Convocatoria</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="startDate">
                        </div>
                        <div class="col-lg-6">
                            <label for="endDate">Fecha Fin Convocatoria</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr dateFormat="d/m/Y" formControlName="endDate">
                        </div>
                    </div>
                    <div class="d-flex flex-row-reverse">
                        <button class="btn btn-primary" type="submit" (click)="save()">Guardar Convocatoria</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>