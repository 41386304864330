import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Rubric, RubricRequest } from '../models/rubrica.model';

@Injectable({
  providedIn: 'root'
})
export class RubricService {

  constructor(private http: HttpClient) { }

  getRubrics(): Promise<Rubric[]> {
    return lastValueFrom(this.http.get<Rubric[]>(`${environment.apiURL}/rubric/list`));
  }

  getRubric(idRubric: Number): Promise<Rubric> {
    return lastValueFrom(this.http.get<Rubric>(`${environment.apiURL}/rubric/${idRubric}`));
  }

  postRubric(data: RubricRequest): Promise<Rubric> {
    return lastValueFrom(this.http.post<Rubric>(`${environment.apiURL}/rubric`, data));
  }

  putRubric(data: RubricRequest, idRubric:Number): Promise<Rubric> {
    return lastValueFrom(this.http.put<Rubric>(`${environment.apiURL}/rubric/${idRubric}`, data));
  }
}
