import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-proyecto-lista',
  templateUrl: './proyecto-lista.component.html',
  styleUrls: ['./proyecto-lista.component.scss']
})
export class ProyectoListaComponent extends BasePage implements OnInit {

  displayedColumns: string[] = ['folio', 'current_status', 'title', 'faculty', 'career', 'academic', 'actions'];
  dataSource: MatTableDataSource<Project> = new MatTableDataSource<Project>();

  actions: Action[] = [];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  projects: Project[] = [];

  constructor(private projectService: ProjectService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getProjects();
    this.setupFilter();
  }

  async getProjects() {
    this.openLoading();
    this.projects = await this.projectService.getProjectsCurrentCallPeriod();
    /*this.projects.map((p) => { 
      p.actions = [
        {
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/proyectos/editar/${p.id}`
        },
        {
          name: 'Ver',
          icon: 'bx bx-search-alt',
          url: `/proyectos/detalle/${p.id}`
        }
      ]
    });*/
    this.dataSource.data = this.projects;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  setupFilter() {
    this.dataSource.filterPredicate = (data, filter): boolean => {
      const lowerCaseFilter = filter.toLowerCase();

      return (
        data.folio.toLocaleLowerCase().indexOf(lowerCaseFilter) !== -1 ||
        data.title.toLowerCase().indexOf(lowerCaseFilter) !== -1 ||
        (data.faculty && data.faculty.name?.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
        (data.career && data.career.name?.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
        (data.academic && data.academic.fullName?.toLowerCase().indexOf(lowerCaseFilter) !== -1) || // Academic
        (this.getStatusName(data.current_status || '').toLowerCase().indexOf(lowerCaseFilter) !== -1)
      );
    };
  }

  doAction(accion: Action, id: number){
    if(accion.name == "Enviar") this.changeStatus('E', accion.name, id);
    else {
      this.router.navigateByUrl(accion.url as string);
    }
  }

  create(): void {
    this.router.navigateByUrl('/proyectos/crear');
  }

  getStatusName(s: string) {
    switch(s) {
      case 'C': return 'CREADO'; break;
      case 'E': return 'ENVIADO'; break;
      case 'PA': return 'PROCESO AUTORIZACION'; break;
      case 'EV': return 'EVALUADO'; break;
      case 'PC': return 'PROCESO CONTRATACION'; break;
      case 'CT': return 'CONTRATADO'; break;
      default: return s; break;
    }
  }

  async changeStatus(s: string, name: String, id: number) {
    //Confirm dialog
    let resp = await Swal.fire({
      title: "Confirmación", 
      text: `¿Está seguro que desea ${name} el Proyecto?`,
      icon: 'warning',
      confirmButtonText: `Si, ${name}`,
      cancelButtonText: 'No, cancelar',
      showCancelButton: true
    });

    if(resp.isConfirmed){
      let ret = await this.projectService.changeStatus(s, id);
      if(ret == 'OK'){
        await this.globalSuccess(`Se ha cambiado el estado del Proyecto correctamente`);
        this.getProjects();
      }
    }
  }

}

