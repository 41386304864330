<!-- start page title -->
<app-pagetitle title="Mis Acciones Pendientes" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="row">
    <div class="col-12" *ngIf="getDirEvals().length > 0">
        <div class="card">
            <div class="card-body">
                <h4>Mis Acciones Pendientes (DIRINVEST)</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th><b>Proyecto</b></th>
                                <th><b>Acción</b></th>
                                <th><b>Fecha Creación</b></th>
                                <th><b>Tiempo</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of getDirEvals()">
                                <td>{{item.project_title}}</td>
                                <td>EVALUACION DIRINVEST</td>
                                <td>{{item.created_date}}</td>
                                <td>
                                    <span 
                                        class="badge"
                                        [ngClass]="{
                                            'bg-success': item.days_since_creation < 30,
                                            'bg-warning': item.days_since_creation >= 30 && item.days_since_creation <= 60,
                                            'bg-danger': item.days_since_creation > 60
                                          }"
                                        >
                                            {{item.days_since_creation}} dias
                                    </span>
                                </td>
                                <td><button class="btn btn-icon btn-sm btn-primary" type="button" ngbTooltip="Ir a la Acción" (click)="goToDirectiveReview(item.project_id)"><i class="bx bx-right-arrow-circle"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12" *ngIf="getFacultyTasks().length > 0">
        <div class="card">
            <div class="card-body">
                <h4>Mis Acciones Pendientes (FACULTAD)</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th><b>Proyecto</b></th>
                                <th><b>Acción</b></th>
                                <th><b>Fecha Creación</b></th>
                                <th><b>Tiempo</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of getFacultyTasks()">
                                <td>{{item.project_title}}</td>
                                <td>{{item.context}}</td>
                                <td>{{item.created_date}}</td>
                                <td>
                                    <span 
                                        class="badge"
                                        [ngClass]="{
                                            'bg-success': item.days_since_creation < 30,
                                            'bg-warning': item.days_since_creation >= 30 && item.days_since_creation <= 60,
                                            'bg-danger': item.days_since_creation > 60
                                          }"
                                        >
                                            {{item.days_since_creation}} dias
                                    </span>
                                </td>
                                <td><button class="btn btn-icon btn-sm btn-primary" type="button" ngbTooltip="Ir a la Acción" (click)="goToFacultyTask(item)"><i class="bx bx-right-arrow-circle"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12" *ngIf="getPeerReviews().length > 0">
        <div class="card">
            <div class="card-body">
                <h4>Mis Acciones Pendientes (PAR EVALUADOR)</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th><b>Facultad</b></th>
                                <th><b>Acción</b></th>
                                <th><b>Fecha Creación</b></th>
                                <th><b>Tiempo</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of getPeerReviews()">
                                <td>{{item.project_title}}</td>
                                <td>{{item.context}}</td>
                                <td>{{item.created_date}}</td>
                                <td>
                                    <span 
                                        class="badge"
                                        [ngClass]="{
                                            'bg-success': item.days_since_creation < 30,
                                            'bg-warning': item.days_since_creation >= 30 && item.days_since_creation <= 60,
                                            'bg-danger': item.days_since_creation > 60
                                          }"
                                        >
                                            {{item.days_since_creation}} dias
                                    </span>
                                </td>
                                <td><button class="btn btn-icon btn-sm btn-primary" type="button" ngbTooltip="Ir a la Acción" (click)="goToPeerReview(item.project_id)"><i class="bx bx-right-arrow-circle"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end row -->
