import { Component, Input, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ProyectoWizardComponent } from '../proyecto-wizard/proyecto-wizard.component';
import { ProjectWizardService } from 'src/app/core/services/project-wizard.service';
import { Objective } from 'src/app/core/models/objective.model';
import { EstimateExpense, EstimateExpenseRequest } from 'src/app/core/models/estimate_expense.model';
import { Project } from 'src/app/core/models/project.model';

export interface Expense{
  category: string;
  amount: number;
  description: string;
  price: number;
  objective: number;
}

@Component({
  selector: 'app-proyecto-step6',
  templateUrl: './proyecto-step6.component.html',
  styleUrls: ['./proyecto-step6.component.scss']
})
export class ProyectoStep6Component {

  formStep: FormGroup;
  @Input() parentComponent!: ProyectoWizardComponent;

  secciones = [
    {
      value: 'OPERACION',
      name: '1. GASTOS DE OPERACIÓN',
      categories: [
        {id:1, name:'1.1 Maquinas y equipos'}, 
        {id:2, name:'1.2 Software y licencias'}, 
        {id:3, name:'1.3 Insumos de Laboratorio'}
      ]
    },
    {
      name: '2. MATERIALES DE OFICINA',
      categories: [
        {id:4, name:'2.1 Materiales de Oficina )paple, lápices, corchetera, fotocopias, etc'}, 
        {id:5, name:'2.2 Otros'}
      ]
    },
    {
      name: '3. HONORARIOS ASISTENTES',
      categories: [
        {id:6, name:'3.1 Alumnos Ayudantes (max. $200.000 por alumno y año'}, 
        {id:7, name:'3.2 Tesistas de pregrado (máximo $200.000 por tesista y semestre)'},
        {id:8, name:'3.3 Tesistas de posgrado (máximo $400.000 por tesista y semestre)'},
        {id:18, name:'3.4 Colaboradores Profesionales Externos'}
      ]
    },
    {
      name: '4. VIAJES PARA REALIZAR EL PROYECTO',
      categories: [
        {id:9, name:'4.1 Movilización (máximo $100.000 en Chile y 200.000 en el extranjero)'}, 
        {id:10, name:'4.1.1 Movilización para recoger datos o consultas bibliográficas'},
        {id:11, name:'4.1.2 Movilización para asesoría científica'},
      ]
    },
    {
      name: '5. PRESENTACIÓN DE LA INVESTIGACIÓN EN EVENTOS CIENTÍFICOS O ACADÉMICOS',
      categories: [
        {id:12, name:'5.1 Gastos de impresión de pósteres, inscripción a congresos, movilización, viáticos, alojamiento investigación (máximo $100.000/evento, y con un máximo de un evento científico anual)'}, 
      ]
    },
    {
      name: '6. PUBLICACIÓN DE LA INVESTIGACIÓN',
      categories: [
        {id:13, name:'6.1. Traducir el artículo para publicar en revista especializada'}, 
        {id:14, name:'6.2 Gastos de edición'},
        {id:15, name:'6.3 Gastos de publicación'},
        {id:16, name:'6.4 Otros'}
      ]
    },
    {
      name: '7. OTROS',
      categories: [
        {id:17, name:'7.1 Otros'}, 
      ]
    }
  ];

  expenses:EstimateExpenseRequest[] = [];
  projectObjectives: Objective[] = [];
  projectId: number = 0;
  deletedExpenses: number[] = [];

  constructor(
    private fb: FormBuilder,
    private projectWizardService: ProjectWizardService
  ) { 
    this.formStep = this.fb.group({
      estimate_expenses: this.fb.array([]),
      deleted_expenses: [undefined]
    });
  }

  async ngOnInit() {
    // Load existing data if available
    //remove all expenses
    this.estimateExpenses.clear();
    this.deletedExpenses = [];
    this.expenses = [];
  
    this.projectWizardService.getProjectData().subscribe(data => {
      this.formStep.patchValue(data);
      this.projectId = data.id || 0;
      this.projectObjectives = data.pi_objectives || [];
      for(let expense of data.estimate_expenses || []) {
        this.expenses.push({
          category: expense.category!.id, 
          amount: expense.amount, 
          name: expense.name, 
          unit_cost: expense.unit_cost, 
          objective: expense.objective,
          section: this.getSectionFromCat(expense.category!.id),
          project: this.projectId,
          id: expense.id,
          attachmentBase64: expense.attachmentBase64,
          attachmentName: expense.attachmentName,
          attachmentId: expense.attachmentId
        });
      }
    });
  }

  get estimateExpenses(): FormArray {
    return this.formStep.get('estimate_expenses') as FormArray;
  }

  getSectionFromCat(cat: number) {
    return this.secciones.find(x => x.categories.find(y => y.id === cat))?.name || '';
  }

  addExpense(cat: number) {
    this.expenses.push({
      category: cat, 
      amount: 0, 
      name: '', 
      unit_cost: 0, 
      objective: 0,
      section: this.getSectionFromCat(cat),
      project: this.projectId
    });
  }

  getExpensesFromCat(cat: number) {
    return this.expenses.filter(x => x.category === cat);
  }

  getExpenseTotal(e: EstimateExpenseRequest) {
    return e.unit_cost * e.amount;
  }

  getSectionTotal(section: any): number {
    let total = 0;
    for(let category of section.categories) {
      const expenses = this.getExpensesFromCat(category.id);
      for (let expense of expenses) {
        total += (expense.amount * expense.unit_cost);
      }
    }
    return total;
  }

  getGrandTotal(): number {
    let total = 0;

    for (let expense of this.expenses) {
      total += (expense.amount * expense.unit_cost);
    }
    
    return total;
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP', minimumFractionDigits: 0 }).format(value);
  }

  prepareExpenses(): void {
    this.formStep.patchValue({
      deleted_expenses: JSON.stringify(this.deletedExpenses)
    });
    const expensesArray = this.formStep.get('estimate_expenses') as FormArray;
    expensesArray.clear();
    for (let expense of this.expenses) {
      expensesArray.push(createEstimateExpenseFormGroup(expense));
    }
  }

  onFileSelected(event: any, expense: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = (reader.result as string).split(',')[1]; // Obtiene la cadena Base64 sin el prefijo
        expense.attachmentBase64 = base64;
        expense.attachmentName = file.name; // Almacena también el nombre del archivo
      };
      reader.readAsDataURL(file); // Lee el archivo como una URL de datos (Base64)
    }
  }

  // Función para eliminar el archivo seleccionado
  removeFile(expense: any): void {
    expense.attachmentBase64 = null;
    expense.attachmentName = null;
    expense.attachmentId = null; // Limpia el ID para que no se envíe al guardar
  }

  nextStep(): void {
    this.prepareExpenses();
    this.parentComponent.nextStep();
  }

  save(): void{
    this.prepareExpenses();
    this.parentComponent.updateData(this.formStep.value);
  }

  previousStep(): void {
    this.prepareExpenses();
    this.parentComponent.previousStep();
  }

  removeExpense(expense: EstimateExpenseRequest): void {
    const index = this.expenses.indexOf(expense);
    if (index >= 0) {
      this.expenses.splice(index, 1);
      if(expense.id){
        this.deletedExpenses.push(expense.id);
      }
    }
  }

}
function createEstimateExpenseFormGroup(expense: EstimateExpenseRequest): any {
  return new FormGroup({
    name: new FormControl(expense.name),
    section: new FormControl(expense.section),
    unit_cost: new FormControl(expense.unit_cost),
    amount: new FormControl(expense.amount),
    objective: new FormControl(expense.objective),
    project: new FormControl(expense.project),
    category: new FormControl(expense.category),
    id: new FormControl(expense.id),
    attachmentBase64: new FormControl(expense.attachmentBase64),
    attachmentName: new FormControl(expense.attachmentName),
    attachmentId: new FormControl(expense.attachmentId)
  });
}


