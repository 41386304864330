<!-- start page title -->
<app-pagetitle title="Formulario Costos Indirectos" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formIndirectCost">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="academic" value="{{project?.academic?.fullName}}" readonly>
                                <label for="academic">Investigador Responsable</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="rut_academic" value="{{project?.rut_academic}}" readonly>
                                <label for="rut_academic">RUT</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="academic_gender" value="{{project?.gender_academic}}" readonly>
                                <label for="academic_gender">Género</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email_academic" value="{{project?.academic?.email}}" readonly>
                                <label for="email_academic">Correo Electrónico</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="phone_academic" value="{{project?.phone_academic}}" readonly>
                                <label for="phone_academic">Teléfono UnACh</label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="cell_academic" value="{{project?.cell_academic}}" readonly>
                                <label for="cell_academic">Celular</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="approvalCode" [value]="approvalCode" readonly>
                                <label for="approvalCode">N.º y fecha del acta del Consejo de Investigación de aprobación</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="directCosts" [value]="workload?.recomended_budget" readonly>
                                <label for="directCosts">Presupuesto concedido (costos directos)</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th rowspan="3">Investigador / Estudiante</th>
                                    <th rowspan="3">Facultad / Unidad</th>
                                    <th [attr.colspan]="semesters.length + 2">N° horas cronológicas* asignadas para el Proyecto**</th>
                                    <th colspan="2">Costos Indirectos</th>
                                </tr>
                                <tr>
                                    <th [attr.colspan]="semesters.length">N° horas</th>
                                    <th rowspan="2">N° semanas</th>
                                    <th rowspan="2">N° horas totales</th>
                                    <th rowspan="2">Valor Hora*</th>
                                    <th rowspan="2">Costo Total*</th>
                                </tr>
                                <tr>
                                    <th *ngFor="let sem of semesters">
                                        {{ sem.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pe of personEstimations; let i = index">
                                    <td>{{pe.rut}}<br>{{pe.name}}</td>
                                    <td>{{pe.faculty?.acronym}}</td>
                                    <td *ngFor="let hourIndex of getWeeklyHoursControls(i)">{{hourIndex}}</td>
                                    <td>{{pe.weeks}}</td>
                                    <td>{{getPersonTotalHours(i)}}h</td>
                                    <td>${{hourly_rate | number}}</td>
                                    <td>{{formatCurrency(getPersonTotalCost(i))}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td [attr.colspan]="semesters.length + 3" style="text-align: right;">TOTAL</td>
                                    <td>{{getTotalHours()}}h</td>
                                    <td></td>
                                    <td>{{formatCurrency(getTotalCost())}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="userDetails.id === indirectCost?.user?.id && !indirectCost?.isSigned">
                <div class="card-body row" style="text-align: center;">
                    <div class="col col-lg-8">
                        <div class="form-check form-check-warning mb-3">
                            <input class="form-check-input" type="checkbox" id="formCheckcolor4" formControlName="isSigned" style="width: 30px; height: 30px; margin-right: 15px">
                            <label class="form-check-label" for="formCheckcolor4" style="line-height: 33px;">
                                ¿Firmar Formulario de Costos Indirectos?
                            </label>
                        </div>
                    </div>
                    <div class="col col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="rut" placeholder="Ingrese su RUT para confirmar" formControlName="rut">
                            <label for="rut">R.U.T. Firmante</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card text-white bg-warning" *ngIf="!indirectCost?.isSigned && userDetails.id !== indirectCost?.user?.id">
                <div class="card-body" style="text-align: center;">
                    <div class="col col-lg-12">
                        <div class="form-check mb-3">
                            <h5>Carta de Apoyo Firmada NO FIRMADA</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card text-white bg-success" *ngIf="indirectCost?.isSigned">
                <div class="card-body" style="text-align: center;">
                    <div class="col col-lg-12">
                        <div class="form-check mb-3">
                            <h5>Carta de Apoyo Firmada Digitalmente el {{indirectCost?.dateSigned | date: 'dd/MM/yyyy'}}, por {{this.indirectCost?.user?.fullName}} - RUT: {{this.indirectCost?.rut || ''}}</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">

                    <div class="d-flex justify-content-between">
                        <!-- Left-aligned button -->
                        <button class="btn btn-secondary" type="button" (click)="previewPDF()">
                            <i class="bx" [ngClass]="{'bxs-file-pdf': isSigned, 'bxs-search': !isSigned}"></i>
                            {{isSigned ? "Ver PDF Firmado": "Previsualizar PDF"}}
                        </button>
                    
                        <!-- Right-aligned buttons -->
                        <div>
                          <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">
                              <i class="bx bxs-x-square"></i> Cancelar
                          </button>
                          <button class="btn btn-primary" type="button" (click)="save()" [disabled]="!formIndirectCost.valid">
                              <i class="bx bxs-check-square"></i> Continuar
                          </button>
                        </div>
                      </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>