import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PersonEstimation } from '../models/person_estimation.model';
import { IndirectCost } from '../models/indirect_cost.model';
import { Mensaje, SignDocument } from "../models/utils.model";

@Injectable({ providedIn: 'root' })
export class IndirectCostService {
    
    constructor(private http: HttpClient) { }

    getIndirectCosts() {
        return lastValueFrom(this.http.get<IndirectCost[]>(`${environment.apiURL}/indirectcost`));
    }

    getIndirectCost(id:number) {
        return lastValueFrom(this.http.get<IndirectCost>(`${environment.apiURL}/indirectcost/${id}`));
    }

    getPersonEstimation(project_id:number, faculty_id:number) {
        return lastValueFrom(this.http.get<PersonEstimation[]>(`${environment.apiURL}/personestimation/?project=${project_id}&faculty=${faculty_id}`));
    }

    putIndirectCost(id:number, data: SignDocument) {
        return lastValueFrom(this.http.put<Mensaje>(`${environment.apiURL}/indirectcost/${id}`, data));
    }

    getIndirectCostsByProject(id: number): Promise<IndirectCost[]> {
        return lastValueFrom(this.http.get<IndirectCost[]>(`${environment.apiURL}/indirectcost?project=${id}`));
    }

}