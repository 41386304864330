import { Component, Injector, OnInit } from '@angular/core';
import { flatpickrFactory } from '../../pages.module';
import { BasePage } from 'src/app/shared/base-page';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { CarrerasService } from 'src/app/core/services/carreras.service';
import { Faculty } from 'src/app/core/models/faculty.model';
import { Career } from 'src/app/core/models/career.model';
import {
    AcademicActivity,
    AcademicTitle,
    Curriculum,
    CurriculumRequest,
    Publications
} from "../../../core/models/curriculum.model";
import {CurriculumService} from "../../../core/services/curriculum.service";

@Component({
  selector: 'app-curriculum-view',
  templateUrl: './curriculum-view.component.html',
  styleUrls: ['./curriculum-view.component.scss']
})
export class CurriculumViewComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formCurriculum!: FormGroup;
  curriculum?: Curriculum = undefined;
  idCurriculum: number = 0;
  rut: string = '';

  faculty: number = 0;

  faculties: Array<Faculty> = [];
  careers: Array<Career> = [];

  degrees: any = {
    'LIC': 'Licenciado',
    'MG': 'Magister',
    'PHD': 'Doctorado'
  }

  regimes: any = {
    'LIC': 'Tiempo Completo',
    'MG': 'Parcial',
    'PHD': 'Honorarios'
  }

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private curriculumService: CurriculumService,
    private facultiesService: FacultadesService,
    private careersService: CarrerasService,
    injector: Injector
  ) {
    super(injector);
    this.formCurriculum = this.fb.group({
      name: [undefined],
      rut: [undefined],
      email: [undefined],
      phone: [undefined],
      mobile: [undefined],
      faculty: [undefined],
      career: [undefined],
      activities: this.fb.array([]),
      publications: this.fb.array([]),
      titles: this.fb.array([])
    });
  }

  async ngOnInit() {

    super.ngOnInit();
    this.openLoading();
    this.faculties = await this.facultiesService.getTodasFacultadesActivas();
    this.careers = await this.careersService.getTodasCarrerasActivas();
    this.closeLoading();

    //BreadCrumb
    this.breadCrumbItems = [
      { label: 'Currículum' },
      { label: 'Ver Currículum', active: true }
    ];

    this.route.params.pipe(
      switchMap(params => {
          this.idCurriculum = params['id'];
          this.rut = params['rut'];

          if (this.idCurriculum) {
              return this.curriculumService.getCurriculum(this.idCurriculum);
          }

          if(this.rut) {
            let curriculum: Curriculum = {
              rut: this.rut,
              name: '',
              email: '',
              phone: '',
              mobile: '',
              faculty: new Faculty,
              career: new Career,
              titles: [],
              activities: [],
              publications: [],
              id: 0,
              createdAt: new Date(),
              updatedAt: new Date(),
              actions: []
            };
            return of(curriculum);
          }

          // If there's no idCallPeriod, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: (response) => {
            if (response ) {
              this.curriculum = response;
                response.titles.forEach(element => {
                    this.titles.controls.push(this.fb.group({
                        year: this.fb.control(element.year),
                        degree: this.fb.control(element.degree),
                        institution: this.fb.control(element.institution)
                    }));
                });
                response.activities.forEach(element => {
                    this.activities.controls.push(this.fb.group({
                        period: this.fb.control(element.period),
                        activity: this.fb.control(element.activity),
                        regime: this.fb.control(element.regime),
                        institution: this.fb.control(element.institution)
                    }));
                });
                response.publications.forEach(element => {
                    this.publications.controls.push(this.fb.group({
                        authors: this.fb.control(element.authors),
                        title: this.fb.control(element.title),
                        magazine: this.fb.control(element.magazine),
                        year: this.fb.control(element.year),
                        volume: this.fb.control(element.volume),
                        number: this.fb.control(element.number),
                        pages: this.fb.control(element.pages),
                        keyword: this.fb.control(element.keyword),
                        webLink: this.fb.control(element.webLink),
                        ddi: this.fb.control(element.ddi),
                        isWosisi: this.fb.control({value: element.isWosisi, disabled: true}),
                        isScopus: this.fb.control({value: element.isScopus, disabled: true}),
                        isScielo: this.fb.control({value: element.isScielo, disabled: true}),
                        isLatindex: this.fb.control({value: element.isLatindex, disabled: true}),
                        isIndenxed: this.fb.control({value: element.isIndenxed, disabled: true}),
                        another: this.fb.control(element.another)
                    }));
                });


                this.formCurriculum.patchValue({
                    career: response.career,
                    email: response.email,
                    faculty: response.faculty,
                    mobile: response.mobile,
                    name: response.name,
                    phone: response.phone,
                    rut: response.rut
                });
            }

            this.breadCrumbItems = [
              { label: 'Currículum' },
              { label: 'Ver Currículum', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Call Period:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  careersForFaculty(facultyId: number) {
    if(facultyId === 0) return this.careers;
    return this.careers.filter(career => career.faculty.id == facultyId);
  }

  async save() {
    let datos = this.formCurriculum.value;
    let titles: AcademicTitle[] = [];
    this.titles.controls.forEach(element => {
        let obj: AcademicTitle = {
            degree: element.value.degree,
            institution: element.value.institution,
            year: element.value.year
        };
        titles.push(obj);
    });
    let activities: AcademicActivity[] = [];
    this.activities.controls.forEach(element => {
        let obj: AcademicActivity = {
            period: element.value.period,
            activity: element.value.activity,
            regime: element.value.regime,
            institution: element.value.institution
        };
        activities.push(obj);
    });
    let publications: Publications[] = [];
    this.publications.controls.forEach(element => {
        let obj: Publications = {
            year: element.value.year,
            title: element.value.title,
            another: element.value.another,
            authors: element.value.authors,
            pages: element.value.pages,
            volume: element.value.volume,
            magazine: element.value.magazine,
            number: element.value.number,
            webLink: element.value.webLink,
            keyword: element.value.keyword,
            ddi: element.value.ddi,
            isIndenxed: element.value.isIndenxed,
            isLatindex: element.value.isLatindex,
            isScielo: element.value.isScielo,
            isScopus: element.value.isScopus,
            isWosisi: element.value.isWosisi
        };
        publications.push(obj);
    });

    let request: CurriculumRequest = {
        rut: datos.rut,
        career: datos.career,
        email: datos.email,
        faculty: datos.faculty,
        mobile: datos.mobile,
        name: datos.name,
        phone: datos.phone,
        titles: titles,
        activities: activities,
        publications: publications
    };

    this.openLoading();
    if (this.idCurriculum) {
        let data = await this.curriculumService.putCurriculum(this.idCurriculum, request);
        this.closeLoading();
        await this.globalSuccess(data.value);
        this.router.navigateByUrl('projectos/lista' );
    } else {
        let data = await this.curriculumService.postCurriculum(request);
        this.closeLoading();
        await this.globalSuccess(data.value);
        this.router.navigateByUrl('projectos/lista');
    }
  }

  cancel(): void {
    this.router.navigateByUrl('curriculos');
  }

  get titles(): FormArray {
    return this.formCurriculum.get('titles') as FormArray;
  }

  get activities(): FormArray {
    return this.formCurriculum.get('activities') as FormArray;
  }

  get publications(): FormArray {
    return this.formCurriculum.get('publications') as FormArray;
  }

  addTitles() {
    this.titles.controls.push(this.fb.group({
        year: this.fb.control(undefined),
        degree: this.fb.control(''),
        institution: this.fb.control('')
    }));
  }

  addActivities() {
    this.activities.controls.push(this.fb.group({
        period: this.fb.control(''),
        activity: this.fb.control(''),
        regime: this.fb.control(''),
        institution: this.fb.control('')
    }));
  }

  addPublications() {
    this.publications.controls.push(this.fb.group({
        authors: this.fb.control(''),
        title: this.fb.control(''),
        magazine: this.fb.control(''),
        year: this.fb.control(''),
        volume: this.fb.control(''),
        number: this.fb.control(''),
        pages: this.fb.control(''),
        keyword: this.fb.control(''),
        webLink: this.fb.control(''),
        ddi: this.fb.control(''),
        isWosisi: this.fb.control(false),
        isScopus: this.fb.control(false),
        isScielo: this.fb.control(false),
        isLatindex: this.fb.control(false),
        isIndenxed: this.fb.control(false),
        another: this.fb.control('')
    }));
  }

    removeTitles(item: any) {
        this.titles.controls.splice(this.titles.controls.indexOf(item), 1);
    }

    removeActivities(item: any) {
        this.activities.controls.splice(this.activities.controls.indexOf(item), 1);
    }

    removePublications(item: any) {
        this.publications.controls.splice(this.publications.controls.indexOf(item), 1);
    }

}
