import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupportLetter, SupportLetterRequest } from '../models/support_letter.model';
import { PersonEstimation } from '../models/person_estimation.model';

@Injectable({ providedIn: 'root' })
export class SupportLetterService {
    
    constructor(private http: HttpClient) { }

    getSupportLetters() {
        return lastValueFrom(this.http.get<SupportLetter[]>(`${environment.apiURL}/supportletter`));
    }

    getSupportLetter(id:number) {
        return lastValueFrom(this.http.get<SupportLetter>(`${environment.apiURL}/supportletter/${id}`));
    }

    getSupportLettersByProject(id:number): Promise<SupportLetter[]> {
        return lastValueFrom(this.http.get<SupportLetter[]>(`${environment.apiURL}/supportletter/?project=${id}`));
    }

    getPersonEstimation(project_id:number, faculty_id:number) {
        return lastValueFrom(this.http.get<PersonEstimation[]>(`${environment.apiURL}/personestimation/?project=${project_id}&faculty=${faculty_id}`));
    }

    putSupportLetter(data: SupportLetterRequest, id: number): Promise<SupportLetter>{
        return lastValueFrom(this.http.put<SupportLetter>(`${environment.apiURL}/supportletter/${id}`, data));
    }
}