import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/shared/base-page';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { SiteSection } from 'src/app/core/models/site_section.model';
import { Rubric, RubricRequest } from 'src/app/core/models/rubrica.model';
import { RubricService } from 'src/app/core/services/rubric.service';

@Component({
  selector: 'app-rubrica-formulario',
  templateUrl: './rubrica-formulario.component.html',
  styleUrls: ['./rubrica-formulario.component.scss']
})
export class RubricaFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formRubric: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    type: ['', [Validators.required]],
    isActive: [true],
    evalJSON: ['', [Validators.required]],
    minMR: ['', [Validators.required]],
    maxMR: ['', [Validators.required]],
    minR: ['', [Validators.required]],
    maxR: ['', [Validators.required]],
    minNR: ['', [Validators.required]],
    maxNR: ['', [Validators.required]],
    categories: this.fb.array([])
  });

  rubric?: Rubric = undefined;
  idRubric: number = 0;

  types = [
    {
      id: 'DIRECTIVE',
      name: 'DIRECTIVA'
    },
    {
      id: 'PEER',
      name: 'PARES'
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private rubricService: RubricService,
    injector: Injector
  ) { 
    super(injector);

    
  }

  async ngOnInit() {

    super.ngOnInit();
    this.openLoading();
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Rúbricas' },
      { label: 'Crear Rúbrica', active: true }
    ];

    this.route.params.pipe(
      switchMap(params => {
          this.idRubric = params['id'];

          if (this.idRubric) {
              return this.rubricService.getRubric(this.idRubric);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: (response) => {
            if (response ) {
                this.rubric = response;

                this.formRubric.patchValue({
                  name: this.rubric.name,
                  type: this.rubric.type,
                  isActive: this.rubric.isActive
                });

                if(this.rubric.evalJSON) {
                  const evalData =  JSON.parse(this.rubric.evalJSON);

                  let mr: any = null;
                  let r: any = null;
                  let nr: any = null;

                  for(let e of evalData.evals){
                    if(e.name === 'Muy Recomendado'){ mr = e; }
                    if(e.name === 'Recomendado'){ r = e; }
                    if(e.name === 'No Recomendado'){ nr = e; }
                  }


                  if(mr) {
                    this.formRubric.patchValue({ minMR: mr.min });
                    this.formRubric.patchValue({ maxMR: mr.max });
                  }
                  if(r) {
                    this.formRubric.patchValue({ minR: r.min });
                    this.formRubric.patchValue({ maxR: r.max });
                  }
                  if(nr) {
                    this.formRubric.patchValue({ minNR: nr.min });
                    this.formRubric.patchValue({ maxNR: nr.max });
                  }
                }

                // Iterate over categories in the JSON data
                this.rubric.categories.forEach((categoryData: any) => {
                  const categoryGroup = this.fb.group({
                    name: categoryData.name,
                    questions: this.fb.array([])
                  });

                  // Add category form group to the categories FormArray
                  this.categories.push(categoryGroup);

                  // Get the questions FormArray of the newly added category
                  const questionsArray = categoryGroup.get('questions') as FormArray;

                  // Iterate over questions in the category data
                  categoryData.questions.forEach((questionData: any) => {
                    // Create a new question form group
                    const questionGroup = this.fb.group({
                      question: '',
                      options: this.fb.array([])
                    });

                    // Patch the question form group
                    questionGroup.patchValue(questionData);

                    // Add the patched question group to the questions FormArray
                    questionsArray.push(questionGroup);

                    // Get the options FormArray of the newly added question
                    const optionsArray = questionGroup.get('options') as FormArray;

                    // Iterate over options in the question data
                    questionData.options.forEach((optionData: any) => {
                      // Create a new option form group
                      const optionGroup = this.fb.group({
                        option: '',
                        min: '',
                        max: ''
                      });

                      // Patch the option form group
                      optionGroup.patchValue({
                        option: optionData.option,
                        min: optionData.minValue,
                        max: optionData.maxValue
                      });

                      // Add the patched option group to the options FormArray
                      optionsArray.push(optionGroup);
                  });
                });
              });

            }

            this.breadCrumbItems = [
              { label: 'Rúbricas' },
              { label: 'Editar Rúbrica', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Rubric:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  get categories(): FormArray {
    return this.formRubric.get('categories') as FormArray;
  }

  questionsArray(i:number): FormArray {
    return this.categories.at(i).get('questions') as FormArray;
  }

  optionsArray(i:number, j:number): FormArray {
    return this.questionsArray(i).at(j).get('options') as FormArray;
  }

  addCategory() {
    const categoryForm = this.fb.group({
      // RubricCategory properties
      name: ['', [Validators.required]],
      questions: this.fb.array([])
    });
    this.categories.push(categoryForm);
  }

  addQuestion(categoryIndex: number) {
    const questionForm = this.fb.group({
      question: ['', [Validators.required]],
      options: this.fb.array([])
    });
    ((this.categories.at(categoryIndex).get('questions')) as FormArray).push(questionForm);
  }

  addOption(categoryIndex: number, questionIndex: number) {
    const optionForm = this.fb.group({
      option: ['', [Validators.required]],
      min: ['', [Validators.required]],
      max: ['', [Validators.required]]
    });
    this.optionsArray(categoryIndex, questionIndex).push(optionForm);
  }

  deleteCategory(categoryIndex: number) {
    this.categories.removeAt(categoryIndex);
  }

  deleteQuestion(categoryIndex: number, questionIndex: number) {
    const questions = (this.categories.at(categoryIndex).get('questions') as FormArray);
    questions.removeAt(questionIndex);
  }

  deleteOption(categoryIndex: number, questionIndex: number, optionIndex: number) {
    const questions = (this.categories.at(categoryIndex).get('questions') as FormArray);
    const options = (questions.at(questionIndex).get('options') as FormArray);
    options.removeAt(optionIndex);
  }


  async save() {
    this.openLoading();
    
    const formData = this.formRubric.getRawValue(); // Extracts the data from the form// Log the data to the console for debugging

    // Create evalJSON
    const evalJSON = {
      evals: [
        { min: formData.minMR, max: formData.maxMR, name: 'Muy Recomendado' },
        { min: formData.minR, max: formData.maxR, name: 'Recomendado' },
        { min: formData.minNR, max: formData.maxNR, name: 'No Recomendado' },
      ]
    }

    // Create RubricRequest
    let jsonData: RubricRequest = {
      name: formData.name,
      type: formData.type,
      isActive: formData.isActive,
      evalJSON: JSON.stringify(evalJSON),
      categories: formData.categories.map((c: any) => ({
        name: c.name,
        questions: c.questions.map((q: any) => ({
          question: q.question,
          options: q.options.map((o: any) => ({
            option: o.option,
            min: o.min,
            max: o.max
          }))
        }))
      }))
    };

    if (this.idRubric && this.router.url.indexOf("copiar") === -1) {
      let data = await this.rubricService.putRubric(jsonData, this.idRubric);
      this.closeLoading();
      await this.globalSuccess(`La Rúbrica ${data.name}, ha sido guardado correctamente`);
    } else {
      let data = await this.rubricService.postRubric(jsonData);
      this.closeLoading();
      await this.globalSuccess(`La Rúbrica ${data.name}, ha sido guardado correctamente`);
      this.router.navigateByUrl('rubricas' );
    }
  }
 

  cancel(): void {
    this.router.navigateByUrl('rubricas');
  }

}
