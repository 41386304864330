import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CECRequest, CECResponse } from '../models/cec.model';

@Injectable({ providedIn: 'root' })
export class CECService {

    keys = [
        'id',
        'title',
        'origin',
        'contact',
        'investigators',
        'academic',
        'faculty',
        'career',
        'start_date',
        'end_date',
        'question',
        'general_objective',
        'specific_objectives',
        'hipotesis',
        'design',
        'sample',
        'environment',
        'activities',
        'is_external_expert_validated',
        'is_statistics_validated',
        'is_country_validated',
        'is_unach_expert_validated',
        'is_not_validated',
        'bibliography',
        'is_descriptive_stats',
        'is_analitical_Stats',
        'is_other_analitics',
        'other_analitics',
        'path_consent_file',
        'path_commitment_file',
        'path_project_file',
      ] as const;
    
    constructor(private http: HttpClient) { }

    postCEC(data: CECRequest, files: { [key: string]: File }): Promise<CECResponse> {
        const formData = new FormData();
    
        this.keys.forEach((key) => {
            if (data[key] !== undefined && data[key] !== null) {
              formData.append(key, data[key] as any);
            }
        });
    
        // Agregar los archivos al FormData
        if (files) {
          Object.keys(files).forEach((fileKey) => {
            formData.append(fileKey, files[fileKey]);
          });
        }
    
        // Enviar la solicitud al backend
        return lastValueFrom(
          this.http.post<CECResponse>(`${environment.apiURL}/cec`, formData, {
            headers: {
              // Content-Type no debe establecerse explícitamente, Angular lo manejará automáticamente.
            }
          })
        );
    }

    putCEC(data: CECRequest, id: number): Promise<CECResponse> {
      return lastValueFrom(this.http.put<CECResponse>(`${environment.apiURL}/cec/${id}`, data));
    }

    evaluateCEC(data: CECRequest, id: number): Promise<CECResponse> {
      return lastValueFrom(this.http.put<CECResponse>(`${environment.apiURL}/cec/eval/${id}`, data));
    }

    getAllCECs(): Promise<CECResponse[]> {
      return lastValueFrom(this.http.get<CECResponse[]>(`${environment.apiURL}/cec`));
    }

    getCEC(id: number): Promise<CECResponse> {
      return lastValueFrom(this.http.get<CECResponse>(`${environment.apiURL}/cec/${id}`));
    }

    changeStatus(s: string, id: number): Promise<string> {
      return lastValueFrom(this.http.post<string>(`${environment.apiURL}/cec/change-status`, {status: s, id}));
  }
}