<!-- start page title -->
<app-pagetitle title="Evaluadores Proyecto" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Pares Evaluadores</h4>
                <div class="d-flex">
                    <div class="p-2 w-100">
                        <mat-form-field>
                            <mat-label>Filtro</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Filtro" #inputPeer>
                        </mat-form-field>
                    </div>
                    <div class="p-2">
                        <button type="button" class="btn btn-info" (click)="showReviewer()">
                            <i class="bx bx-plus"></i> Agregar Evaluador
                        </button>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSourcePeer">

                            <ng-container matColumnDef="fullName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Evaluador</th>
                                <td mat-cell *matCellDef="let row"> {{row.user.fullName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Eval </th>
                                <td mat-cell *matCellDef="let row">
                                    <div *ngIf="row.submitted">
                                        {{row.type === 'PEER'? 'PAR': 'DIRECCIÓN'}}
                                    </div>
                                    <div *ngIf="!row.submitted">
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="row.type" name="type" required>
                                              <mat-option value="PEER">PAR</mat-option>
                                              <mat-option value="DIRECTIVE">DIRECCIÓN</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="maxDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Maxima </th>
                                <td mat-cell *matCellDef="let row">
                                    <div *ngIf="row.submitted">
                                        {{row.maxDate | date:'dd/MM/yyyy'}}
                                    </div>
                                    <div *ngIf="!row.submitted">
                                        <mat-form-field>
                                            <input matInput [(ngModel)]="row.maxDate" [matDatepicker]="datepicker">
                                            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                                            <mat-datepicker #datepicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="made">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Realizada</th>
                                <td mat-cell *matCellDef="let row"> {{row.made}} </td>
                            </ng-container>

                            <ng-container matColumnDef="submitDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Fecha Evaluación</b></th>
                                <td mat-cell *matCellDef="let row"> {{row.submitDate | date:'dd/MM/yyyy'}} </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> Acciones</th>
                                <td mat-cell *matCellDef="let row">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end" *ngIf="!row.submitted">
                                        <button class="btn btn-icon btn-danger" type="button" (click)="removePeer(row)">
                                            <i class="bx bx-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsPeer"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsPeer;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No se encontraron Pares evaluadores</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button type="button" class="btn btn-secondary" (click)="cancel()">
                        <i class="bx bxs-x-square"></i> Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" (click)="save()">
                        <i class="bx bx-save"></i> Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end row -->

<div class="modal fade bs-example-modal-center" id="modalReviewer" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Evaluadores Disponibles</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <input type="text" placeholder="Filtrar" class="form-control" matInput (keyup)="filterReviewer($event)" #inputReviewer>
                </div>
                <section style="height: 400px; overflow: auto;">
                    <table mat-table [dataSource]="dataSourceReviewer" class="mat-elevation-z8" style="width: 100%;">

                        <ng-container matColumnDef="fullName">
                            <th mat-header-cell *matHeaderCellDef> Nombre</th>
                            <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
                        </ng-container>

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row) : null"
                                              [checked]="selection.isSelected(row)"
                                              [color]="'primary'">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsReviewer; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsReviewer;"
                            (click)="selection.toggle(row)">
                        </tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="2">Sin Evaluadores</td>
                        </tr>
                    </table>
                </section>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal">
                    <i class="bx bxs-x-square"></i> Cancelar
                </button>
                <button type="button" class="btn btn-primary waves-effect waves-light" (click)="addReviewer()">
                    <i class="bx bx-plus"></i> Agregar
                </button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
