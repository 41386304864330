import { Component } from '@angular/core';

@Component({
  selector: 'app-lista-plantillas',
  templateUrl: './lista-plantillas.component.html',
  styleUrls: ['./lista-plantillas.component.scss']
})
export class ListaPlantillasComponent {

}
