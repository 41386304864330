import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Mensaje } from "../models/utils.model";
import { CommentEvaluation } from "../models/comment-evaluation.model";

@Injectable({
  providedIn: 'root'
})
export class CommentEvaluationService {

  constructor(private http: HttpClient) { }

  getCommentEvaluation(id: number): Promise<CommentEvaluation> {
    return lastValueFrom(this.http.get<CommentEvaluation>(`${environment.apiURL}/comment-evaluation/${id}`));
  }

  putCommentEvaluation(id: number, data: CommentEvaluation): Promise<Mensaje> {
    return lastValueFrom(this.http.put<Mensaje>(`${environment.apiURL}/comment-evaluation/${id}`, data));
  }

}
