import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Role, RoleRequest } from '../models/role.model';
import { SiteSection } from '../models/site_section.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }

  getRoles(): Promise<Role[]> {
    return lastValueFrom(this.http.get<Role[]>(`${environment.apiURL}/role`));
  }

  getSiteSections(): Promise<SiteSection[]> {
    return lastValueFrom(this.http.get<SiteSection[]>(`${environment.apiURL}/sitesection`));
  }

  getRole(id: number): Promise<Role> {
    return lastValueFrom(this.http.get<Role>(`${environment.apiURL}/role/${id}`));
  }

  postRole(role: RoleRequest): Promise<Role>{
    return lastValueFrom(this.http.post<Role>(`${environment.apiURL}/role/`, role));
  }

  putRole(role: RoleRequest, id: number): Promise<Role>{
    return lastValueFrom(this.http.put<Role>(`${environment.apiURL}/role/${id}`, role));
  }
}
