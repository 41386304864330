import { BaseModel } from "./base.model";
import { Faculty } from "./faculty.model";

export class Career extends BaseModel {
  name?: string;
  is_active?: boolean;
  faculty!: Faculty;
}

export class CareerRequest {
  name?: string;
  is_active?: boolean;
  faculty!: number;
}