import { Component, Injector, OnInit } from '@angular/core';
import { flatpickrFactory } from '../../pages.module';
import { BasePage } from 'src/app/shared/base-page';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { User, UserRequest } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { Select2Data } from 'ng-select2-component';
import { RoleService } from 'src/app/core/services/role.service';
import { Role } from 'src/app/core/models/role.model';

@Component({
  selector: 'app-usuario-formulario',
  templateUrl: './usuario-formulario.component.html',
  styleUrls: ['./usuario-formulario.component.scss']
})
export class UsuarioFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formUser!: FormGroup;
  user?: User = undefined;
  idUser: number = 0;
  roles!: Role[];
  roleData: Select2Data = [];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userService: UserService,
    private roleService: RoleService,
    injector: Injector
  ) { 
    super(injector);
    this.formUser = this.fb.group({
      email: [undefined, [Validators.required]],
      fullName: [undefined],
      isActive: [true, [Validators.required]],
      role: [undefined, [Validators.required]]
    });
  }

  async ngOnInit() {

    super.ngOnInit();
    this.openLoading();

    this.roles = await this.roleService.getRoles();
    for(let role of this.roles) {
      this.roleData.push({
        value: role.id + "",
        label: role.name
      });
    }
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Usuario' },
      { label: 'Crear Usuario', active: true }
    ];

    flatpickrFactory();

    this.route.params.pipe(
      switchMap(params => {
          this.idUser = params['id'];

          if (this.idUser) {
              return this.userService.getUser(this.idUser);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: (response) => {
            if (response ) {
                this.user = response;

                this.formUser.patchValue({
                  email: this.user.email,
                  fullName: this.user.fullName,
                  isActive: this.user.isActive,
                  role: this.user.role?.id
                });
            }

            this.breadCrumbItems = [
              { label: 'Usuarios' },
              { label: 'Editar Usuario', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Call Period:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  async save() {
    let datos = this.formUser.value;
    let request: UserRequest = {
      email: datos.email,
      fullName: datos.fullName,
      isActive: datos.isActive,
      role: datos.role
    };

    this.openLoading();
    if (this.user) {
        let data = await this.userService.putUser(request, this.user.id);
        this.closeLoading();
        await this.globalSuccess(`El usuario ${data.fullName? data.fullName : data.email}, ha sido guardado correctamente`);
        this.router.navigateByUrl('usuarios' );
    } else {
        let data = await this.userService.postUser(request);
        this.closeLoading();
        await this.globalSuccess(`El usuario ${data.fullName? data.fullName : data.email}, ha sido creado correctamente`);
        this.router.navigateByUrl('usuarios');
    }
  }

  cancel(): void {
    this.router.navigateByUrl('usuarios');
  }

}
