import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { User } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-usuario-lista',
  templateUrl: './usuario-lista.component.html',
  styleUrls: ['./usuario-lista.component.scss']
})
export class UsuarioListaComponent extends BasePage implements OnInit{

  displayedColumns: string[] = ['fullName', 'email', 'isActive', 'actions'];
  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  users: User[] = [];

  constructor(private userService: UserService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getUsers();
  }

  async getUsers() {
    this.openLoading();
    this.users = await this.userService.getUsers();
    this.users.map((cp) => { 
      cp.actions = [
        {
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/usuarios/editar/${cp.id}`
        },
        {
          name: 'Ver',
          icon: 'bx bx-search-alt',
          url: `/usuarios/detalle/${cp.id}`
        }
      ]
    });
    this.dataSource.data = this.users;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  doAction(accion: Action, id: number){
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/usuarios/crear');
  }

}
