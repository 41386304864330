import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AuthStateModel {
    token: string | null;
    name: string | null;
    photoURL: string | null;
    role: string | null;
    id:number | null;
}

const AUTH_STATE_KEY = 'unach-pi-auth-state';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  
  private authState = new BehaviorSubject<AuthStateModel | null>(this.loadInitialState());

  authState$ = this.authState.asObservable();

  constructor() { }

  private loadInitialState(): AuthStateModel | null {
    const stateJson = localStorage.getItem(AUTH_STATE_KEY);
    return stateJson ? JSON.parse(stateJson) : null;
  }

  setAuthState(state: AuthStateModel): void {
    localStorage.setItem(AUTH_STATE_KEY, JSON.stringify(state));
    this.authState.next(state);
  }

  clearUser(): void {
    localStorage.removeItem(AUTH_STATE_KEY);
    this.authState.next(null);
  }

  logout() {
    // Clear user information from the application state
    this.clearUser();

    // Remove the user's token (and any other session information) from localStorage
    localStorage.removeItem('unach-pi.ms-token'); // or the key you use to store the token
    localStorage.removeItem(AUTH_STATE_KEY);
  }
}
