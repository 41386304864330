import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Project, ProjectRequest } from '../models/project.model';


@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    
    
    constructor(private http: HttpClient) {}

    postProject(project: ProjectRequest): Promise<Project> {
        return lastValueFrom(this.http.post<Project>(`${environment.apiURL}/project`, project));
    }

    putProject(projectID: number, project: ProjectRequest): Promise<Project> {
        return lastValueFrom(this.http.put<Project>(`${environment.apiURL}/project/${projectID}`, project));
    }

    getNextFolio(cpId:number, facultyId:number): Promise<string> {
        return lastValueFrom(this.http.post<string>(`${environment.apiURL}/project/next-folio`, {callPeriodId:cpId, facultyId:facultyId}));
    }

    getProjectsCurrentCallPeriod(): Promise<Project[]> {
        return lastValueFrom(this.http.get<Project[]>(`${environment.apiURL}/project/list-current-period`));
    }

    getProject(id: number): Promise<Project> {
        return lastValueFrom(this.http.get<Project>(`${environment.apiURL}/project/get/${id}`));
    }

    changeStatus(s: string, id: number): Promise<string> {
        return lastValueFrom(this.http.post<string>(`${environment.apiURL}/project/change-status`, {status: s, id}));
    }

    checkProjectSend(id: number) {
        return lastValueFrom(this.http.post<string>(`${environment.apiURL}/project/check`, {id}));
    }

}
