import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { Career, CareerRequest } from '../models/career.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CarrerasService {

    constructor(private http: HttpClient) { }

    getCarrera(idCarrera: number): Promise<Career> {
        return lastValueFrom(this.http.get<Career>(`${environment.apiURL}/career/${idCarrera}`));
    }

    getCarreras(idFacultad:number): Promise<Career[]> {
        return lastValueFrom(this.http.get<Career[]>(`${environment.apiURL}/career/?faculty=${idFacultad}&limit=999`));
    }

    getTodasCarreras(): Promise<Career[]> {
        return lastValueFrom(this.http.get<Career[]>(`${environment.apiURL}/career/?limit=999`));
    }

    getTodasCarrerasActivas(): Promise<Career[]> {
        return lastValueFrom(this.http.get<Career[]>(`${environment.apiURL}/career/?is_active=1&limit=999`));
    }

    putCarrera(carrera: CareerRequest, idCarrera: number): Promise<Career> {
        return lastValueFrom(this.http.put<Career>(`${environment.apiURL}/career/${idCarrera}`, carrera));
    }

    postCarrera(carrera: CareerRequest): Promise<Career> {
        return lastValueFrom(this.http.post<Career>(`${environment.apiURL}/career`, carrera));
    }

}
