import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { CallPeriod } from 'src/app/core/models/call-period.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CallPeriodService } from 'src/app/core/services/call-period.service';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';

@Component({
  selector: 'app-convocatoria-lista',
  templateUrl: './convocatoria-lista.component.html',
  styleUrls: ['./convocatoria-lista.component.scss']
})
export class ConvocatoriaListaComponent extends BasePage implements OnInit {

  displayedColumns: string[] = ['name', 'code', 'startDate', 'endDate', 'actions'];
  dataSource: MatTableDataSource<CallPeriod> = new MatTableDataSource<CallPeriod>();

  actions: Action[] = [];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;

  callPeriods: CallPeriod[] = [];

  constructor(private callPeriodService: CallPeriodService, injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    this.getCallPeriods();
  }

  async getCallPeriods() {
    this.openLoading();
    this.callPeriods = await this.callPeriodService.getCallPeriods();
    this.callPeriods.map((cp) => { 
      cp.actions = [
        {
          name: 'Editar',
          icon: 'bx bx-pencil',
          url: `/convocatorias/editar/${cp.id}`
        },
        {
          name: 'Ver',
          icon: 'bx bx-search-alt',
          url: `/convocatorias/detalle/${cp.id}`
        }
      ]
    });
    this.dataSource.data = this.callPeriods;
    this.closeLoading();

    if(this.paginator) this.dataSource.paginator = this.paginator;
    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  doAction(accion: Action, id: number){
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/convocatorias/crear');
  }

}
