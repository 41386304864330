import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { CECResponse } from 'src/app/core/models/cec.model';
import { CECService } from 'src/app/core/services/cec.service';
import { BasePage } from 'src/app/shared/base-page';

@Component({
  selector: 'app-cec-detalle',
  templateUrl: './cec-detalle.component.html',
  styleUrls: ['./cec-detalle.component.scss']
})
export class CECDetalleComponent extends BasePage implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  idCEC: number = 0;
  cec: CECResponse | null = null;

  constructor(
    private fb: FormBuilder, 
    private cecService: CECService,
    private route: ActivatedRoute,
    injector: Injector
  ) {
    super(injector);
  }


  async ngOnInit() {
    super.ngOnInit();
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Solicitudes CEC' },
      { label: 'Viendo Solicitud', active: true }
    ];

    this.openLoading();

    this.route.params.pipe(
      switchMap(params => {
          this.idCEC = params['id'];

          if (this.idCEC) {
              return this.cecService.getCEC(this.idCEC);
          }

          // If there's no idCEC, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: async(response) => {
            if (response ) {
                this.cec = response;

                this.cec.start_date = new Date(this.cec.start_date).toISOString().split('T')[0];
                this.cec.end_date = new Date(this.cec.end_date).toISOString().split('T')[0];

            }

            this.breadCrumbItems = [
              { label: 'Solicitudes CEC' },
              { label: 'Viendo Solicitud CEC', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching CEC:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  getFileName(filePath: string | undefined): string {
    if(!filePath) return '';
    return filePath.split('/').pop() || '';
  }

  getFileNameB64(filePath: string | undefined): string {
    if(!filePath) return '';
    const file = this.getFileName(filePath);
    return file? btoa(file) : '';
  }
}
