<!-- start page title -->
<app-pagetitle title="Configurando Evaluadores Solicitud Comité Ético Científico" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">

                <div ngbAccordion #accordion="ngbAccordion" activeIds="config-panel-one" [closeOthers]="true">
                    <div class="card rounded mb-3"></div>
                    <div ngbAccordionItem="config-panel-one" id="config-panel-one">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Detalles de la Solicitud al CEC</h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>

                                <div class="row">
                                    <!-- Resumen de Identificación -->
                                    <div class="col-lg-12">
                                        <h6>Identificación</h6>
                                        <div class="card p-3 mb-3">
                                        <p><strong>Título de la Investigación:</strong> {{ cec?.title || '' }}</p>
                                        <p><strong>Responsable:</strong> {{cec?.user?.fullName}}</p>
                                        <p><strong>Procedencia:</strong> {{ cec?.origin || '' }}</p>
                                        <p><strong>Investigador de Contacto:</strong> {{ cec?.contact || '' }}</p>
                                        <p><strong>Investigadores:</strong> {{ cec?.investigators || '' }}</p>
                                        <p><strong>Profesor/a Guía:</strong> {{ cec?.academic || '' }}</p>
                                        <p><strong>Facultad / Unidad:</strong> {{ cec?.faculty?.name || '' }}</p>
                                        <p><strong>Programa / Carrera:</strong> {{ cec?.career?.name || '' }}</p>
                                        <p><strong>Fecha de Inicio:</strong> {{ cec?.start_date || '' }}</p>
                                        <p><strong>Fecha de Cierre:</strong> {{ cec?.end_date || '' }}</p>
                                        </div>
                                    </div>
                                
                                    <!-- Resumen de Estructura -->
                                    <div class="col-lg-12">
                                        <h6>Estructura Metodológica</h6>
                                        <div class="card p-3 mb-3">
                                        <p><strong>Pregunta de Investigación:</strong> {{ cec?.question }}</p>
                                        <p><strong>Objetivo General:</strong> {{ cec?.general_objective }}</p>
                                        <p><strong>Objetivos Específicos:</strong> {{ cec?.specific_objectives }}</p>
                                        <p><strong>Hipótesis:</strong> {{ cec?.hipotesis }}</p>
                                        <p><strong>Diseño:</strong> {{ cec?.design }}</p>
                                        <p><strong>Población o muestra:</strong> {{ cec?.sample }}</p>
                                        <p><strong>Entorno:</strong> {{ cec?.environment }}</p>
                                        <p><strong>Actividades:</strong> {{ cec?.activities }}</p>
                                        </div>
                                    </div>
                                
                                    <!-- Resumen de Validaciones -->
                                    <div class="col-lg-12">
                                        <h6>Validaciones</h6>
                                        <div class="card p-3 mb-3">
                                        <p><strong>Validación del Instrumento:</strong></p>
                                        <ul>
                                            <li *ngIf="cec?.is_external_expert_validated">Validado por expertos de otras instituciones</li>
                                            <li *ngIf="cec?.is_statistics_validated">Validado estadísticamente</li>
                                            <li *ngIf="cec?.is_country_validated">Validado por uso común en el país o zona</li>
                                            <li *ngIf="cec?.is_unach_expert_validated">Validado por expertos de la UNACH</li>
                                            <li *ngIf="cec?.is_not_validated">No validado aún</li>
                                        </ul>
                                        <p><strong>Bibliografía:</strong> {{ cec?.bibliography }}</p>
                                        <p><strong>Análisis de Datos:</strong></p>
                                        <ul>
                                            <li *ngIf="cec?.is_descriptive_stats">Estadística Descriptiva</li>
                                            <li *ngIf="cec?.is_analitical_stats">Estadística Analítica</li>
                                            <li *ngIf="cec?.is_other_analitics">Otros Análisis: {{ cec?.other_analitics }}</li>
                                        </ul>
                                        </div>
                                    </div>
                                
                                    <!-- Resumen de Documentación -->
                                    <div class="col-lg-12">
                                        <h6>Documentación</h6>
                                        <div class="card p-3 mb-3">
                                        <p><strong>Archivo de Consentimiento:</strong>&nbsp;<a *ngIf="cec" target="_blank" href="/api/attachment/get-cec/{{ getFileNameB64(cec.path_consent_file) }}">{{ getFileName(cec.path_consent_file) }}</a></p>
                                        <p><strong>Archivo de Compromiso:</strong>&nbsp;<a *ngIf="cec" target="_blank" href="/api/attachment/get-cec/{{ getFileNameB64(cec.path_commitment_file) }}">{{ getFileName(cec.path_commitment_file) }}</a></p>
                                        <p><strong>Archivo del Proyecto:</strong>&nbsp;<a *ngIf="cec" target="_blank" href="/api/attachment/get-cec/{{ getFileNameB64(cec.path_project_file) }}">{{ getFileName(cec.path_project_file) }}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form [formGroup]="form" class="mt-5">
                    <h5>Evaluadores</h5>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="coordinator" formControlName="coordinator" required>
                                    <option *ngFor="let user of users" [value]="user.id">{{user.fullName}}</option>
                                </select>
                                <label for="coordinator">Coordinador *</label>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="cec_member" formControlName="cec_member" required>
                                    <option *ngFor="let user of users" [value]="user.id">{{user.fullName}}</option>
                                </select>
                                <label for="cec_member">Miembro del CEC *</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row-reverse">
                        <button class="btn btn-primary" type="submit" (click)="save()">Guardar Evaluadores</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
  