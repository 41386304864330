import { Component, Injector, OnInit } from '@angular/core';
import { flatpickrFactory } from '../../pages.module';
import { BasePage } from 'src/app/shared/base-page';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CallPeriod, CallPeriodRequiest } from 'src/app/core/models/call-period.model';
import { CallPeriodService } from 'src/app/core/services/call-period.service';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'app-convocatoria-formulario',
  templateUrl: './convocatoria-formulario.component.html',
  styleUrls: ['./convocatoria-formulario.component.scss']
})
export class ConvocatoriaFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formCallPeriod!: FormGroup;
  callPeriod?: CallPeriod = undefined;
  idCallPeriod: number = 0;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private callPeriodService: CallPeriodService,
    injector: Injector
  ) { 
    super(injector);
    this.formCallPeriod = this.fb.group({
      name: [undefined, [Validators.required]],
      year: [undefined, [Validators.required]],
      code: [undefined, [Validators.required]],
      startDate: [undefined, [Validators.required]],
      endDate: [undefined, [Validators.required]]
    });
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.openLoading();
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Convocatorias' },
      { label: 'Crear Convocatoria', active: true }
    ];

    flatpickrFactory();

    this.route.params.pipe(
      switchMap(params => {
          this.idCallPeriod = params['id'];

          if (this.idCallPeriod) {
              return this.callPeriodService.getCallPeriod(this.idCallPeriod);
          }

          // If there's no idCallPeriod, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: (response) => {
            if (response ) {
                this.callPeriod = response;

                this.formCallPeriod.patchValue({
                  name: this.callPeriod.name,
                  year: this.callPeriod.year,
                  code: this.callPeriod.code,
                  startDate: this.callPeriod.startDate,
                  endDate: this.callPeriod.endDate
                });
            }

            this.breadCrumbItems = [
              { label: 'Convocatorias' },
              { label: 'Editar Convocatoria', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Call Period:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  async save() {
    let datos = this.formCallPeriod.value;
    let request: CallPeriodRequiest = {
      name: datos.name,
      year: datos.year,
      code: datos.code,
      startDate: this.convertToDate(datos.startDate),
      endDate: this.convertToDate(datos.endDate)
    };

    this.openLoading();
    if (this.callPeriod) {
        let data = await this.callPeriodService.putCallPeriod(request, this.callPeriod.id);
        this.closeLoading();
        await this.globalSuccess(`La convocatoria ${data.name}, ha sido guardada correctamente`);
        this.router.navigateByUrl('convocatorias' );
    } else {
        let data = await this.callPeriodService.postCallPeriod(request);
        this.closeLoading();
        await this.globalSuccess(`La convocatoria ${data.name}, ha sido creada correctamente`);
        this.router.navigateByUrl('convocatorias');
    }
  }

  cancel(): void {
    this.router.navigateByUrl('convocatorias');
  }

  private convertToDate(dateString: string): Date {
    if(dateString.indexOf("/") !== -1) {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    } else {
      return new Date(dateString);
    }
  }

}
