import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User, UserRequest } from '../models/user.model';
import { GoogleLogin } from 'src/app/account/auth/login/google-login.model';
import { PendingTasks } from '../models/pending-tasks.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    
    constructor(private http: HttpClient) { }

    getUsers() {
        return lastValueFrom(this.http.get<User[]>(`${environment.apiURL}/user?limit=999`));
    }

    getUser(idUser: number) {
        return lastValueFrom(this.http.get<User>(`${environment.apiURL}/user/${idUser}`));
    }

    postUser(request: UserRequest) {
        return lastValueFrom(this.http.post<User>(`${environment.apiURL}/user`, request));
    }

    putUser(request: UserRequest, idUser: number) {
        return lastValueFrom(this.http.put<User>(`${environment.apiURL}/user/${idUser}`, request));
    }

    postLogin(loginData: GoogleLogin) {
        return lastValueFrom(this.http.post<string>(`${environment.apiURL}/login`, loginData));
    }

    getMisPendientes(){
        return lastValueFrom(this.http.get<PendingTasks[]>(`${environment.apiURL}/task/pending`));
    }

}