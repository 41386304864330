<!-- start page title -->
<app-pagetitle title="Formulario Usuario" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formUser">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email" placeholder="Ej. usuario@unach.cl" formControlName="email">
                                <label for="email">Correo Electrónico</label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="mb-4">
                                <h5 class="font-size-14 mb-2">¿Activo?</h5>
                                <div class="form-check form-switch form-switch-lg mb-lg-3" dir="ltr">
                                    <input class="form-check-input" type="checkbox" id="formCheckActivo" formControlName="isActive">
                                    <label class="form-check-label" for="formCheckActivo">
                                        {{ formUser.value.isActive ? 'Activo' : 'Inactivo' }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="fullName" placeholder="Ej. Francisco Figueroa" formControlName="fullName">
                                <label for="fullName">Nombre Completo</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" aria-label="Floating label select example" formControlName="role">
                                    <option value="">Eliga un Rol</option>
                                    <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
                                </select>
                                <label for="floatingSelect">Rol</label>
                            </div>
                        </div>
                    </div>
                    
                    <div class="d-flex flex-row-reverse">
                        <button class="btn btn-primary" type="submit" (click)="save()">Guardar Usuario</button>
                        <button class="btn btn-light" style="margin-right: 5px;" type="button" (click)="cancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>