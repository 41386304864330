<!-- start page title -->
<app-pagetitle title="Formulario Comité Ético Científico" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <ng-stepper #cdkStepper [linear]="true" class="wizard">
                    <!-- Sección 2: Identificación -->
                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">1.</span>
                        <span>Identificación</span>
                        </ng-template>
                    <form [formGroup]="formStep2">
                        <h5>1. Identificación</h5>
                        <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="title" formControlName="title" required>
                            <label for="title">Título de la Investigación *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <select class="form-select" id="origin" formControlName="origin" required>
                                <option value="TFC">Trabajo final de carrera o de posgrado</option>
                                <option value="UNACH">Proyectos de investigación procedentes de Docentes de la UNACH</option>
                                <option value="EXTERNO">Proyectos de investigación Externos a la UNACH</option>
                            </select>
                            <label for="origin">Procedencia *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="contact" formControlName="contact" required>
                            <label for="contact">Investigador de Contacto *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="investigators" formControlName="investigators" style="height: 100px"></textarea>
                            <label for="investigators">Investigadores *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="academic" formControlName="academic" required>
                            <label for="academic">Profesor/a Guía *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelectFacu" formControlName="faculty" name="faculty" required>
                                    <option value="">Eliga una Facultad / Unidad</option>
                                    <option *ngFor="let faculty of faculties" [value]="faculty.id">
                                        {{faculty.name}}
                                    </option>
                                </select>
                                <label for="floatingSelectFacu">Facultad / Unidad</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" formControlName="career" name="career" required>
                                    <option value="">Eliga un Programa / Carrera</option>
                                    <option *ngFor="let career of careersForFaculty(formStep2.value.faculty)" [value]="career.id">
                                        {{career.name}}
                                    </option>
                                </select>
                                <label for="floatingSelect">Programa / Carrera</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                            <input type="date" class="form-control" id="start_date" formControlName="start_date" required>
                            <label for="start_date">Fecha de Inicio *</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                            <input type="date" class="form-control" id="end_date" formControlName="end_date" required>
                            <label for="end_date">Fecha de Cierre *</label>
                            </div>
                        </div>
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                        <button class="btn btn-primary" mat-button cdkStepperNext>Siguiente</button>
                        </div>
                    </form>
                    </cdk-step>
                
                    <!-- Sección 3: Estructura -->
                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">2.</span>
                        <span>Estructura</span>
                        </ng-template>
                    <form [formGroup]="formStep3">
                        <h5>2. Estructura Metodológica</h5>
                        <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="question" formControlName="question" required style="height: 100px"></textarea>
                            <label for="question">Pregunta de Investigación *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="general_objective" formControlName="general_objective" required style="height: 100px"></textarea>
                            <label for="general_objective">Objetivo General *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="specific_objectives" formControlName="specific_objectives" required style="height: 100px"></textarea>
                            <label for="specific_objectives">Objetivos Específicos *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="hipotesis" formControlName="hipotesis" style="height: 100px"></textarea>
                            <label for="hipotesis">Hipótesis (si la hubiera)</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="design" formControlName="design" required style="height: 100px"></textarea>
                            <label for="design">Diseño *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="sample" formControlName="sample" required style="height: 100px"></textarea>
                            <label for="sample">Población o muestra a estudiar *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="environment" formControlName="environment" required style="height: 100px"></textarea>
                            <label for="environment">Entorno *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <textarea class="form-control" id="activities" formControlName="activities" required style="height: 100px"></textarea>
                            <label for="activities">Actividades *</label>
                            </div>
                        </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3">
                        <button class="btn btn-light" mat-button cdkStepperPrevious>Anterior</button>
                        <button class="btn btn-primary" mat-button cdkStepperNext>Siguiente</button>
                        </div>
                    </form>
                    </cdk-step>
                
                    <!-- Sección 4: Validaciones -->
                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">3.</span>
                        <span>Validaciones</span>
                        </ng-template>
                        <form [formGroup]="formStep4">
                        <h5>3. Validaciones</h5>
                        <div class="row">
                            <div class="col-lg-12">
                            <label>Validación del Instrumento de Recolección de Datos *</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_external_expert_validated" formControlName="is_external_expert_validated">
                                <label class="form-check-label" for="is_external_expert_validated">El instrumento está validado por expertos de otras instituciones</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_statistics_validated" formControlName="is_statistics_validated">
                                <label class="form-check-label" for="is_statistics_validated">El instrumento está validado de manera estadística</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_country_validated" formControlName="is_country_validated">
                                <label class="form-check-label" for="is_country_validated">El instrumento está validado por uso común en el país o zona</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_unach_expert_validated" formControlName="is_unach_expert_validated">
                                <label class="form-check-label" for="is_unach_expert_validated">El instrumento fue validado por expertos principalmente de la UNACH</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_not_validated" formControlName="is_not_validated">
                                <label class="form-check-label" for="is_not_validated">El instrumento todavía no ha sido validado</label>
                            </div>
                            </div>
                    
                            <div class="col-lg-12 mt-3">
                            <div class="form-floating">
                                <textarea class="form-control" id="bibliography" formControlName="bibliography" style="height: 100px"></textarea>
                                <label for="bibliography">Bibliografía que sustente la validación</label>
                            </div>
                            </div>
                    
                            <div class="col-lg-12 mt-3">
                            <label>Análisis de Datos *</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_descriptive_stats" formControlName="is_descriptive_stats">
                                <label class="form-check-label" for="is_descriptive_stats">Estadística Descriptiva (Tablas, promedios, medianas)</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_analitical_Stats" formControlName="is_analitical_Stats">
                                <label class="form-check-label" for="is_analitical_Stats">Estadística Analítica (Pruebas paramétricas, no paramétricas)</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="is_other_analitics" formControlName="is_other_analitics">
                                <label class="form-check-label" for="is_other_analitics">Otros Análisis</label>
                            </div>
                            </div>
                    
                            <div class="col-lg-12 mt-3" *ngIf="formStep4.get('is_other_analitics')?.value">
                            <div class="form-floating">
                                <textarea class="form-control" id="other_analitics" formControlName="other_analitics" style="height: 100px"></textarea>
                                <label for="other_analitics">Especificar Otros Análisis</label>
                            </div>
                            </div>
                        </div>
                    
                        <div class="d-flex justify-content-between mt-3">
                            <button class="btn btn-light" mat-button cdkStepperPrevious>Anterior</button>
                            <button class="btn btn-primary" mat-button cdkStepperNext>Siguiente</button>
                        </div>
                        </form>
                    </cdk-step>
                    
                
                    <!-- Sección 5: Documentación -->
                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">4.</span>
                        <span>Documentación</span>
                        </ng-template>
                    <form [formGroup]="formStep5">
                        <h5>4. Documentación</h5>
                        <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <input type="file" class="form-control" id="path_consent_file" formControlName="path_consent_file" (change)="onFileChange($event, 'path_consent_file')">
                            <label for="path_consent_file">Archivo de Consentimiento *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <input type="file" class="form-control" id="path_commitment_file" formControlName="path_commitment_file" (change)="onFileChange($event, 'path_commitment_file')">
                            <label for="path_commitment_file">Archivo de Compromiso *</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                            <input type="file" class="form-control" id="path_project_file" formControlName="path_project_file" (change)="onFileChange($event, 'path_project_file')">
                            <label for="path_project_file">Archivo del Proyecto *</label>
                            </div>
                        </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3">
                        <button class="btn btn-light" mat-button cdkStepperPrevious>Anterior</button>
                        <button class="btn btn-primary" mat-button cdkStepperNext>Siguiente</button>
                        </div>
                    </form>
                    </cdk-step>
                
                    <!-- Resumen -->
                    <cdk-step [optional]="false">
                        <ng-template cdkStepLabel>
                        <span class="number">5.</span>
                        <span>Resumen</span>
                        </ng-template>

                        <h5>5. Resumen</h5>
                        <div class="row">
                        <!-- Resumen de Identificación -->
                        <div class="col-lg-12">
                            <h6>Identificación</h6>
                            <div class="card p-3 mb-3">
                            <p><strong>Título de la Investigación:</strong> {{ formStep2.get('title')?.value }}</p>
                            <p><strong>Procedencia:</strong> {{ formStep2.get('origin')?.value }}</p>
                            <p><strong>Investigador de Contacto:</strong> {{ formStep2.get('contact')?.value }}</p>
                            <p><strong>Investigadores:</strong> {{ formStep2.get('investigators')?.value }}</p>
                            <p><strong>Profesor/a Guía:</strong> {{ formStep2.get('academic')?.value }}</p>
                            <p><strong>Facultad / Unidad:</strong> {{ getFacultyName(formStep2.get('faculty')?.value) }}</p>
                            <p><strong>Programa / Carrera:</strong> {{ getCareerName(formStep2.get('career')?.value) }}</p>
                            <p><strong>Fecha de Inicio:</strong> {{ formStep2.get('start_date')?.value }}</p>
                            <p><strong>Fecha de Cierre:</strong> {{ formStep2.get('end_date')?.value }}</p>
                            <button class="btn btn-outline-primary btn-sm" (click)="navigateToStep(0)">Editar</button>
                            </div>
                        </div>
                    
                        <!-- Resumen de Estructura -->
                        <div class="col-lg-12">
                            <h6>Estructura Metodológica</h6>
                            <div class="card p-3 mb-3">
                            <p><strong>Pregunta de Investigación:</strong> {{ formStep3.get('question')?.value }}</p>
                            <p><strong>Objetivo General:</strong> {{ formStep3.get('general_objective')?.value }}</p>
                            <p><strong>Objetivos Específicos:</strong> {{ formStep3.get('specific_objectives')?.value }}</p>
                            <p><strong>Hipótesis:</strong> {{ formStep3.get('hipotesis')?.value }}</p>
                            <p><strong>Diseño:</strong> {{ formStep3.get('design')?.value }}</p>
                            <p><strong>Población o muestra:</strong> {{ formStep3.get('sample')?.value }}</p>
                            <p><strong>Entorno:</strong> {{ formStep3.get('environment')?.value }}</p>
                            <p><strong>Actividades:</strong> {{ formStep3.get('activities')?.value }}</p>
                            <button class="btn btn-outline-primary btn-sm" (click)="navigateToStep(1)">Editar</button>
                            </div>
                        </div>
                    
                        <!-- Resumen de Validaciones -->
                        <div class="col-lg-12">
                            <h6>Validaciones</h6>
                            <div class="card p-3 mb-3">
                            <p><strong>Validación del Instrumento:</strong></p>
                            <ul>
                                <li *ngIf="formStep4.get('is_external_expert_validated')?.value">Validado por expertos de otras instituciones</li>
                                <li *ngIf="formStep4.get('is_statistics_validated')?.value">Validado estadísticamente</li>
                                <li *ngIf="formStep4.get('is_country_validated')?.value">Validado por uso común en el país o zona</li>
                                <li *ngIf="formStep4.get('is_unach_expert_validated')?.value">Validado por expertos de la UNACH</li>
                                <li *ngIf="formStep4.get('is_not_validated')?.value">No validado aún</li>
                            </ul>
                            <p><strong>Bibliografía:</strong> {{ formStep4.get('bibliography')?.value }}</p>
                            <p><strong>Análisis de Datos:</strong></p>
                            <ul>
                                <li *ngIf="formStep4.get('is_descriptive_stats')?.value">Estadística Descriptiva</li>
                                <li *ngIf="formStep4.get('is_analitical_Stats')?.value">Estadística Analítica</li>
                                <li *ngIf="formStep4.get('is_other_analitics')?.value">Otros Análisis: {{ formStep4.get('other_analitics')?.value }}</li>
                            </ul>
                            <button class="btn btn-outline-primary btn-sm" (click)="navigateToStep(2)">Editar</button>
                            </div>
                        </div>
                    
                        <!-- Resumen de Documentación -->
                        <div class="col-lg-12">
                            <h6>Documentación</h6>
                            <div class="card p-3 mb-3">
                            <p *ngIf="formStep5.get('path_consent_file')?.value"><strong>Archivo de Consentimiento:</strong> {{ getFileName(formStep5.get('path_consent_file')?.value, 'path_consent_file') }}</p>
                            <p *ngIf="!formStep5.get('path_consent_file')?.value"><strong>Archivo de Consentimiento:</strong>&nbsp;<a target="_blank" href="/api/attachment/get-cec/{{ getFileNameB64(formStep5.get('path_consent_file')?.value, 'path_consent_file') }}">{{ getFileName(formStep5.get('path_consent_file')?.value, 'path_consent_file') }}</a></p>
                            <p *ngIf="formStep5.get('path_commitment_file')?.value"><strong>Archivo de Compromiso:</strong> {{ getFileName(formStep5.get('path_commitment_file')?.value, 'path_commitment_file') }}</p>
                            <p *ngIf="!formStep5.get('path_commitment_file')?.value"><strong>Archivo de Compromiso:</strong>&nbsp;<a target="_blank" href="/api/attachment/get-cec/{{ getFileNameB64(formStep5.get('path_commitment_file')?.value, 'path_commitment_file') }}">{{ getFileName(formStep5.get('path_commitment_file')?.value, 'path_commitment_file') }}</a></p>
                            <p *ngIf="formStep5.get('path_project_file')?.value"><strong>Archivo del Proyecto:</strong> {{ getFileName(formStep5.get('path_project_file')?.value, 'path_project_file') }}</p>
                            <p *ngIf="!formStep5.get('path_project_file')?.value"><strong>Archivo del Proyecto:</strong>&nbsp;<a target="_blank" href="/api/attachment/get-cec/{{ getFileNameB64(formStep5.get('path_project_file')?.value, 'path_project_file') }}">{{ getFileName(formStep5.get('path_project_file')?.value, 'path_project_file') }}</a></p>
                            <button class="btn btn-outline-primary btn-sm" (click)="navigateToStep(3)">Editar</button>
                            </div>
                        </div>
                        </div>
                    
                        <div class="d-flex justify-content-between mt-3">
                        <button class="btn btn-light" mat-button cdkStepperPrevious>Anterior</button>
                        <button class="btn btn-primary" mat-button (click)="submitForm()">Finalizar</button>
                        </div>
                    </cdk-step>
                    
                </ng-stepper>
            </div>
        </div>
    </div>
</div>
  