<!-- start page title -->
<app-pagetitle title="Creando Currículum" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formCurriculum">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Ej. PEREZ RODRIGUEZ, Juan Alberto" formControlName="name" readonly>
                                <label for="name">Nombre Investigador</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="rut" placeholder="Ej. 11111111-1" formControlName="rut" readonly>
                                <label for="rut">RUT</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="email" placeholder="Ej. investigador@unach.cl" formControlName="email" readonly>
                                <label for="email">Correo Electrónico</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="phone" placeholder="Ej. 42 304 6543" formControlName="phone" readonly>
                                <label for="phone">Teléfono</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="mobile" placeholder="Ej. 9 3045 6543" formControlName="mobile" readonly>
                                <label for="mobile">Celular</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" value="{{curriculum?.faculty?.name}}" readonly>
                                <label for="floatingSelectFacu">Facultad / Unidad</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" value="{{curriculum?.career?.name}}"  readonly>
                                <label for="floatingSelect">Programa / Carrera</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <h4>Títulos Académicos</h4>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="titles">
                                    <thead>
                                        <tr>
                                            <th>Año</th>
                                            <th>Grado Académico Alcanzado</th>
                                            <th>Institución Académica</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of titles.controls; let i = index" [formGroupName]="i">
                                            <td>
                                                <input type="text" class="form-control" formControlName="year" placeholder="Ej. 2010" readonly>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" value="{{degrees[item.value.degree]}}" readonly>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" formControlName="institution" placeholder="Ej. Universidad X" readonly>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <h4>Actividad Docente Desempeñada</h4>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="activities">
                                    <thead>
                                        <tr>
                                            <th>Período</th>
                                            <th>Actividad</th>
                                            <th>Régimen de Dedicación</th>
                                            <th>Institución u Organismo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of activities.controls; let i = index" [formGroupName]="i">
                                            <td>
                                                <input type="text" class="form-control" formControlName="period" placeholder="Ej. 2010 - 2012" readonly>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" formControlName="activity" placeholder="Ej. Docente FAIN" readonly>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" value="{{regimes[item.value.regime]}}" readonly>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" formControlName="institution" placeholder="Ej. Universidad X" readonly>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <h4>Publicaciones</h4>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="publications">
                                    <thead>
                                        <tr>
                                            <th>ARTICULO DE REVISTA CIENTÍFICA O ACADEMICA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-accordion>
                                                    <mat-expansion-panel class="mb-3" hideToggle *ngFor="let item of publications.controls; let i = index" [formGroupName]="i">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                Titulo: {{item.value.title}}
                                                            </mat-panel-title>
                                                            <mat-panel-description>
                                                                Revista: {{item.value.magazine}}
                                                            </mat-panel-description>
                                                        </mat-expansion-panel-header>
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="authors" id="authors" readonly>
                                                                    <label for="authors">Autor/es</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="title" id="title" readonly>
                                                                    <label for="title">Título del Artículo</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="magazine" id="magazine" readonly>
                                                                    <label for="magazine">Revista</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="year" id="year" readonly>
                                                                    <label for="year">Año</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="volume" id="volume" readonly>
                                                                    <label for="volume">Volumen</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="number" id="number" readonly>
                                                                    <label for="number">Número</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="pages" id="pages" readonly>
                                                                    <label for="year">Páginas</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="keyword" id="keyword" readonly>
                                                                    <label for="keyword">Palabras Clave</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="webLink" id="webLink" readonly>
                                                                    <label for="webLink">Vínculo Web Artículo</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="ddi" id="ddi" readonly>
                                                                    <label for="ddi">DOI Artículo</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <h5 for="revista">Revista Indexada En:</h5>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isWosisi" id="isWosisi">
                                                                        <label class="form-check-label" for="isWosisi">WOS-ISI</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isScopus" id="isScopus">
                                                                        <label class="form-check-label" for="isScopus">Scopus</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isScielo" id="isScielo">
                                                                        <label class="form-check-label" for="isScielo">SciELO</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isLatindex" id="isLatindex">
                                                                        <label class="form-check-label" for="isLatindex">Latindex</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isIndenxed" id="isIndenxed">
                                                                        <label class="form-check-label" for="isIndenxed">No Indexada</label>
                                                                    </div>
                                                                    <div class="form-floating mb-3">
                                                                        <input type="text" class="form-control" formControlName="another" id="otra" readonly>
                                                                        <label for="otra">Otra</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>